import React, { useState, useEffect, useRef } from "react";
import Sidebar from "./Sidebar";
import Header from "../components/Header";
import axios, { Axios } from "axios";
import Swal from "sweetalert2";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import "../Style/cumulativeDeposit.css";
import DataTable from "react-data-table-component";
import { debounce } from "lodash";
export default function CumulativePaymentEntryNewDeposit() {
  const [depositData, setDepositData] = useState([]);
  const [newData, setNewData] = useState("");
  const [data, setData] = useState([]);
  const [fullName, setFullName] = useState("");
  let [accountNo, setAccountNo] = useState("");
  let [contribution, setContribution] = useState("");
  let [employeeNumber, setEmployeeNumber] = useState("");
  let [totalBalance, setTotalBalance] = useState("");
  let [Credit, setCredit] = useState("");
  let [CheckNo, setCheckNo] = useState("");
  let [TransactionAmount, setTransactionAmount] = useState("");
  let [count, setCount] = useState("");
  const [balanceData, setBalanceData] = useState([]);
  const [secondLast, setSecondLast] = useState([]);
  let [BottomselectedOption, setBottomselectedOption] = useState("");
  let forSelectTagValues = useRef();
  let [purchaseType, setPurchaseType] = useState("");
  let [postData, setPostData] = useState("");
  let [DropdownTitle, setDropdownTitle] = useState("Select an option");
  const [error, setError] = useState(null);

  //   let[payloadState,setPayloadState]=useState

  // let[]

  const handlePurchaseChange = (event) => {
    setPurchaseType(event.target.value);
    setDropdownTitle(event.target.value);
    console.log(purchaseType);
    console.log("###########");
    console.log(event.target.value);
    console.log("###########");
  };

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, "0");
    const day = today.getDate().toString().padStart(2, "0");
    // return `${year}-${month}-${day}`;
    return `${day}-${month}-${year}`;
  };
  const [transactions, setTransactions] = useState([]);

  // function updateTotalValue(newBalance)
  // {
  //     setTotalBalance(newBalance)
  // }

  // useEffect(()=>{
  //     handleAccountNumberChange(employeeNumber);
  // },[employeeNumber]);

  useEffect(() => {
    handleAccountNumberChange(employeeNumber);
  }, [totalBalance]);

  // function handleBottomselectedOption(e) {
  //   // setBottomselectedOption(e.target.value);
  //   // if (forSelectTagValues.current.value !== "") {
  //   //   document.getElementById("cummulative-modal-button").click();
  //   //   setBottomselectedOption(forSelectTagValues.current.value);
  //   // }
  //   const selectedValue = e.target.value;

  //   if (selectedValue !== "") {
  //     document.getElementById("cummulative-modal-button").click();
  //     setBottomselectedOption(selectedValue);
  //   }
  // }

  // useEffect(()=>{
  //     if(BottomselectedOption==="Purchase Cummulative")
  //     {
  //         document.getElementById('cummulative-modal-button').click();
  //     }
  // },[BottomselectedOption]);

  // useEffect(() => {
  //   if (employeeNumber === "") {
  //     setBottomselectedOption("");
  //   }
  // }, [employeeNumber]);

  // useEffect(() => {
  //   if (count > 0) {
  //     // let temp=employeeNumber;
  //     // handleAccountNumberChange("");
  //     console.log("count is :", count);
  //     // setBottomselectedOption("");
  //     setTransactionAmount("");
  //     forSelectTagValues.current.value = "";
  //     handleAccountNumberChange(employeeNumber);
  //   }
  // }, [count]);

  // useEffect(()=>{
  //     if(forSelectTagValues.target.value!==null&&forSelectTagValues.target.value==='Purchase Cummulative')
  //     {
  //         document.getElementById('cummulative-modal-button').click();
  //     }
  // },[forSelectTagValues])

  const handleAccountNumberChange = async (newRDID) => {
    setEmployeeNumber(newRDID);
    //console.log(newRDID);
    let dataArray = [];

    let inputObject = {
      purchase_date: (
        <input
          type="date"
          className="inputFields text-center inputf"
          style={{ fontSize: "0.9em" }}
          value={getCurrentDate()}
        />
      ),

      // RVNO: (
      //         <input
      //           type="number"
      //           className="inputFields inputf"
      //           style={{ width: "90%" }}
      //         />
      //       ),
      // purchase_type: <input type="text" className="inputFields inputf" style={{ width: '90%' }} />,
      // purchase_type: <select  className="inputFields text-center inputf" required onChange={handleBottomselectedOption}  >
      debit_credit: (
        <select
          className="inputFields text-start inputf"
          required
          value={purchaseType}
          onChange={handlePurchaseChange}
          // ref={forSelectTagValues}
          style={{ width: "90%" }}
        >
          <option value="">{DropdownTitle}</option>
          <option value="TRANSFER">Transfer</option>
          <option value="CASH">Cash</option>
          <option value="BANK">Bank</option>
        </select>
      ),
      CHEQUENO: (
        <input
          type="number"
          className="inputFields text-center inputf"
          style={{ width: "90%" }}
          // value={CheckNo}
          onChange={(e) => setCheckNo(e.target.value)}
        />
      ),
      // debit: (
      //   <input
      //     type="number"
      //     className="inputFields inputf"
      //     style={{ width: "90%" }}
      //   />
      // ),
      AMOUNT: (
        <input
          type="number"
          className="inputFields text-center inputf"
          style={{ width: "90%" }}
          // value={Credit}
          onChange={(e) => handleCreditChange(e)}
        />
      ),
      // share_price: (
      //   <input
      //     type="number"
      //     className="inputFields inputf"
      //     style={{ width: "90%" }}
      //   />
      // ),
      balance: (
        <input
          type="number"
          className="inputFields text-center inputf"
          value={totalBalance}
          style={{ width: "90%" }}
          disabled
        />
      ),
      // User: (
      //   <input
      //     type="text"
      //     className="inputFields inputf"
      //     style={{ width: "90%" }}
      //   />
      // ),
    };
    const dataPost = {
      member_id: newRDID,
      purchase_type: "Cumulative",
    };
    if (!newRDID) {
      setData("");
      return;
    }
    try {
      let response = await axios.post(
        "http://13.232.218.212:8000/cumulativeDeposit/",
        dataPost
      );
      let jsonData = response.data.data;
      let jsonData1 = response.data.balance_data;
      setBalanceData(jsonData1);
      // console.log(jsonData);
      if (
        jsonData1.length &&
        jsonData.length &&
        jsonData1.length === jsonData.length
      ) {
        jsonData = jsonData.map((member, index) => ({
          ...member,
          balance: jsonData1[index],
        }));
      } else if (jsonData1.length !== jsonData.length) {
        console.error(
          "The length of balanceData does not match the length of memberData."
        );
      }

      jsonData.map((element) => {
        element.RVNO = element.RVNO || "";
        if (element.transaction_method === "Withdrawal") {
          element.debit = element.share_price;
        } else {
          element.debit = 0;
        }
        if (element.transaction_method === "Deposit") {
          element.credit = element.share_price;
        } else {
          element.credit = 0;
        }

        element.User = element.User || "";
      });
      dataArray = jsonData;

      dataArray.push(inputObject);
      // console.log(dataArray);

      const lastTransaction = dataArray[dataArray.length - 1];
      const atotalAmount = lastTransaction.balance || "";

      let forMemberDetails = response.data.member_data[0];

      console.log(forMemberDetails);
      setData(dataArray);
      setFullName(forMemberDetails.full_name);
      setAccountNo(forMemberDetails.member_id);
      setContribution(forMemberDetails.monthly_contribution);
      setTotalBalance(forMemberDetails.salary);
    } catch (error) {
      setData([]);
      setFullName("");
      setAccountNo("");
      setContribution("");
      setTotalBalance("");
      console.error("Error fetching employee numbers:", error);
    }
  };

  const debouncedHandleAccountNumberChange = useRef(
    debounce(handleAccountNumberChange, 300) // Adjust the delay (in milliseconds) as needed
  ).current;

  const handleCreditChange = async (e) => {
    const creditValue = parseFloat(e.target.value) || 0;

    try {
      // Fetch data and balance array
      const dataArray = await debouncedHandleAccountNumberChange(
        employeeNumber
      );
      const latestBalance = balanceData[balanceData.length - 1]; // Get the last balance from balanceData
      setSecondLast(latestBalance);
      setCredit(creditValue);

      // Calculate new total balance
      if (latestBalance) {
        const calculatedBalance = latestBalance + creditValue;
        setTotalBalance(calculatedBalance);
      }
    } catch (error) {
      console.error("Error handling credit change:", error);
    }
  };

  // useEffect(() => {
  //   console.log('Updated totalBalance:', totalBalance);
  //   console.log(Credit);
  //   console.log(secondLast)
  // }, [totalBalance,secondLast]);

  const [cashAmount, setCashAmount] = useState("");
  const [bankAmount, setBankAmount] = useState("");
  const [transferAmount, setTransferAmount] = useState("");

  const formRef = useRef(null);

  // async function handleTransferModal() {

  //   try {
  //     let response = await axios.post(
  //       "http://13.232.218.212:8000/cumulative_purchase/",
  //       {
  //         member_id: employeeNumber,
  //         share_price: BottomselectedOption === 'By cash' ? contribution : BottomselectedOption === 'To NEFT' ? totalBalance : '',
  //         payment_method: BottomselectedOption,
  //       }
  //     );
  //     if (
  //       "message" in response.data &&
  //       response.data.message === "Share taken successfully"
  //     ) {
  //       Swal.fire({
  //         icon: "success",
  //         title: "Amount Successfully Shared",
  //         text: "shared",
  //         customClass: {
  //           popup: "custom-swal-popup",
  //           confirmButton: "custom-swal-button",
  //         },
  //         didOpen: () => {
  //           Swal.getPopup().style.borderRadius = "25px";
  //         },
  //       });

  //       handleAccountNumberChange(employeeNumber);
  //       // setEmployeeNumber("");
  //       setTransactionAmount("");
  //       setBottomselectedOption("");
  //       setSelectedTransactionType("");
  //       // setReceiptVoucherNo("");
  //       setTransactionDetails("");
  //       setdisablecash(false);
  //       setdisablebank(false);
  //       setdisabletransfer(false);

  //       formRef.current.reset();

  //       return;
  //     } else if (
  //       "error_message" in response.data &&
  //       response.data.error_message.length > 2
  //     ) {
  //       Swal.fire({
  //         icon: "warning",
  //         title: "Member has not completed 20 shares.",
  //         text: " To complete your shareholding, consider exploring shares deposits.",
  //         customClass: {
  //           popup: "custom-swal-popup",
  //           confirmButton: "custom-swal-button",
  //         },
  //         didOpen: () => {
  //           Swal.getPopup().style.borderRadius = "25px";
  //         },
  //       });
  //       return;
  //     }

  //     console.log(response);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  const newCumulativeDepositEntry = async () => {
    const newCumulativeEntry = {
      member_id: employeeNumber,
      payment_method: purchaseType,
      credit_amount: Credit,
      check_no: CheckNo,
      name: fullName,
    };

    try {
      const response = await axios.post(
        "http://13.232.218.212:8000/cumulative_purchase/",
        newCumulativeEntry
      );

      setPostData(response.data);
    } catch (error) {
      console.error("Submit Error:", error);
      setError("Error submitting in course. Please try again later.");
    }
  };

  const customDateFormatter = (dateString) => {
    if (!dateString || typeof dateString !== "string") {
      return getCurrentDate();
    }

    const dateParts = dateString.split("-");
    if (dateParts.length !== 3) {
      return "Invalid Date Format";
    }

    const [year, month, day] = dateParts;
    return `${day}-${month}-${year}`;
  };

  const columns = [
    {
      name: "Date",
      selector: "DATE",
      sortable: true,
      center: true,
      cell: (row) => customDateFormatter(row.DATE),
    },
    // {
    //   name: "RV No.",
    //   selector: "RVNO",
    //   sortable: true,
    //   center: true,
    // },
    {
      name: "Particular",
      selector: "debit_credit",
      sortable: true,
      center: true,
    },
    {
      name: "Cheque No.",
      selector: "CHEQUENO",
      sortable: true,
      center: true,
    },
    {
      name: "Debit",
      selector: "debit",
      sortable: true,
      center: true,
    },
    {
      name: "Credit",
      selector: "AMOUNT",
      sortable: true,
      center: true,
    },
    // {
    //   name: "Share Price",
    //   selector: "share_price",
    //   sortable: true,
    //   center: true,
    // },
    {
      name: "Balance",
      selector: "balance",
      sortable: true,
      center: true,
    },
    // {
    //   name: "User",
    //   selector: "User",
    //   sortable: true,
    //   center: true,
    // },
  ];

  const showInputRow = true;

  const handleInputChange = (index, field, value) => {
    const updatedData = [...data];
    updatedData[index] = {
      ...updatedData[index],
      [field]: value,
    };
    setData(updatedData);
  };

  const handleSave = () => {
    const updatedData = [...data];
    updatedData.push(newData);

    setData(updatedData);
    setNewData({});
  };

  const customStyles = {
    rows: {
      style: {
        minHeight: "48px",
        textAlign: "center",
      },
    },
    headCells: {
      style: {
        minHeight: "40px",
        backgroundColor: "#4db3c8",
        fontSize: "14px",
        fontWeight: "400",
        color: "white",
        textAlign: "center",
      },
    },
  };

  const [disablecash, setdisablecash] = useState(false);
  const [disablebank, setdisablebank] = useState(false);
  const [disabletransfer, setdisabletransfer] = useState(false);

  // const handleAmount = (event) => {
  //   const { name, value } = event.target;

  //   if (name === "cashAmount") {
  //     setCashAmount(value);
  //     setdisablebank(true);
  //     setdisabletransfer(true);
  //   }
  //   if (name === "transferAmount") {
  //     setTransferAmount(value);
  //     setdisablebank(true);
  //     setdisablecash(true);
  //   }
  //   if (value === "") {
  //     setdisablebank(false);
  //     setdisablecash(false);
  //     setdisabletransfer(false);
  //   }
  // };

  // const [selectedTransactionType, setSelectedTransactionType] =
  //   useState("Select Type");

  // const [transactionDetails, setTransactionDetails] = useState("");

  // const [selectedBankTransfer, setSelectedBankTransfer] = useState("");

  const [processedTransactions, setProcessedTransactions] = useState([]);

  useEffect(() => {
    const processTransactions = () => {
      if (Array.isArray(data)) {
        const updatedTransactions = data.map((item) => {
          const newItem = { ...item };

          if (item.payment_method === "CASH") {
            newItem.transaction_method = "Deposit";
            newItem.credit = item.amount;
            newItem.debit = 0;
          } else if (item.payment_method === "TRANSFER") {
            newItem.transaction_method = "Withdrawal";
            newItem.debit = item.amount;
            newItem.credit = 0;
          }

          return newItem;
        });

        setProcessedTransactions(updatedTransactions);
      }
    };

    processTransactions();

    const intervalId = setInterval(() => {
      processTransactions();
    }, 5000);

    return () => clearInterval(intervalId);
  }, [data]);

  // console.log(processedTransactions);

  const handleReset = () => {
    setPurchaseType("");
    setCredit("");
    setCheckNo("");
    setPostData(null);
    setError(null);
  };

  return (
    <>
      <Sidebar />
      <div className="container-fluid dashboard-area d-flex">
        <div className="main-content p-4">
          {/* Navbar */}
          <Header />
          {/* Heading1 Main */}
          <div className="container-fluid d-flex text-start w-100 pb-1">
            <div className="H1-Heading-Main">
              Cumulative Deposit Receipt/Payment Entry
            </div>
          </div>

          <div className="container-fluid">
            {/* Your first form code */}
            <div className="row First-Main-Row  pt-3 pb-3">
              {/* Basic Information  */}
              <form>
                <div className="Border-Black p-2 mb-4">
                  <div className="row">
                    <div className="col-xl-5 col-lg-6 col-md-9 col-sm-9 text-start ">
                      <div
                        className="d-flex align-items-center"
                        style={{ gap: "0.5em" }}
                      >
                        <div for="rdId" className="small-label ms-1">
                          {/* Employee No. */}
                          Member ID
                        </div>
                        <input
                          type="text"
                          id="rdId"
                          name="employeeno"
                          class="form-control small-placeholder mx-0"
                          value={employeeNumber}
                          // onChange={(e) => handleAccountNumberChange(e.target.value)}
                          onChange={(e) => {
                            const numericValue = e.target.value.replace(
                              /[^0-9]/g,
                              ""
                            );
                            handleAccountNumberChange(numericValue);
                            // setEmployeeNumber(numericValue);
                          }}
                          min={0}
                          style={{ width: "37%" }}
                        />
                      </div>

                      {/* <div className="col-xl-6 col-lg-3 col-md-6 col-sm-6 text-start"> */}
                      <div class="no-outline-login my-1 ms-1 d-flex ">
                        <div for="" className="labels me-5">
                          Name
                        </div>
                        <div>: {employeeNumber === "" ? "" : fullName}</div>
                        {/* </div> */}
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-1 col-md-6 col-sm-9 text-start "></div>

                    <div className="col-xl-4 col-lg-5 col-md-9 col-sm-9 text-start ">
                      <label htmlFor="" className="labels ms-1">
                        Lock Amount : 0
                      </label>
                      <br />
                      <label htmlFor="" className="labels ms-1">
                        Account No. : {employeeNumber === "" ? "" : accountNo}
                      </label>
                      <br />
                      <label htmlFor="" className="labels ms-1">
                        Contribution :{" "}
                        {employeeNumber === "" ? "" : contribution}
                      </label>
                    </div>
                  </div>
                </div>
              </form>

              <button
                data-target="#exampleModalCenter"
                style={{ display: "none" }}
                data-toggle="modal"
                id="cummulative-modal-button"
              >
                click
              </button>

              {/* <div
                class="modal fade"
                id="exampleModalCenter"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-dialog-centered" role="document">
                  <div class="modal-content">
                    <div class="modal-header pb-0">
                      <h6 class="modal-title" id="exampleModalCenterTitle">
                        Transaction Details
                      </h6>
                      <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() => {
                          setCount(count + 1);
                        }}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body small-label py-0">
                      <div className="row w-100">
                        <div className="modal-body text-start">
                          <form ref={formRef}>
                            <div className="row mb-1 mt-0 pt-0">
                              <label
                                htmlFor="inputBalance3"
                                className="col-sm-4 col-form-label"
                              >
                                Balance in A/c
                              </label>
                              <div className="col-sm-8">
                                <input
                                  type="text"
                                  className="form-control no-outline-login"
                                  value={totalBalance}
                                  id="inputBalance3"
                                  name="totalSavingBalance"
                                  // onChange={}
                                />
                              </div>
                            </div>

                            <div className="row mb-1">
                              <label
                                htmlFor="inputTransfer3"
                                className="col-sm-4 col-form-label"
                              >
                                Cash Amount
                              </label>
                              <div className="col-sm-8">
                                <input
                                  type="number"
                                  className="form-control no-outline-login"
                                  id="inputTransfer3"
                                  name="cashAmount"
                                  // onChange={handleAmount}
                                  disabled={disablecash}
                                  value={BottomselectedOption==="By cash" ? contribution : ""}
                                  min={0}
                                />
                              </div>
                            </div>

                            <div className="row mb-1">
                              <label htmlFor="inputTransfer3" className="col-sm-4 col-form-label">Transfer Amount</label>
                              <div className="col-sm-8">
                                <input
                                  type="number"
                                  className="form-control no-outline-login"
                                  id="inputTransfer3"
                                  name="transferAmount"
                                  onChange={handleAmount}
                                  disabled={disabletransfer}
                                  value={BottomselectedOption==="To NEFT" ? totalBalance : ""}

                                  min={0}
                                />
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer d-flex justify-content-center align-items-center py-1">
                      <button
                        type="button"
                        class="btn btn-primary text-white "
                        data-dismiss="modal"
                        style={{ padding: "6px 18px" }}
                        // onClick={() => {
                        //   setCount(count + 1);
                        // }}
                      >
                        Close
                      </button>

                      <button
                        type="button"
                        className="btn btn-success"
                        onClick={handleTransferModal}
                      >
                        {BottomselectedOption === "To NEFT" ? "Transfer" : BottomselectedOption === "By cash" ? "Purchase" : "Select an option"}
                      </button>
                    </div>
                  </div>
                </div>
              </div> */}
              <DataTable
                columns={columns}
                data={processedTransactions}
                customStyles={customStyles}
                pagination
                striped
                dense
                responsive
              />

              <div className="row mt-3 mb-3">
                <div className="col-sm d-flex justify-content-center">
                  <button
                    type="button"
                    className="mt-2 mx-2"
                    onClick={newCumulativeDepositEntry}
                    style={{
                      padding: "7px 25px 7px 25px",
                      backgroundColor: "green",
                      color: "white",
                      fontWeight: "bold",
                      border: "none",
                      borderRadius: "7px",
                      fontSize: "16px",
                    }}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="mt-2 mx-2"
                    onClick={handleReset}
                    style={{
                      padding: "7px 25px 7px 25px",
                      backgroundColor: "red",
                      color: "white",
                      fontWeight: "bold",
                      border: "none",
                      borderRadius: "7px",
                      fontSize: "16px",
                    }}
                  >
                    Cancel
                  </button>
                </div>
                {/* <></> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
