import React, { useState, useEffect } from "react";
import Sidebar from "./Sidebar";
import Header from "../components/Header";
import axios from "axios";
import "../Style/Header-style.css";
import { BsPrinterFill } from "react-icons/bs";

const PrintCertificate = () => {
  const [memberId, setMemberId] = useState("");
  const [certificateType, setCertificateType] = useState("");
  const [certificateDetails, setCertificateDetails] = useState({});
  const [certificateNumbers, setCertificateNumbers] = useState([]);
  const [selectedCertificateNo, setSelectedCertificateNo] = useState("");
  const [tableData, setTableData] = useState({});

  useEffect(() => {
    // Fetch certificate numbers based on memberId and certificateType
    const fetchCertificateNumbers = async () => {
      try {
        if (memberId && certificateType) {
          const response = await axios.post(
            `http://13.232.218.212:8000/deposit_certificate/`,
            {
              member_id: memberId,
              Account_type: certificateType,
            }
          );
          const certificateNumbersArray = response.data.RDIDs || [];
          setCertificateNumbers(certificateNumbersArray);
        }
      } catch (error) {
        console.error("Error fetching certificate numbers:", error);
      }
    };

    fetchCertificateNumbers();
  }, [memberId, certificateType]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (memberId && certificateType && selectedCertificateNo) {
          const response = await axios.post(
            `http://13.232.218.212:8000/deposit_certificate/`,
            {
              member_id: memberId,
              RDID: selectedCertificateNo,
              Account_type: certificateType,
            }
          );
          const responseData = response.data || {};
          // const tableDataArray = Array.isArray(responseData)
          // ? responseData
          // : [];
          setTableData(responseData);
          setCertificateDetails(responseData);
          console.log(responseData);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the function to fetch data
    if (selectedCertificateNo) {
      fetchData();
    } else {
      // If selectedCertificateNo is empty, reset all fields
      setTableData({});
      setCertificateDetails({});
    }
  }, [memberId, certificateType, selectedCertificateNo]);

  const handleMemberIdChange = (e) => {
    setMemberId(e.target.value);
    setCertificateType("");
    setCertificateNumbers([]);
    setSelectedCertificateNo("");
  };

  const handleCertificateTypeChange = (e) => {
    setCertificateType(e.target.value);
  };

  const handleCertificateNoChange = (e) => {
    setSelectedCertificateNo(e.target.value);
  };

  const handlePrint = () => {
    setTimeout(() => {
      let printContents = document.getElementById("printable").innerHTML;
      let originalContents = document.body.innerHTML;
      const style = document.createElement("style");
      style.innerHTML = `
        @media print {
          .printbutton {
            display: none;
          }
          .print-container {
            margin: 5rem;
          }
        }
      `;
      document.head.appendChild(style);
      document.body.innerHTML = `<div class="print-container">${printContents}</div>`;
      window.print();
      document.body.innerHTML = originalContents;
      window.location.reload();
    }, 0);
  };

  return (
    <>
      <Sidebar />
      <div className="container-fluid dashboard-area d-flex">
        <div className="main-content p-4">
          {/* Navbar */}
          <Header />
          {/* Heading */}
          <div className="H1-Heading-Main text-start">Print Certificate's</div>
          <div className="row py-3 d-flex align-items-center">
            <div className="col-3 text-start">
              <input
                className="form-control"
                type="text"
                placeholder="Enter Member ID"
                value={memberId}
                onChange={handleMemberIdChange}
              />
            </div>
            <div className="col-3 text-start">
              <select
                className="form-select"
                value={certificateType}
                onChange={handleCertificateTypeChange}
              >
                <option value="">Select Certificate Type</option>
                <option value="CASH CERTIFICATE DEPOSIT">
                  Cash Certificate
                </option>
                <option value="TERM DEPOSIT">Term Deposit</option>
                <option value="LAKHPATI YOJANA DEPOSIT">Lakhpati Yojana</option>
              </select>
            </div>
            <div className="col-3 text-start">
              <select
                className="form-select"
                value={selectedCertificateNo}
                onChange={handleCertificateNoChange}
              >
                <option value="">Select Certificate No</option>
                {certificateNumbers.map((certificateNo) => (
                  <option key={certificateNo} value={certificateNo}>
                    {certificateNo}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-3 text-center">
              <BsPrinterFill
                onClick={handlePrint}
                style={{
                  fontSize: "1.7rem",
                  color: "darkblue",
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
          {/* Display other details */}
          <div id="printable">
            <div className="row text-start d-flex w-100">
              <div className="col-2">Ref No.</div>
              <div className="col-2">: &nbsp;{certificateDetails.emp_no}</div>
              <div className="col-4"></div>
              <div className="col-2">Member No.</div>
              <div className="col-2">:&nbsp; {memberId}</div>
            </div>
            <div className="row text-start d-flex w-100">
              <div className="col-2">Receipt No.</div>
              <div className="col-2">
                :&nbsp; {certificateDetails.receiptNo}
              </div>
            </div>
            <div className="row text-start d-flex w-100">
              <div className="col-2">Deposit Type</div>
              <div className="col-4">
                : &nbsp;<b>{certificateDetails.deposit_type}</b>
              </div>
              <div className="col-2"></div>
              <div className="col-2">Date</div>
              <div className="col-2">
                : &nbsp;
                {(() => {
                  const date = new Date(certificateDetails.date);
                  if (isNaN(date.getTime())) {
                    return "";
                  }
                  return date
                    .toLocaleDateString("en-GB", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })
                    .replace(/\//g, "-");
                })()}
              </div>
            </div>
            <div className="row text-start d-flex w-100">
              <div className="col-8"></div>
              <div className="col-2">A/c No.</div>
              <div className="col-2">:&nbsp; {certificateDetails.acc_no}</div>
            </div>
            <div className="row text-start d-flex w-100">
              <div className="col-2">Name</div>
              <div className="col-6">
                : &nbsp;<b>{certificateDetails.name}</b>
              </div>
            </div>
            <div className="row text-start d-flex w-100">
              <div className="col-2">In Words</div>
              <div className="col-6">: &nbsp;{certificateDetails.in_word}</div>
            </div>

            {/* Table to display data */}
            {certificateNumbers.length > 0 && (
              <table className="data-table mt-=3">
                <thead>
                  <tr>
                    <th>Deposit Date</th>
                    <th>Deposit Amount</th>
                    <th>Period</th>
                    <th>Interest Rate</th>
                    <th>Maturity Date</th>
                    <th>Maturity Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    <tr>
                      <td>
                        {(() => {
                          const date = new Date(tableData.deposit_date);
                          if (isNaN(date.getTime())) {
                            return "";
                          }
                          return date
                            .toLocaleDateString("en-GB", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            })
                            .replace(/\//g, "-");
                        })()}
                      </td>
                      <td>{tableData.deposit_amt}</td>
                      <td>{tableData.period}</td>
                      <td>{tableData.int_rate}</td>
                      <td>
                        {(() => {
                          const date = new Date(tableData.maturity_date);
                          if (isNaN(date.getTime())) {
                            return "";
                          }
                          return date
                            .toLocaleDateString("en-GB", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            })
                            .replace(/\//g, "-");
                        })()}
                      </td>
                      <td>{tableData.maturity_amt}</td>
                    </tr>
                  }
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PrintCertificate;
