import React from 'react';
import Logo from "../Images/Credit-Logo.png";
import { Link } from 'react-router-dom';

const PrivacyPolicies = () => {
    return (
        <div className='container-fluid d-flex' style={{flexDirection:"column"}}>
            <div className='row p-3 d-flex align-items-center' style={{backgroundColor:"#17a2b8"}}>
                <div className="col-4 text-start">
                    <img src={Logo} alt='' style={{height:"5rem"}}/>
                </div>
                <div className='col-4 text-white'>
                <h3>The BPC Employees’ Co-op. Credit Society Ltd 
                    </h3>
                </div>
                <div className="col-4 text-end">
                    <span> 
                        <Link to="/" style={{textDecoration:"underline 1px solid #17a2b8", color:"white"}}>
                            Login
                        </Link>
                    </span>
                </div>
            </div>
            <h1>Privacy Policy</h1>
            <p>Welcome to The BPC Employees’ Co-op. Credit Society Ltd., developed and maintained by The DataTech Labs Pune. <br/>This Privacy Policy outlines how we collect, use, and protect your personal information.</p>

            <h2>Information We Collect</h2>
            <p>We may collect various types of information, including but not limited to:</p>
           
                <div>Personal Information: Name, address, contact details, etc.</div>
                <div>Financial Information: Bank account details for transactions.</div>
                <div>Usage Information: How you interact with our platform.</div>
        

            <h2 className='pt-3'>How We Use Your Information</h2>
            <p>We use the collected information for purposes such as:</p>
            
                <div>Providing and maintaining our services.</div>
                <div>Processing transactions and managing accounts.</div>
                <div>Improving our website and services.</div>
           

            <h2>Security</h2>
            <p>We prioritize the security of your information. We implement measures to protect against unauthorized access and maintain data integrity.</p>

            {/* <h2>Third-Party Links</h2>
            <p>Our website may contain links to third-party sites. We are not responsible for the privacy practices or content of these sites.</p> */}

            <h2>Contact Us</h2>
            <p>If you have any questions or concerns about our Privacy Policy, please contact us at &nbsp;   
            <a href="https://tdtl.world" target="_blank" rel="noreferrer" style={{ textDecoration: "none" }}>
               The DataTech Labs Inc
            </a>.</p>
        </div>
    );
}

export default PrivacyPolicies;
