import React, { useState, useEffect } from "react";
import Sidebar from "./Sidebar";
import Header from "../components/Header";
import axios from "axios";
import DataTable from "react-data-table-component";

const GeneralLedger = () => {
  const [selectedOption, setSelectedOption] = useState([]);
  const [data, setData] = useState("");
  const [selectedDate, setSelectedDate] = useState(
    new Date().toISOString().split("T")[0]
  );

  useEffect(() => {
    if (selectedOption) {
      handleLedger();
    }
  }, [selectedOption, selectedDate]);

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  const handleLedger = async () => {
    const selectedOption = document.querySelector(".form-select").value;

    // Define default values for account_type and type
    let accountType = "";
    let transactionType = "";

    // Set values based on the selected option
    switch (selectedOption) {
      case "Recurring Deposit":
        accountType = "RECURRING DEPOSIT";
        transactionType = "Deposit";
        break;
      case "Interest Paid On Recurring Deposit":
        accountType = "Recurring Deposit";
        transactionType = "Interest";
        break;
      case "Installment On Recurring Deposit":
        accountType = "Recurring Deposit";
        transactionType = "Installment";
        break;

      case "Ek Laksha Deposit":
        accountType = "Ek Laksha Deposit";
        transactionType = "Deposit";
        break;
      case "Interest Paid On Ek Laksha Deposit":
        accountType = "Ek Laksha Deposit";
        transactionType = "Interest";
        break;
      case "Installment On Ek Laksha Deposit":
        accountType = "Ek Laksha Deposit";
        transactionType = "Installment";
        break;

      case "Lakhpati Deposit":
        accountType = "Lakhpati Deposit";
        transactionType = "Deposit";
        break;
      case "Interest Paid On Lakhpati Deposit":
        accountType = "Lakhpati Deposit";
        transactionType = "Interest";
        break;
      case "Installment On Lakhpati Deposit":
        accountType = "Lakhpati Deposit";
        transactionType = "Installment";
        break;

      default:
        break;
    }

    try {
      const response = await axios.post(
        "http://13.232.218.212:8000/general_ledger/",
        {
          account_type: accountType,
          type: transactionType,
          date: selectedDate,
        }
      );

      const responseData = response.data.data;
      console.log(responseData);
      if (responseData && typeof responseData === "object") {
        // Update the data state with the response data
        setData(responseData);
      } else {
        console.error("Invalid responseData structure:", responseData);
      }
      console.log(responseData);
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    { name: "Account Type", selector: "Account_type", sortable: true },
    { name: "Member Id", selector: "member_id", sortable: true },
    { name: "Employee No.", selector: "emp_no", sortable: true },
    { name: "Name", selector: "name", sortable: true },
    { name: "Account No.", selector: "RDID", sortable: true },
    { name: "Opening Balance", selector: "opening_balance", sortable: true },
    { name: "Deposit Period", selector: "deposit_period", sortable: true },
    { name: "Class", selector: "d_class", sortable: true },
    { name: "Date", selector: "date", sortable: true },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "55px",
        textAlign: "center",
      },
    },
    headCells: {
      style: {
        minHeight: "60px",
        backgroundColor: "#4db3c8",
        fontSize: "14px",
        fontWeight: "400",
        color: "white",
        textAlign: "center",
      },
    },
  };

  return (
    <>
      <Sidebar />
      <div className="container-fluid dashboard-area d-flex">
        <div className="main-content p-4">
          {/* Navbar */}
          <Header />
          <div className="container d-flex text-start w-100 pb-1">
            <div className="row w-100">
              <div className="col-6">
                <h3 style={{ fontWeight: "bold", color: "dodgerblue" }}>
                  General Ledger
                </h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              <select className="form-select" onChange={handleOptionChange}>
                <option value="">Select an Option</option>
                <option value="Recurring Deposit">Recurring Deposit</option>
                <option value="Interest Paid On Recurring Deposit">
                  Interest Paid On Recurring Deposit
                </option>
                <option value="Installment On Recurring Deposit">
                  Installment On Recurring Deposit
                </option>
                <option value="Ek Laksha Deposit">Ek Laksha Deposit</option>
                <option value="Interest Paid On Ek Laksha Deposit">
                  Interest Paid On Ek Laksha Deposit{" "}
                </option>
                <option value="Installment On Ek Laksha Deposit">
                  Installment On Ek Laksha Deposit
                </option>
                <option value="Lakhpati Deposit">Lakhpati Deposit</option>
                <option value="Interest Paid On Lakhpati Deposit">
                  Interest Paid On Lakhpati Deposit
                </option>
                <option value="Installment On Lakhpati Deposit">
                  Installment On Lakhpati Deposit
                </option>
              </select>
            </div>
            <div className="col-3">
              <input
                type="date"
                className="form-control"
                value={selectedDate}
                onChange={handleDateChange}
              />
            </div>
          </div>
          <div className="py-3">
            <DataTable
              data={data}
              columns={columns}
              customStyles={customStyles}
              striped
              dense
              pagination
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default GeneralLedger;
