import React, { useState, useEffect } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import Sidebar from "../Page/Sidebar";
import Header from "../components/Header";
import { ToastContainer, toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import "../Style/Global_Classes.css";
import "../Style/ScrunityFormPage.css";

export default function ScrutinyFormPage() {
  const [popupImage, setPopupImage] = useState(null);
  const [depositData, setDepositData] = useState([]);
  const [bond_no, setBond_no] = useState("");
  const [data, setData] = useState([]);
  const [loan_details, setLoaneeDetails] = useState("");
  const [member_id, setMember_Id] = useState();
  const [emp_no, setEmp_No] = useState();
  const [name, setName] = useState();
  const [salary, setSalary] = useState("");
  const [garontor1Id, setGarontor1Id] = useState("");
  const [garontor1EmpCode, setGarontor1EmpCode] = useState("");
  const [garontor1FullName, setGarontor1FullName] = useState("");
  const [garontor1Salary, setGarontor1Salary] = useState("");
  const [imageData, setImageData] = useState("");
  const [imageData1, setImageData1] = useState("");
  const [imageData3, setImageData3] = useState("");
  const [imageData4, setImageData4] = useState("");

  const [garontor2Id, setGarontor2Id] = useState("");
  const [garontor2EmpCode, setGarontor2EmpCode] = useState("");
  const [garontor2FullName, setGarontor2FullName] = useState("");
  const [garontor2Salary, setGarontor2Salary] = useState("");

  const [garontor3Id, setGarontor3Id] = useState("");
  const [garontor3EmpCode, setGarontor3EmpCode] = useState("");
  const [garontor3FullName, setGarontor3FullName] = useState("");
  const [garontor3Member_C, setGarontor3Member_C] = useState("");
  const [garontor3Salary, setGarontor3Salary] = useState("");

  const [isModalOpen, setModalOpen] = useState(false);

  let [scrunityApiResponse, setScrunityApiResponse] = useState(null);
  let [scrunityVisibility, setScrunityVisibility] = useState(false);

  const handleBondNumber = async (bond_no) => {
    try {
      if (bond_no === undefined || bond_no === "") {
        setEmp_No("");
        setName("");
        setMember_Id("");
        setSalary("");
        // setMICR_no("");
        setGarontor1Id("");
        setGarontor1EmpCode("");
        setGarontor1FullName("");
        setGarontor1Salary("");
        setGarontor2EmpCode("");
        setGarontor2Id("");
        setGarontor2FullName("");
        setGarontor2Salary("");
        setGarontor3Id("");
        setGarontor3EmpCode("");
        setGarontor3FullName("");
        setGarontor3Member_C("");
        return;
      }

      const response = await axios.post(
        "http://13.232.218.212:8000/scrutiny/",
        {
          bond_no: bond_no,
        }
      );

      const responseData = response.data;
      const loan_details = responseData.data.loan_details;
      const guranter_details = responseData.data.guranter_details;
      // console.log("guranter_details",guranter_details);

      if (loan_details) {
        setEmp_No(loan_details.emp_no || "");
        setName(loan_details.name || "");
        setMember_Id(loan_details.member_id || "");
        setSalary(loan_details.salary || "");
        // setMICR_no(loan_details.MICR_no || "");
      } else {
        console.error(
          "Error: emp_no not found in loan_details or loan_details is undefined"
        );
        console.log("Detailed loan_details:", loan_details);
      }
      if (guranter_details) {
        const firstGuarantor = guranter_details[0];
        const secondGuarantor = guranter_details[1];
        const thirdGuarantor = guranter_details[2];
        setGarontor1Id(firstGuarantor.guranter_member_no);
        setGarontor1EmpCode(firstGuarantor.guranter_emp_no);
        setGarontor1FullName(firstGuarantor.guranter_name);
        setGarontor1Salary(firstGuarantor.guranter_salary);

        setGarontor2Id(secondGuarantor.guranter_member_no);
        setGarontor2EmpCode(secondGuarantor.guranter_emp_no);
        setGarontor2FullName(secondGuarantor.guranter_name);
        setGarontor2Salary(secondGuarantor.guranter_salary);

        setGarontor3Id(thirdGuarantor.guranter_member_no);
        setGarontor3EmpCode(thirdGuarantor.guranter_emp_no);
        setGarontor3FullName(thirdGuarantor.guranter_name);
        setGarontor3Salary(thirdGuarantor.guranter_salary);
        // console.log("guranter_emp_no",garontor1EmpCode);
      }

      // console.log("Loan Details:", loan_details);
      setLoaneeDetails(loan_details);
      setBond_no(bond_no);
    } catch (error) {
      console.error("Error handling bond number:", error);
    }
  };

  // const imageUrl = `http://13.232.218.212:8000/scrutiny_loan_img/${bond_no}/`;

  // useEffect(() => {
  //   fetch(imageUrl)
  //     .then(response => response.json())
  //     .then(data => {
  //       if (data.success) {
  //         const base64ImageData = `data:image/png;base64,${data.image_data}`;
  //         setImageData(base64ImageData);
  //       }
  //     })
  //     .catch(error => console.error('Error fetching image:', error));
  // }, [imageUrl]);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await axios.get(
          `http://13.232.218.212:8000/scrutiny_loan_img/?member_id=${member_id}`,
          {
            responseType: "blob", // Important for handling binary data
            withCredentials: true, // To include cookies in the request
          }
        );

        const url = URL.createObjectURL(response.data);
        setImageData(url);
      } catch (error) {
        console.error("Error fetching image:", error);
      }
    };

    fetchImage();
  }, [member_id]);

  const Modal = ({ imageData, onClose }) => {
    return (
      <div className="modal">
        <div className="modal-content">
          <img src={imageData} alt="API Response Image" />
          <button onClick={onClose}>Close</button>
        </div>
      </div>
    );
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    // Optionally, clear the imageData state to reset the image in the modal
    setImageData(null);
  };

  // const imageUrl2 = `http://13.232.218.212:8000/fetch_image_new/${garontor1Id}/`;
  const [isModalOpen2, setModalOpen2] = useState(false);

  // useEffect(() => {
  //   fetch(imageUrl2)
  //     .then(response => response.json())
  //     .then(data => {
  //       if (data.success) {
  //         const base64ImageData = `data:image/png;base64,${data.image_data}`;
  //         setImageData1(base64ImageData);
  //       }
  //     })
  //     .catch(error => console.error('Error fetching image:', error));
  // }, [imageUrl2]);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await axios.get(
          `http://13.232.218.212:8000/scrutiny_loan_img/?member_id=${garontor1EmpCode}`,
          {
            responseType: "blob", // Important for handling binary data
            withCredentials: true, // To include cookies in the request0
          }
        );

        const url = URL.createObjectURL(response.data);
        setImageData1(url);
      } catch (error) {
        console.error("Error fetching image:", error);
      }

      // try {
      //   const response = await axios.get(
      //     "http://13.232.218.212:8000/scrutiny_loan_img/",
      //     {
      //       params: {
      //         member_id: garontor1Id,
      //       },
      //     }
      //   );

      //   if (response.data.success) {
      //     const base64ImageData = `data:image/png;base64,${response.data.image_data}`;
      //     setImageData1(base64ImageData);
      //   }
      // } catch (error) {
      //   console.error("Error fetching image:", error);
      // }
    };
    fetchImage();
  }, [garontor1Id]);

  const Modal2 = ({ imageData1, onClose }) => {
    return (
      <div className="modal">
        <div className="modal-content">
          <img src={imageData1} alt="API Response Image" />
          <button onClick={onClose}>Close</button>
        </div>
      </div>
    );
  };

  const handleCloseModal2 = () => {
    setModalOpen2(false);
    // Optionally, clear the imageData state to reset the image in the modal
    setImageData1(null);
  };
  // const imageUrl2 = `http://13.232.218.212:8000/fetch_image_new/${garontor1Id}/`;
  // const [isModalOpen2, setModalOpen2] = useState(false);

  // const Modal2 = ({ imageUrl, onClose }) => {
  //   return (
  //     <div className="modal">
  //       <div className="modal-content">
  //         <img src={imageUrl} alt="API Response Image" />
  //         <button onClick={onClose}>Close</button>
  //       </div>
  //     </div>
  //   );
  // };

  // const handleCloseModal2 = () => {
  //   // Close the second modal and reset the second image URL
  //   setModalOpen2(false);
  //   // setImageUrl2(null);
  // };
  //  const imageUrl3 = `http://13.232.218.212:8000/fetch_image_new/${garontor2Id}/`;
  //   const [isModalOpen3, setModalOpen3] = useState(false);

  //   const Modal3 = ({ imageUrl, onClose }) => {
  //     return (
  //       <div className="modal">
  //         <div className="modal-content">
  //           <img src={imageUrl} alt="API Response Image" />
  //           <button onClick={onClose}>Close</button>
  //         </div>
  //       </div>
  //     );
  //   };

  //   const handleCloseModal3= () => {
  //     // Close the second modal and reset the second image URL
  //     setModalOpen3(false);
  //     // setImageUrl2(null);
  //   };

  // const imageUrl3 = `http://13.232.218.212:8000/fetch_image_new/${garontor2Id}/`;
  const [isModalOpen3, setModalOpen3] = useState(false);

  // useEffect(() => {
  //   fetch(imageUrl3)
  //     .then(response => response.json())
  //     .then(data => {
  //       if (data.success) {
  //         const base64ImageData = `data:image/png;base64,${data.image_data}`;
  //         setImageData1(base64ImageData);
  //       }
  //     })
  //     .catch(error => console.error('Error fetching image:', error));
  // }, [imageUrl3]);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await axios.get(
          `http://13.232.218.212:8000/scrutiny_loan_img/?member_id=${garontor2EmpCode}`,
          {
            responseType: "blob", // Important for handling binary data
            withCredentials: true, // To include cookies in the request
          }
        );

        const url = URL.createObjectURL(response.data);
        setImageData3(url);
      } catch (error) {
        console.error("Error fetching image:", error);
      }
      // try {
      //   const response = await axios.get(
      //     "http://13.232.218.212:8000/scrutiny_loan_img/",
      //     {
      //       params: {
      //         member_id: garontor2Id,
      //       },
      //     }
      //   );

      //   if (response.data.success) {
      //     const base64ImageData = `data:image/png;base64,${response.data.image_data}`;
      //     setImageData3(base64ImageData);
      //   }
      // } catch (error) {
      //   console.error("Error fetching image:", error);
      // }
    };
    fetchImage();
  }, [garontor2Id]);

  const Modal3 = ({ imageData3, onClose }) => {
    return (
      <div className="modal">
        <div className="modal-content">
          <img src={imageData3} alt="API Response Image" />
          <button onClick={onClose}>Close</button>
        </div>
      </div>
    );
  };

  const handleCloseModal3 = () => {
    setModalOpen3(false);
    // Optionally, clear the imageData state to reset the image in the modal
    setImageData3(null);
  };
  // const imageUrl4 = `http://13.232.218.212:8000/fetch_image_new/${garontor3Id}/`;
  // const [isModalOpen4, setModalOpen4] = useState(false);

  // const Modal4 = ({ imageUrl, onClose }) => {
  //   return (
  //     <div className="modal">
  //       <div className="modal-content">
  //         <img src={imageUrl} alt="API Response Image" />
  //         <button onClick={onClose}>Close</button>
  //       </div>
  //     </div>
  //   );
  // };

  // const handleCloseModal4 = () => {
  //   // Close the second modal and reset the second image URL
  //   setModalOpen(false);
  //   // setImageUrl2(null);
  // };

  // const imageUrl4 = `http://13.232.218.212:8000/fetch_image_new/${garontor3Id}/`;
  const [isModalOpen4, setModalOpen4] = useState(false);

  // useEffect(() => {
  //   fetch(imageUrl4)
  //     .then(response => response.json())
  //     .then(data => {
  //       if (data.success) {
  //         const base64ImageData = `data:image/png;base64,${data.image_data}`;
  //         setImageData1(base64ImageData);
  //       }
  //     })
  //     .catch(error => console.error('Error fetching image:', error));
  // }, [imageUrl4]);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await axios.get(
          `http://13.232.218.212:8000/scrutiny_loan_img/?member_id=${garontor3EmpCode}`,
          {
            responseType: "blob", // Important for handling binary data
            withCredentials: true, // To include cookies in the request
          }
        );

        const url = URL.createObjectURL(response.data);
        setImageData4(url);
      } catch (error) {
        console.error("Error fetching image:", error);
      }
      // try {
      //   const response = await axios.get(
      //     "http://13.232.218.212:8000/scrutiny_loan_img/",
      //     {
      //       params: {
      //         member_id: garontor3Id,
      //       },
      //     }
      //   );

      //   if (response.data.success) {
      //     const base64ImageData = `data:image/png;base64,${response.data.image_data}`;
      //     setImageData4(base64ImageData);
      //   }
      // } catch (error) {
      //   console.error("Error fetching image:", error);
      // }
    };
    fetchImage();
  }, [garontor3Id]);

  const Modal4 = ({ imageData4, onClose }) => {
    return (
      <div className="modal">
        <div className="modal-content">
          <img src={imageData4} alt="API Response Image" />
          <button onClick={onClose}>Close</button>
        </div>
      </div>
    );
  };

  const handleCloseModal4 = () => {
    setModalOpen4(false);
    // Optionally, clear the imageData state to reset the image in the modal
    setImageData4(null);
  };

  // async function scrunityFormAPI() {
  //   let payload = {
  //     bond_no: bond_no,
  //   };
  //   try {
  //     let response = await axios.post(
  //       "http://13.232.218.212:8000/scrutiny_report/",
  //       payload
  //     );
  //     let temp = response.data.data;
  //     setScrunityApiResponse(temp);

  //     setTimeout(() => {
  //       setScrunityVisibility(true);
  //     }, 100);
  //   } catch (error) {
  //     Swal.fire({
  //       icon: "question",
  //       title: "Warning",
  //       text: "No data found htmlFor this bond no",
  //       didOpen: () => {
  //         Swal.getPopup().style.borderRadius = "25px";
  //         const confirmButton = Swal.getConfirmButton();
  //         confirmButton.classList.add("custom-swal-button");
  //       },
  //     });
  //     return;
  //     console.log(error);
  //   }
  // }
  // useEffect(()=>{
  //   scrunityFormAPI();
  // },[]);

  // function handleProcessBTn() {
  //   if (bond_no == "") {
  //     Swal.fire({
  //       icon: "question",
  //       title: "Warning",
  //       text: "Please Enter Valid Bond no",
  //       didOpen: () => {
  //         Swal.getPopup().style.borderRadius = "25px";
  //         const confirmButton = Swal.getConfirmButton();
  //         confirmButton.classList.add("custom-swal-button");
  //       },
  //     });
  //     return;
  //   }
  //   scrunityFormAPI();
  // }
  return (
    <>
      <Sidebar />
      <div className="container-fluid dashboard-area d-flex">
        <div className="main-content p-4">
          <Header />
          {/* First Modal Code  */}
          {!scrunityVisibility ? (
            <>
              <div
                className="modal fade"
                id="exampleModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        title
                      </h5>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      {imageData && (
                        <img
                          src={imageData}
                          alt="Fetched Image"
                          className="img-fluid"
                          width="300"
                          height="200"
                        />
                      )}

                      {/* Add other content as needed */}
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-dismiss="modal"
                        onClick={handleCloseModal}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* Second Modal Code  */}
              <div
                className="modal fade"
                id="exampleModal2"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel2"
                aria-hidden="true"
              >
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel2">
                        title2
                      </h5>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      {imageData1 && (
                        <img
                          src={imageData1}
                          alt="Fetched Image"
                          className="img-fluid"
                          width="300"
                          height="200"
                        />
                      )}
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-dismiss="modal"
                        onClick={handleCloseModal2}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* Third Modal Code  */}
              <div
                className="modal fade"
                id="exampleModal3"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel3"
                aria-hidden="true"
              >
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel3">
                        title3
                      </h5>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      {imageData3 && (
                        <img
                          src={imageData3}
                          alt="Fetched Image"
                          className="img-fluid"
                          width="300"
                          height="200"
                        />
                      )}
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-dismiss="modal"
                        onClick={handleCloseModal3}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* Third Modal Code  */}
              <div
                className="modal fade"
                id="exampleModal4"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel4"
                aria-hidden="true"
              >
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel4">
                        title4
                      </h5>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      {imageData4 && (
                        <img
                          src={imageData4}
                          alt="Fetched Image"
                          className="img-fluid"
                          width="300"
                          height="200"
                        />
                      )}
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-dismiss="modal"
                        onClick={handleCloseModal4}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container d-flex text-start w-100 pb-1">
                <div className="H1-Heading-Main">Loan Scrutiny Form</div>
              </div>

              <div className="container-fluid" style={{ width: "100%" }}>
                {/* Your first form code */}
                <div className="row First-Main-Row  pt-3 mb-3">
                  {/* Basic Information  */}
                  <form className="mb-3">
                    <div className="Border-Black p-1 mb-3">
                      <div className="row mb-3">
                        <div className="col-xl-3 col-lg-3  col-md-6 col-sm-6 text-center">
                          <div className="mt-4">
                            <label htmlFor="" className="">
                              Select Loan Type :
                            </label>
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 text-center">
                          <div className="mt-3 ">
                            <select
                              type="text"
                              id="rdId"
                              name="employeeno"
                              className="form-select small-label"
                              min={0}
                              // onChange={(e) =>
                              //   handleAccountNumberChange(e.target.value)
                              // }
                            >
                              <option>Select Loan Type</option>
                              <option>Medium Term Loan</option>
                              <option>Emergency Loan</option>
                              <option>Staff Medium Term Loan</option>
                              <option>Staff Emergency Loan</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 text-center">
                          <div className="mt-4">
                            <label htmlFor="" className="">
                              Loan Application No :
                            </label>
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-2 col-md-6 col-sm-6 text-center">
                          <div className="mt-3 ">
                            <input
                              type="text"
                              id="bond_no"
                              name="bond_no"
                              className="form-control small-label"
                              value={bond_no}
                              min={0}
                              onChange={(e) => {
                                setBond_no(e.target.value);
                                handleBondNumber(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row mt-2">
                      <div className="col-xl col-lg-3 col-md-6 col-sm-6 text-start">
                        <div className="mt-4">
                          <div className="">
                            <label htmlFor="" className="">
                              Loanee
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl col-lg-3 col-md-6 col-sm-6 text-start">
                        <div className="">
                          <label htmlFor="" className="">
                            Emp Code
                          </label>
                        </div>

                        <div className="">
                          <input
                            type="text"
                            id="emp_no"
                            name="emp_no"
                            className="form-control small-placeholder  bg-white"
                            value={emp_no}
                            onChange={(e) => setEmp_No(e.target.value)}
                            readOnly
                            min={0}
                          />
                        </div>
                      </div>
                      <div className="col-xl col-lg-3 col-md-6 col-sm-6 text-start">
                        <div className="">
                          <label htmlFor="" className="">
                            Mem No.
                          </label>
                        </div>
                        <div className="">
                          <input
                            type="text"
                            id="member_id"
                            name="member_id"
                            className="form-control small-placeholder  bg-white"
                            value={member_id}
                            onChange={(e) => setMember_Id(e.target.value)}
                            readOnly
                            min={0}
                          />
                        </div>
                      </div>
                      <div className="col-xl-1 col-lg-3 col-md-6 col-sm-6 text-start">
                        <div className="">
                          <label htmlFor="" className="">
                            Show
                          </label>
                        </div>
                        <div>
                          <span
                            type="button"
                            data-toggle="modal"
                            data-target="#exampleModal"
                            className="btn btn-primary w-100"
                            // onClick={handleSignClick}
                          >
                            Sign
                          </span>
                          {isModalOpen && (
                            <Modal
                              imageUrl={imageData}
                              onClose={handleCloseModal}
                            />
                          )}
                        </div>
                      </div>
                      <div className="col-xl-5 col-lg-3 col-md-6 col-sm-6 text-start">
                        <div className="">
                          <label htmlFor="" className="">
                            Name
                          </label>
                        </div>
                        <div className="">
                          <input
                            type="text"
                            className="form-control small-placeholder  bg-white"
                            id="name"
                            name="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-sm text-start">
                        <div className="">
                          <div className="">
                            <label htmlFor="" className="">
                              Salary
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm text-start">
                        <div className="">
                          <input
                            type="text"
                            className="form-control"
                            id="salary"
                            name="salary"
                            placeholder="₹"
                            value={salary}
                            onChange={(e) => {
                              setSalary(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-sm text-start">
                        <div className="">
                          <input
                            type="text"
                            id="rdId"
                            name="employeeno"
                            className="form-control small-label"
                            min={0}
                          />
                        </div>
                      </div>
                      <div className="col-sm-1 text-start">
                        <div className="">
                          <div className="">
                            {/* <button className="w-100">Sign</button> */}
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-5 text-start">
                        <div className="row ">
                          {/* <div className="col-xl-6 col-lg-3 col-md-6 col-sm-6 text-start"> */}
                          <div className="col-sm-6 text-start">
                            <div className="">
                              <label htmlFor="" className="">
                                Membership Application :
                              </label>
                            </div>
                          </div>

                          {/* <div className="col-xl-6 col-lg-3 col-md-6 col-sm-6 text-start"> */}
                          <div className="col-sm-6 text-start">
                            <div className="">
                              <input
                                type="text"
                                id="rdId"
                                name="employeeno"
                                className="form-control small-label"
                                min={0}
                                // onChange={(e) =>
                                //   handleAccountNumberChange(e.target.value)
                                // }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-xl col-lg-3 col-md-6 col-sm-6 text-start">
                        <div className="">
                          <div className="">
                            <label htmlFor="" className="">
                              B+Da+Hra
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl col-lg-3 col-md-6 col-sm-6 text-start">
                        <div className="">
                          <input
                            type="text"
                            id="rdId"
                            name="employeeno"
                            className="form-control small-label"
                            min={0}
                            // onChange={(e) =>
                            //   handleAccountNumberChange(e.target.value)
                            // }
                          />
                        </div>
                      </div>
                      <div className="col-xl col-lg-3 col-md-6 col-sm-6 text-start">
                        <div className="d-flex justify-content-center align-item-center m-1">
                          <label>4 Deduct</label>
                        </div>
                      </div>
                      <div className="col-xl-1 col-lg-3 col-md-6 col-sm-6 text-start">
                        <div className="">
                          <div className="">
                            {/* <button className="w-100">Sign</button> */}
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-5 text-start">
                        <div className="row ">
                          {/* <div className="col-xl-6 col-lg-3 col-md-6 col-sm-6 text-start"> */}
                          <div className="col-sm-6 text-start">
                            <div className="">
                              <label htmlFor="" className="">
                                Loan Demanded :
                              </label>
                            </div>
                          </div>

                          {/* <div className="col-xl-6 col-lg-3 col-md-6 col-sm-6 text-start"> */}
                          <div className="col-sm-6 text-start">
                            <div className="">
                              <input
                                type="text"
                                id="rdId"
                                name="employeeno"
                                className="form-control small-label"
                                min={0}
                                // onChange={(e) =>
                                //   handleAccountNumberChange(e.target.value)
                                // }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl col-lg-3 col-md-6 col-sm-6 text-start">
                        <div className="">
                          <div className="">
                            <label htmlFor="" className="">
                              Net Pay
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl col-lg-3 col-md-6 col-sm-6 text-start">
                        <div className="">
                          <input
                            type="text"
                            id="rdId"
                            name="employeeno"
                            className="form-control small-label"
                            min={0}
                            // onChange={(e) =>
                            //   handleAccountNumberChange(e.target.value)
                            // }
                          />
                        </div>
                      </div>
                      <div className="col-xl col-lg-3 col-md-6 col-sm-6 text-start">
                        <div className="">
                          <input
                            type="text"
                            id="rdId"
                            name="employeeno"
                            className="form-control small-label"
                            min={0}
                            // onChange={(e) =>
                            //   handleAccountNumberChange(e.target.value)
                            // }
                          />
                        </div>
                      </div>
                      <div className="col-xl-1 col-lg-3 col-md-6 col-sm-6 text-start">
                        <div className="">
                          <div className="">
                            {/* <button className="w-100">Sign</button> */}
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-5 text-start">
                        <div className="row ">
                          {/* <div className="col-xl-6 col-lg-3 col-md-6 col-sm-6 text-start"> */}
                          <div className="col-sm-6 text-start">
                            <div className="">
                              <label htmlFor="" className="">
                                Basic+Da+Hra Eligible :
                              </label>
                            </div>
                          </div>

                          {/* <div className="col-xl-6 col-lg-3 col-md-6 col-sm-6 text-start"> */}
                          <div className="col-sm-6 text-start">
                            <div className="">
                              <input
                                type="text"
                                id="rdId"
                                name="employeeno"
                                className="form-control small-label"
                                min={0}
                                // onChange={(e) =>
                                //   handleAccountNumberChange(e.target.value)
                                // }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-xl col-lg-3 col-md-6 col-sm-6 text-start">
                        <div className="">
                          <div className="">
                            <label htmlFor="" className="">
                              Soc Deduct
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl col-lg-3 col-md-6 col-sm-6 text-start">
                        <div className="">
                          <input
                            type="text"
                            id="rdId"
                            name="employeeno"
                            className="form-control small-label"
                            min={0}
                            // onChange={(e) =>
                            //   handleAccountNumberChange(e.target.value)
                            // }
                          />
                        </div>
                      </div>
                      <div className="col-xl col-lg-3 col-md-6 col-sm-6 text-start"></div>
                      <div className="col-xl-1 col-lg-3 col-md-6 col-sm-6 text-start">
                        <div className="">
                          <div className="">
                            {/* <button className="w-100">Sign</button> */}
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-5 text-start">
                        <div className="row ">
                          {/* <div className="col-xl-6 col-lg-3 col-md-6 col-sm-6 text-start"> */}
                          <div className="col-sm-6 text-start">
                            <div className="">
                              <label htmlFor="" className="">
                                Share+CD Eligible :
                              </label>
                            </div>
                          </div>

                          {/* <div className="col-xl-6 col-lg-3 col-md-6 col-sm-6 text-start"> */}
                          <div className="col-sm-6 text-start">
                            <div className="">
                              <input
                                type="text"
                                id="rdId"
                                name="employeeno"
                                className="form-control small-label"
                                min={0}
                                // onChange={(e) =>
                                //   handleAccountNumberChange(e.target.value)
                                // }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-xl-2 col-lg-3 col-md-6 col-sm-6 text-start">
                        {/* <div className=""> */}
                        <div className="">
                          <label htmlFor="" className=""></label>
                        </div>
                        <div className="mt-2">
                          <label htmlFor="" className="">
                            Guarantor 1
                          </label>
                        </div>
                        {/* </div> */}
                      </div>
                      <div className="col-xl col-lg-3 col-md-6 col-sm-6 text-start">
                        <div className="">
                          <label htmlFor="" className="">
                            Emp Code
                          </label>
                        </div>
                        <div className="">
                          <input
                            type="text"
                            id="garontor1EmpCode"
                            name="garontor1EmpCode"
                            className="form-control small-placeholder bg-white"
                            value={garontor1EmpCode}
                            onChange={(e) => {
                              setGarontor1EmpCode(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-xl col-lg-3 col-md-6 col-sm-6 text-start">
                        <div className="">
                          <label htmlFor="" className="">
                            Mem No.
                          </label>
                        </div>
                        <div className="">
                          <input
                            type="text"
                            id="garontor1Id"
                            name="garontor1Id"
                            className="form-control small-placeholder bg-white"
                            value={garontor1Id}
                            onChange={(e) => {
                              setGarontor1Id(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-xl-1 col-lg-3 col-md-6 col-sm-6 text-start">
                        <div className="">
                          <label htmlFor="" className="">
                            Show
                          </label>
                        </div>
                        <div className="">
                          {/* <span
                    
                      
                        type="button"
                        data-toggle="modal"
                        data-target="#exampleModal2"
                        className="btn btn-primary w-100"
                      >
                        Sign 2
                      </span> */}

                          <div>
                            <span
                              type="button"
                              data-toggle="modal"
                              data-target="#exampleModal2"
                              className="btn btn-primary w-100"
                              // onClick={handleSignClick2}
                            >
                              Sign 2
                            </span>
                            {isModalOpen2 && (
                              <Modal2
                                imageUrl={imageData1}
                                onClose={handleCloseModal2}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-3 col-md-6 col-sm-6 text-start">
                        <div className="">
                          <label htmlFor="" className="">
                            Name
                          </label>
                        </div>
                        <div className="">
                          <input
                            type="text"
                            id="garontor1FullName"
                            name="garontor1FullName"
                            className="form-control small-placeholder bg-white"
                            value={garontor1FullName}
                            onChange={(e) => {
                              setGarontor1FullName(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-xl-2 col-lg-3 col-md-6 col-sm-6 text-start">
                        <div className="">
                          <label htmlFor="" className="">
                            Salary
                          </label>
                        </div>
                        <div className="">
                          <input
                            type="text"
                            id="garontor2Salary"
                            name="garontorSalary"
                            className="form-control small-placeholder bg-white"
                            value={garontor1Salary}
                            onChange={(e) => {
                              setGarontor1Salary(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-xl-2 col-lg-3 col-md-6 col-sm-6 text-start ">
                        <div className="">
                          <div className="">
                            <label htmlFor="" className="">
                              Guarantor 2
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl col-lg-3 col-md-6 col-sm-6 text-start">
                        <div className="">
                          <input
                            type="text"
                            id="garontor2EmpCode"
                            name="garontor2EmpCode"
                            className="form-control small-label"
                            min={0}
                            value={garontor2EmpCode}
                            onChange={(e) => {
                              setGarontor2EmpCode(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-xl col-lg-3 col-md-6 col-sm-6 text-start">
                        <div className="">
                          <input
                            type="text"
                            id="garontor2Id"
                            name="garontor2Id"
                            className="form-control small-placeholder bg-white"
                            value={garontor2Id}
                            onChange={(e) => {
                              setGarontor2Id(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-xl-1 col-lg-3 col-md-6 col-sm-6 text-start">
                        <div className="">
                          <div>
                            <span
                              type="button"
                              data-toggle="modal"
                              data-target="#exampleModal3"
                              className="btn btn-primary w-100"
                              // onClick={handleSignClick2}
                            >
                              Sign 3
                            </span>
                            {isModalOpen3 && (
                              <Modal2
                                imageUrl={imageData3}
                                onClose={handleCloseModal3}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-3 col-md-6 col-sm-6 text-start">
                        <div className="">
                          <input
                            type="text"
                            id="garontor2FullName"
                            name="garontor2FullName"
                            className="form-control small-placeholder bg-white"
                            value={garontor2FullName}
                            onChange={(e) => {
                              setGarontor1FullName(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-xl-2 col-lg-3 col-md-6 col-sm-6 text-start">
                        <div className="">
                          <input
                            type="text"
                            id="garontor1Salary"
                            name="garontor1Salary"
                            className="form-control small-placeholder bg-white"
                            value={garontor2Salary}
                            onChange={(e) => {
                              setGarontor2Salary(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row mt-2">
                      <div className="col-xl-2 col-lg-3 col-md-6 col-sm-6 text-start ">
                        <div className="">
                          <div className="">
                            <label htmlFor="" className="">
                              Guarantor 3
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl col-lg-3 col-md-6 col-sm-6 text-start">
                        <div className="">
                          <input
                            type="text"
                            id="garontor3EmpCode"
                            name="garontor3EmpCode"
                            className="form-control small-label"
                            min={0}
                            value={garontor3EmpCode}
                            onChange={(e) => {
                              setGarontor3EmpCode(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-xl col-lg-3 col-md-6 col-sm-6 text-start">
                        <div className="">
                          <input
                            type="text"
                            id="garontor3Id"
                            name="garontor3Id"
                            className="form-control small-placeholder bg-white"
                            value={garontor3Id}
                            onChange={(e) => {
                              setGarontor3Id(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-xl-1 col-lg-3 col-md-6 col-sm-6 text-start">
                        <div className="">
                          {/* <div className="">
                        <span className="btn btn-primary w-100">Sign</span>
                      </div> */}
                          <div>
                            <span
                              type="button"
                              data-toggle="modal"
                              data-target="#exampleModal4"
                              className="btn btn-primary w-100"
                              // onClick={handleSignClick2}
                            >
                              Sign 4
                            </span>
                            {isModalOpen4 && (
                              <Modal2
                                imageUrl={imageData4}
                                onClose={handleCloseModal4}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-3 col-md-6 col-sm-6 text-start">
                        <div className="">
                          <input
                            type="text"
                            id="garontor3FullName"
                            name="garontor3FullName"
                            className="form-control small-placeholder bg-white"
                            value={garontor3FullName}
                            onChange={(e) => {
                              setGarontor3FullName(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-xl-2 col-lg-3 col-md-6 col-sm-6 text-start">
                        <div className="">
                          <input
                            type="text"
                            id="garontor3Salary"
                            name="garontor3Salary"
                            className="form-control small-placeholder bg-white"
                            value={garontor3Salary}
                            onChange={(e) => {
                              setGarontor3Salary(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              {/* <div className="row mt-3 justify-content-center">
                <div className="col-sm-3">
                  <button
                    type="button"
                    className="btn btn-success saveButton mt-2"
                    onClick={handleProcessBTn}
                  >
                    Process
                  </button>
                </div>
                <div className="col-sm-3">
                  <button
                    type="button"
                    className="btn btn-danger cancelButton mt-2"
                  >
                    Exit
                  </button>
                </div>
              </div> */}
            </>
          ) : (
            <div className="scrunity-form-outer-box mt-4 px-3 ">
              <h5 className="text-center fw-bold mt-3">
                The B P C Employee's Co-op. Credit Society Ltd.
              </h5>

              <div className="d-flex align-items-center justify-content-between w-100 mt-3">
                <h6>MLOAN 108</h6>
                <h5>LOAN SCRUTINY REPORT</h5>
                <h6>Run Date: 11/01/2024</h6>
              </div>

              <div className="w-100">
                {scrunityApiResponse ? (
                  <table className="scrunity-form-table">
                    <thead>
                      <tr>
                        <th className="scrunity-form-th">NO.</th>
                        <th className="scrunity-form-th">PARTICULARS</th>
                        <th className="scrunity-form-th">LOANEE</th>
                        <th className="scrunity-form-th">SURETY NO. 1</th>
                        <th className="scrunity-form-th">SURETY NO. 2</th>
                        <th className="scrunity-form-th">SURETY NO. 3</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="scrunity-form-td">1</td>
                        <td className="scrunity-form-td scrunity-form-particular">
                          Name
                        </td>
                        <td className="scrunity-form-td">
                          {scrunityApiResponse.loan_details.name}
                        </td>
                        <td className="scrunity-form-td">
                          {
                            scrunityApiResponse.guranter_details[0]
                              .guranter_name
                          }
                        </td>
                        <td className="scrunity-form-td">
                          {
                            scrunityApiResponse.guranter_details[1]
                              .guranter_name
                          }
                        </td>
                        <td className="scrunity-form-td">
                          {
                            scrunityApiResponse.guranter_details[2]
                              .guranter_name
                          }
                        </td>
                      </tr>
                      <tr>
                        <td className="scrunity-form-td">2</td>
                        <td className="scrunity-form-td scrunity-form-particular">
                          Employee No.
                        </td>
                        <td className="scrunity-form-td">
                          {scrunityApiResponse.loan_details.emp_no}
                        </td>
                        <td className="scrunity-form-td">
                          {
                            scrunityApiResponse.guranter_details[0]
                              .guranter_emp_no
                          }
                        </td>
                        <td className="scrunity-form-td">
                          {
                            scrunityApiResponse.guranter_details[1]
                              .guranter_emp_no
                          }
                        </td>
                        <td className="scrunity-form-td">
                          {
                            scrunityApiResponse.guranter_details[2]
                              .guranter_emp_no
                          }
                        </td>
                      </tr>
                      <tr>
                        <td className="scrunity-form-td">3</td>
                        <td className="scrunity-form-td scrunity-form-particular">
                          Membership No.
                        </td>
                        <td className="scrunity-form-td">
                          {scrunityApiResponse.loan_details.member_id}
                        </td>
                        <td className="scrunity-form-td">
                          {
                            scrunityApiResponse.guranter_details[0]
                              .guranter_member_no
                          }
                        </td>
                        <td className="scrunity-form-td">
                          {
                            scrunityApiResponse.guranter_details[1]
                              .guranter_member_no
                          }
                        </td>
                        <td className="scrunity-form-td">
                          {
                            scrunityApiResponse.guranter_details[2]
                              .guranter_member_no
                          }
                        </td>
                      </tr>
                      <tr>
                        <td className="scrunity-form-td">4</td>
                        <td className="scrunity-form-td scrunity-form-particular">
                          Date of Birth
                        </td>
                        <td className="scrunity-form-td">
                          {scrunityApiResponse.loan_details.birth_date}
                        </td>
                        <td className="scrunity-form-td">
                          {
                            scrunityApiResponse.guranter_details[0]
                              .guranter_birth_date
                          }
                        </td>
                        <td className="scrunity-form-td">
                          {
                            scrunityApiResponse.guranter_details[1]
                              .guranter_birth_date
                          }
                        </td>
                        <td className="scrunity-form-td">
                          {
                            scrunityApiResponse.guranter_details[2]
                              .guranter_birth_date
                          }
                        </td>
                      </tr>
                      <tr>
                        <td className="scrunity-form-td">5</td>
                        <td className="scrunity-form-td scrunity-form-particular">
                          Date of Membership
                        </td>
                        <td className="scrunity-form-td">
                          {scrunityApiResponse.loan_details.opening_date}
                        </td>
                        <td className="scrunity-form-td">
                          {
                            scrunityApiResponse.guranter_details[0]
                              .guranter_opening_date
                          }
                        </td>
                        <td className="scrunity-form-td">
                          {
                            scrunityApiResponse.guranter_details[1]
                              .guranter_opening_date
                          }
                        </td>
                        <td className="scrunity-form-td">
                          {
                            scrunityApiResponse.guranter_details[2]
                              .guranter_opening_date
                          }
                        </td>
                      </tr>
                      <tr>
                        <td className="scrunity-form-td">6</td>
                        <td className="scrunity-form-td scrunity-form-particular">
                          Date of Retirement
                        </td>
                        <td className="scrunity-form-td">
                          {scrunityApiResponse.loan_details.retire_on}
                        </td>
                        <td className="scrunity-form-td">
                          {
                            scrunityApiResponse.guranter_details[0]
                              .guranter_retire_on
                          }
                        </td>
                        <td className="scrunity-form-td">
                          {
                            scrunityApiResponse.guranter_details[1]
                              .guranter_retire_on
                          }
                        </td>
                        <td className="scrunity-form-td">
                          {
                            scrunityApiResponse.guranter_details[2]
                              .guranter_retire_on
                          }
                        </td>
                      </tr>
                      <tr>
                        <td className="scrunity-form-td">7</td>
                        <td className="scrunity-form-td scrunity-form-particular">
                          Months of Membership
                        </td>
                        <td className="scrunity-form-td">
                          {
                            scrunityApiResponse.loan_details
                              .months_of_membership
                          }
                        </td>
                        <td className="scrunity-form-td">
                          {
                            scrunityApiResponse.guranter_details[0]
                              .guranter_months_of_membership
                          }
                        </td>
                        <td className="scrunity-form-td">
                          {
                            scrunityApiResponse.guranter_details[1]
                              .guranter_months_of_membership
                          }
                        </td>
                        <td className="scrunity-form-td">
                          {
                            scrunityApiResponse.guranter_details[2]
                              .guranter_months_of_membership
                          }
                        </td>
                      </tr>
                      <tr>
                        <td className="scrunity-form-td">8</td>
                        <td className="scrunity-form-td scrunity-form-particular">
                          Months to Retire
                        </td>
                        <td className="scrunity-form-td">
                          {scrunityApiResponse.loan_details.months_to_retire}
                        </td>
                        <td className="scrunity-form-td">
                          {
                            scrunityApiResponse.guranter_details[0]
                              .guranter_months_to_retire
                          }
                        </td>
                        <td className="scrunity-form-td">
                          {
                            scrunityApiResponse.guranter_details[1]
                              .guranter_months_to_retire
                          }
                        </td>
                        <td className="scrunity-form-td">
                          {
                            scrunityApiResponse.guranter_details[2]
                              .guranter_months_to_retire
                          }
                        </td>
                      </tr>
                      <tr>
                        <td className="scrunity-form-td">9</td>
                        <td className="scrunity-form-td scrunity-form-particular">
                          Basic Salary
                        </td>
                        <td className="scrunity-form-td">
                          {scrunityApiResponse.loan_details.salary}
                        </td>
                        <td className="scrunity-form-td">
                          {
                            scrunityApiResponse.guranter_details[0]
                              .guranter_salary
                          }
                        </td>
                        <td className="scrunity-form-td">
                          {
                            scrunityApiResponse.guranter_details[1]
                              .guranter_salary
                          }
                        </td>
                        <td className="scrunity-form-td">
                          {
                            scrunityApiResponse.guranter_details[2]
                              .guranter_salary
                          }
                        </td>
                      </tr>
                      <tr>
                        <td className="scrunity-form-td">10</td>
                        <td className="scrunity-form-td scrunity-form-particular">
                          Gross Salary
                        </td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                      </tr>
                      <tr>
                        <td className="scrunity-form-td">11</td>
                        <td className="scrunity-form-td scrunity-form-particular">
                          Net Salary
                        </td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                      </tr>
                      <tr>
                        <td className="scrunity-form-td">12</td>
                        <td className="scrunity-form-td scrunity-form-particular">
                          Society Deduction
                        </td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                      </tr>
                      <tr>
                        <td className="scrunity-form-td">13</td>
                        <td className="scrunity-form-td scrunity-form-particular">
                          Share + CD Balance
                        </td>
                        <td className="scrunity-form-td">
                          {
                            scrunityApiResponse.loan_details
                              .total_shares_cumulative
                          }
                        </td>
                        <td className="scrunity-form-td">
                          {
                            scrunityApiResponse.guranter_details[0]
                              .guranter_total_shares_cumulative
                          }
                        </td>
                        <td className="scrunity-form-td">
                          {
                            scrunityApiResponse.guranter_details[1]
                              .guranter_total_shares_cumulative
                          }
                        </td>
                        <td className="scrunity-form-td">
                          {
                            scrunityApiResponse.guranter_details[2]
                              .guranter_total_shares_cumulative
                          }
                        </td>
                      </tr>
                      <tr>
                        <td className="scrunity-form-td">14</td>
                        <td className="scrunity-form-td scrunity-form-particular">
                          MTL Loan Date
                        </td>
                        <td className="scrunity-form-td">
                          {scrunityApiResponse.loan_details.MTL_loan_date}
                        </td>
                        <td className="scrunity-form-td">
                          {
                            scrunityApiResponse.guranter_details[0]
                              .guranter_MTL_loan_date
                          }
                        </td>
                        <td className="scrunity-form-td">
                          {
                            scrunityApiResponse.guranter_details[1]
                              .guranter_MTL_loan_date
                          }
                        </td>
                        <td className="scrunity-form-td">
                          {
                            scrunityApiResponse.guranter_details[2]
                              .guranter_MTL_loan_date
                          }
                        </td>
                      </tr>
                      <tr>
                        <td className="scrunity-form-td">15</td>
                        <td className="scrunity-form-td scrunity-form-particular">
                          MTL Loan Amount
                        </td>
                        <td className="scrunity-form-td">
                          {scrunityApiResponse.loan_details.MTL_loan_amt}
                        </td>
                        <td className="scrunity-form-td">
                          {
                            scrunityApiResponse.guranter_details[0]
                              .guranter_MTL_loan_amt
                          }
                        </td>
                        <td className="scrunity-form-td">
                          {
                            scrunityApiResponse.guranter_details[1]
                              .guranter_MTL_loan_amt
                          }
                        </td>
                        <td className="scrunity-form-td">
                          {
                            scrunityApiResponse.guranter_details[2]
                              .guranter_MTL_loan_amt
                          }
                        </td>
                      </tr>
                      <tr>
                        <td className="scrunity-form-td">16</td>
                        <td className="scrunity-form-td scrunity-form-particular">
                          MTL Loan Paid
                        </td>
                        <td className="scrunity-form-td">
                          {scrunityApiResponse.loan_details.MTL_loan_paid}
                        </td>
                        <td className="scrunity-form-td">
                          {
                            scrunityApiResponse.guranter_details[0]
                              .guranter_MTL_loan_paid
                          }
                        </td>
                        <td className="scrunity-form-td">
                          {
                            scrunityApiResponse.guranter_details[1]
                              .guranter_MTL_loan_paid
                          }
                        </td>
                        <td className="scrunity-form-td">
                          {
                            scrunityApiResponse.guranter_details[2]
                              .guranter_MTL_loan_paid
                          }
                        </td>
                      </tr>
                      <tr>
                        <td className="scrunity-form-td">17</td>
                        <td className="scrunity-form-td scrunity-form-particular">
                          MTL Loan Balance
                        </td>
                        <td className="scrunity-form-td">
                          {scrunityApiResponse.loan_details.MTL_loan_balance}
                        </td>
                        <td className="scrunity-form-td">
                          {
                            scrunityApiResponse.guranter_details[0]
                              .guranter_MTL_loan_balance
                          }
                        </td>
                        <td className="scrunity-form-td">
                          {
                            scrunityApiResponse.guranter_details[1]
                              .guranter_MTL_loan_balance
                          }
                        </td>
                        <td className="scrunity-form-td">
                          {
                            scrunityApiResponse.guranter_details[2]
                              .guranter_MTL_loan_balance
                          }
                        </td>
                      </tr>
                      <tr>
                        <td className="scrunity-form-td">18</td>
                        <td className="scrunity-form-td scrunity-form-particular">
                          EMG Loan Balance
                        </td>
                        <td className="scrunity-form-td">
                          {scrunityApiResponse.loan_details.EL_loan_balance}
                        </td>
                        <td className="scrunity-form-td">
                          {
                            scrunityApiResponse.guranter_details[0]
                              .guranter_EL_loan_balance
                          }
                        </td>
                        <td className="scrunity-form-td">
                          {
                            scrunityApiResponse.guranter_details[1]
                              .guranter_EL_loan_balance
                          }
                        </td>
                        <td className="scrunity-form-td">
                          {
                            scrunityApiResponse.guranter_details[2]
                              .guranter_EL_loan_balance
                          }
                        </td>
                      </tr>
                      <tr>
                        <td className="scrunity-form-td">19</td>
                        <td className="scrunity-form-td scrunity-form-particular">
                          Deficit in Share+CD
                        </td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                      </tr>
                      <tr>
                        <td className="scrunity-form-td">20</td>
                        <td className="scrunity-form-td scrunity-form-particular">
                          Deficit in MTL
                        </td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                      </tr>
                      <tr>
                        <td className="scrunity-form-td">21</td>
                        <td className="scrunity-form-td scrunity-form-particular">
                          Allowable Deduct Amnt
                        </td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                      </tr>
                      <tr>
                        <td className="scrunity-form-td">22</td>
                        <td className="scrunity-form-td scrunity-form-particular">
                          Maximum Liability
                        </td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                      </tr>
                      <tr>
                        <td className="scrunity-form-td">23</td>
                        <td className="scrunity-form-td scrunity-form-particular">
                          LAST Paid Date
                        </td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                      </tr>
                      <tr>
                        <td className="scrunity-form-td">24</td>
                        <td className="scrunity-form-td scrunity-form-particular">
                          C/R/E/S/D Date
                        </td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                      </tr>
                      <tr>
                        <td className="scrunity-form-td">25</td>
                        <td className="scrunity-form-td scrunity-form-particular">
                          Recovery is Regular
                        </td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                      </tr>
                      <tr>
                        <td className="scrunity-form-td">26</td>
                        <td className="scrunity-form-td scrunity-form-particular">
                          Loan Demanded
                        </td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                      </tr>
                      <tr>
                        <td className="scrunity-form-td">27</td>
                        <td className="scrunity-form-td scrunity-form-particular">
                          Loan Eligibility
                        </td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                      </tr>
                      <tr>
                        <td className="scrunity-form-td">28</td>
                        <td className="scrunity-form-td scrunity-form-particular">
                          Loan Sanctioned
                        </td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                      </tr>
                      <tr>
                        <td className="scrunity-form-td">29</td>
                        <td className="scrunity-form-td scrunity-form-particular">
                          Guaranter Liability1
                        </td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                      </tr>
                      <tr>
                        <td className="scrunity-form-td">30</td>
                        <td className="scrunity-form-td scrunity-form-particular">
                          Guaranter Liability2
                        </td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                      </tr>
                      <tr>
                        <td className="scrunity-form-td">31</td>
                        <td className="scrunity-form-td scrunity-form-particular">
                          Guaranter Liability3
                        </td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                      </tr>
                      <tr>
                        <td className="scrunity-form-td">32</td>
                        <td className="scrunity-form-td scrunity-form-particular">
                          Guaranter Liability4
                        </td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                      </tr>
                      <tr>
                        <td className="scrunity-form-td">33</td>
                        <td className="scrunity-form-td scrunity-form-particular">
                          Minimum Loan Installment
                        </td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                      </tr>
                      <tr>
                        <td className="scrunity-form-td">34</td>
                        <td className="scrunity-form-td scrunity-form-particular">
                          Member of Other Society
                        </td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                      </tr>
                      <tr>
                        <td className="scrunity-form-td">35</td>
                        <td className="scrunity-form-td scrunity-form-particular">
                          Franking Validity
                        </td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                      </tr>
                      <tr>
                        <td className="scrunity-form-td">36</td>
                        <td className="scrunity-form-td scrunity-form-particular">
                          Remark
                        </td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                      </tr>
                      <tr>
                        <td className="scrunity-form-td">37</td>
                        <td className="scrunity-form-td scrunity-form-particular">
                          &nbsp;
                        </td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                      </tr>
                      <tr>
                        <td className="scrunity-form-td">38</td>
                        <td className="scrunity-form-td scrunity-form-particular">
                          Loan Sanctioned
                        </td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                      </tr>
                      <tr>
                        <td className="scrunity-form-td">39</td>
                        <td className="scrunity-form-td scrunity-form-particular">
                          Less: MTL/LTL Loan Balance
                        </td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                      </tr>
                      <tr>
                        <td className="scrunity-form-td">40</td>
                        <td className="scrunity-form-td scrunity-form-particular">
                          Less: Interest
                        </td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                      </tr>
                      <tr>
                        <td className="scrunity-form-td">41</td>
                        <td className="scrunity-form-td scrunity-form-particular">
                          Less: EMG Loan Balance
                        </td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                      </tr>
                      <tr>
                        <td className="scrunity-form-td">42</td>
                        <td className="scrunity-form-td scrunity-form-particular">
                          Less: Interest
                        </td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                      </tr>
                      <tr>
                        <td className="scrunity-form-td">43</td>
                        <td className="scrunity-form-td scrunity-form-particular">
                          Less: Cumulative Deposit
                        </td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                      </tr>
                      <tr>
                        <td className="scrunity-form-td">44</td>
                        <td className="scrunity-form-td scrunity-form-particular">
                          Less: Share Deposit
                        </td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                      </tr>
                      <tr>
                        <td className="scrunity-form-td">45</td>
                        <td className="scrunity-form-td scrunity-form-particular">
                          Less: Group Insurance
                        </td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                      </tr>
                      <tr>
                        <td className="scrunity-form-td">46</td>
                        <td className="scrunity-form-td scrunity-form-particular">
                          Less: Individual Insurance
                        </td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                      </tr>
                      <tr>
                        <td className="scrunity-form-td">47</td>
                        <td className="scrunity-form-td scrunity-form-particular">
                          Net Amount Payable
                        </td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                      </tr>
                      <tr>
                        <td className="scrunity-form-td">48</td>
                        <td className="scrunity-form-td scrunity-form-particular">
                          Loan Instalment
                        </td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                      </tr>
                      <tr>
                        <td className="scrunity-form-td">49</td>
                        <td className="scrunity-form-td scrunity-form-particular">
                          Bank Account htmlFor ECS
                        </td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                        <td className="scrunity-form-td">&nbsp;</td>
                      </tr>
                    </tbody>
                  </table>
                ) : null}
              </div>
              <div className="d-flex align-items-center justify-content-between w-100 pt-5">
                <div>Prepared by</div>
                <div>Checked by</div>
                <div>Manager</div>
              </div>
            </div>
          )}
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
