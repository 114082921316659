import axios from "axios";
import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import "../Style/ForgotPassword.css";
// import { FaEye, FaEyeSlash } from "react-icons/fa";

export default function ForgotPassword2(props) {
  const [verifyOtp, setVerifyOtp] = useState("");
  const [whatsappNo, setWhatsappNo] = useState("");
  const [memberId, setMemberId] = useState("");

  // Use the useLocation hook to access location
  const location = useLocation();
  const storedOtp = location.state?.storedOtp;
  const memberIdFromPreviousPage = location.state?.memberId;

  useEffect(() => {
    setWhatsappNo(props.forgotPasswordWhatsapp);
  }, [props.forgotPasswordWhatsapp, storedOtp]);

  useEffect(() => {
    setMemberId(memberIdFromPreviousPage);
  }, [storedOtp, memberIdFromPreviousPage]);

  let navigate = useNavigate();

  const handleChangeEmailButton = () => {
    navigate("/forgot-password");
  };

  // const handleVerifyOtpSubmit = () => {
  //   if (verifyOtp === storedOtp) {
  //     toast.success("OTP verified successfully!");
  //     navigate("/set-new-password", { state: { memberId } });
  //   } else {
  //     toast.error("Incorrect OTP. Please try again.");
  //   }
  // };
  const handleVerifyOtpSubmit = async () => {
    try {
      await axios.post(
        "http://13.232.218.212:8000/forgot_password_verify_otp/",
        {
          otp: verifyOtp,
          local_otp: storedOtp,
          member_id: memberIdFromPreviousPage,
        }
      );

      toast.success("OTP verified successfully!");

      setTimeout(() => {
        navigate("/set-new-password", {
          state: { memberId: memberIdFromPreviousPage },
        });
      }, 2000);
    } catch (error) {
      console.error("Error verifying OTP:", error);
      toast.error("An unexpected error occurred. Please try again later.");
    }
  };

  return (
    <div>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div
        className="container-fluid forgot-password-parent-container"
        style={{ backgroundColor: "white", height: "100vh" }}
      >
        <div className="row">
          <div
            className="col-sm-12 d-flex justify-content-center align-items-center "
            style={{ paddingTop: "8rem" }}
          >
            <div
              className="forgot-password-inner-div"
              style={{
                backgroundColor: "#027CC6",
                padding: "30px 50px 30px 50px",
                borderRadius: "10px",
              }}
            >
              <strong
                className=""
                style={{
                  paddingTop: "20px",
                  fontSize: "1.4rem",
                  color: "white",
                }}
              >
                Enter 6 Digit Code
              </strong>

              <div className="forgot-password-msg-div mt-2">
                Check <span style={{ color: "#FFDD00" }}>{whatsappNo}</span> for
                a verification code.
              </div>
              <div className="text-start" title="Change Whatsapp No.">
                <button
                  className="Resend-Code mt-1 ps-0"
                  onClick={handleChangeEmailButton}
                >
                  <i>Change Whatsapp No.</i>
                </button>
              </div>
              <form>
                <div className="w-100 mt-3 ">
                  <div
                    className="d-flex align-items-center justify-content-center"
                    // style={{ gap: "1em" }}
                  >
                    <input
                      type="text"
                      className="form-control"
                      value={verifyOtp}
                      onChange={(e) => {
                        setVerifyOtp(e.target.value);
                      }}
                      min="0"
                      max="9"
                      maxLength={6}
                      pattern="[0-9]"
                    />
                  </div>
                  <div className="text-start">
                    <button className="Resend-Code">Resend Code</button>
                  </div>
                </div>
                <div className="my-3">
                  <button
                    type="button"
                    onClick={handleVerifyOtpSubmit}
                    className=" w-100 reset-password-button"
                  >
                    Submit
                  </button>
                </div>
                <div className="forgot-password-msg-div">
                  <div className="forgot-password-msg-div">
                    <div className="forgot-password-msg-div">
                      If you don't see a code in your WhatsApp messages, please
                      check your internet connection. If it's still not there,
                      the WhatsApp number may not be confirmed or may not match
                      an existing account.
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
