import React, { useState, useEffect } from "react";
import Sidebar from "./Sidebar";
import Header from "../components/Header";
import DataTable from "react-data-table-component";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import "../Style/Global_Classes.css";
import { format, parse } from "date-fns";

export default function ViewCashCertificateRenew() {
  const [accountNo, setAccountNo] = useState("");
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);

  const [formData, setFormData] = useState({
    d_class: "",
    //member_id: "",
    emp_no: "",
    certificate_no: "",
    accountNumber: "",
    initial: "",
    full_name: "",
    join_date: "",
    retire_on: "",
    age: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (accountNo) {
          const response = await axios.post(
            `http://13.232.218.212:8000/view_renew_details/`,
            { ACNO: accountNo }
          );
          const data = response.data.data;

          if (data.length > 0) {
            const fetchedData = data[0]; // Access the first item in the array
            setFormData({
              d_class: fetchedData.d_class || "",
              emp_no: fetchedData.emp_no || "",
              certificate_no: fetchedData.fdr_no || "",
              accountNumber: fetchedData.r_RDID || "",
              initial: "",
              full_name: fetchedData.name || "",
              join_date: fetchedData.startDateDemo || "",
              retire_on: fetchedData.enddateDemo || "",
              birth_date: "",
              age: "",
            });
          } else {
            console.warn("No data found or data array is empty.");
            setFormData({
              d_class: "",
              emp_no: "",
              certificate_no: "",
              accountNumber: "",
              initial: "",
              full_name: "",
              join_date: "",
              retire_on: "",
              birth_date: "",
              age: "",
            });
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [accountNo]);

  // const Bdate = formData.birth_date;
  // let formattedBirthDate = "";

  // if (Bdate) {
  //   const [year, month, day] = Bdate.split("-");
  //   formattedBirthDate = `${day}-${month}-${year}`;
  // }
  const RDate = formData.retire_on;
  let formattedRDate = "";

  if (RDate) {
    const [year, month, day] = RDate.split("-");
    formattedRDate = `${day}-${month}-${year}`;
  }
  const Jdate = formData.join_date;
  let formattedJoinDate = "";
  if (Jdate) {
    const [year, month, day] = Jdate.split("-");
    formattedJoinDate = `${day}-${month}-${year}`;
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (accountNo) {
          const response = await axios.post(
            `http://13.232.218.212:8000/fetch_cfixed2/`,
            { ACNO: accountNo }
          );

          // Check if response.data.result exists and is an array
          const rawData = response.data.result;
          if (Array.isArray(rawData)) {
            setData1(rawData);
          } else {
            console.error("Expected an array, but received:", rawData);
            setData1([]);
          }

          console.log("Data:", rawData);
        } else {
          setData1([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [accountNo]);

  const columns = [
    {
      name: "Date",
      selector: (row) => row.DATE,
      sortable: true,
      center: true,
      cell: (row) => {
        const date = new Date(row.DATE);
        if (isNaN(date.getTime())) {
          return "Invalid Date";
        }
        if (isNaN(date.getTime())) {
          return "";
        }
        return format(date, "dd-MM-yyyy");
      },
    },
    {
      name: "R V No",
      selector: (row) => row.RVNO,
      sortable: true,
      center: true,
    },
    {
      name: "Detail",
      selector: (row) => row.DETAIL,
      sortable: true,
      center: true,
    },
    {
      name: "Debit",
      selector: (row) => row.DEBIT,
      sortable: true,
      center: true,
    },
    {
      name: "Credit",
      selector: (row) => row.CREDIT,
      sortable: true,
      center: true,
    },

    {
      name: "Balance",
      selector: (row) => row.BALANCE,
      sortable: true,
      center: true,
    },
    {
      name: "Operator",
      selector: (row) => row.OPERATOR,
      sortable: true,
      center: true,
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (accountNo) {
          const response = await axios.post(
            `http://13.232.218.212:8000/fetch_cfixed1/`,
            { ACNO: accountNo }
          );
          const rawData1 = response.data.result;
          if (Array.isArray(rawData1)) {
            setData2(rawData1);
          } else {
            console.error("Expected an array, but received:", rawData1);
            setData2([]);
          }

          console.log("Data:", rawData1);
        } else {
          setData2([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [accountNo]);

  const column_one = [
    {
      name: "Date",
      selector: (row) => row.DATE,
      sortable: true,
      center: true,
      cell: (row) => {
        let date;
        try {
          date = new Date(row.DATE);
          if (isNaN(date.getTime())) {
            date = parse(row.DATE, "dd/MM/yyyy", new Date());
          }
        } catch (error) {
          return "Invalid Date";
        }
        return format(date, "dd-MM-yyyy");
      },
    },
    {
      name: "R V No",
      selector: (row) => row.RVNO,
      sortable: true,
      center: true,
    },
    {
      name: "Detail",
      selector: (row) => row.DETAIL,
      sortable: true,
      center: true,
    },
    {
      name: "Debit",
      selector: (row) => row.DEBIT,
      sortable: true,
      center: true,
    },
    {
      name: "Credit",
      selector: (row) => row.CREDIT,
      sortable: true,
      center: true,
    },

    {
      name: "Balance",
      selector: (row) => row.BALANCE,
      sortable: true,
      center: true,
    },
    {
      name: "Operator",
      selector: (row) => row.OPERATOR,
      sortable: true,
      center: true,
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (accountNo) {
          const response = await axios.post(
            `http://13.232.218.212:8000/fetch_cfixed3/`,
            { ACNO: accountNo }
          );

          // Check if response.data.result exists and is an array
          const rawData2 = response.data.result;
          if (Array.isArray(rawData2)) {
            setData3(rawData2);
          } else {
            console.error("Expected an array, but received:", rawData2);
            setData3([]);
          }

          console.log("Data:", rawData2);
        } else {
          setData3([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [accountNo]);

  const column_two = [
    {
      name: "Date",
      selector: (row) => row.DATE,
      sortable: true,
      center: true,
      cell: (row) => {
        let date;
        try {
          date = new Date(row.DATE);
          if (isNaN(date.getTime())) {
            date = parse(row.DATE, "dd/MM/yyyy", new Date());
          }
        } catch (error) {
          return "Invalid Date";
        }
        return format(date, "dd-MM-yyyy");
      },
    },
    {
      name: "Amount",
      selector: (row) => row.AMOUNT,
      sortable: true,
      center: true,
    },
    {
      name: "Renewal period",
      selector: (row) => row.RPERIOD,
      sortable: true,
      center: true,
    },
    {
      name: "Renewal Day Month",
      selector: (row) => row.RDAYMONTH,
      sortable: true,
      center: true,
    },
    {
      name: "Renewal Interest Rate",
      selector: (row) => row.RINT_RATE,
      sortable: true,
      center: true,
    },
    {
      name: "M_date",
      selector: (row) => row.M_DATE,
      sortable: true,
      center: true,
      cell: (row) => {
        let date;
        try {
          date = new Date(row.M_DATE);
          if (isNaN(date.getTime())) {
            date = parse(row.M_DATE, "dd/MM/yyyy", new Date());
          }
        } catch (error) {
          return "Invalid Date";
        }
        return format(date, "dd-MM-yyyy");
      },
    },
    {
      name: "M_amount",
      selector: (row) => row.M_AMOUNT,
      sortable: true,
      center: true,
    },
    {
      name: "Operator",
      selector: (row) => row.OPERATOR,
      sortable: true,
      center: true,
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "48px",
        textAlign: "center",
      },
    },
    headCells: {
      style: {
        minHeight: "40px",
        backgroundColor: "#4db3c8",
        fontSize: "14px",
        fontWeight: "400",
        color: "white",
        textAlign: "center",
      },
    },
  };

  return (
    <>
      <Sidebar />
      <div className="container-fluid dashboard-area d-flex">
        <div className="main-content p-4">
          {/* Navbar */}
          <Header />
          {/* Heading1 Main */}
          <div className="container d-flex text-start w-100 pb-1">
            <div className="H1-Heading-Main">
              View Renew Cash Certificate Deposit
            </div>
          </div>

          <div className="container">
            {/* Your first form code */}
            <div className="row First-Main-Row  pt-3 mb-3">
              {/* Basic Information  */}
              <form>
                <div className="H2-Sub-Heading ">Basic Information</div>
                <div className="row">
                  <div className="col-xl-1 col-lg-3 col-md-6 col-sm-6 text-start">
                    <div className="">
                      <label htmlFor="memberId" className="small-label">
                        Class
                      </label>
                      <input
                        type="text"
                        id="d_class"
                        name="d_class"
                        className="form-control small-label bg-white"
                        value={formData && formData.d_class}
                        readOnly
                        min={0}
                      />
                    </div>
                  </div>
                  {/*} <div className="col-xl col-lg-3 col-md-6 col-sm-6 text-start">
                    <label for="memberId" className="small-label">
                      Member No*
                    </label>
                    <div class="">
                      <input
                        type="text"
                        id="memberId"
                        name="member_id"
                        class="form-control small-label"
                        value={formData.member_id}
                        min={0}
                        required
                      />
                    </div>
                  </div>*/}
                  <div className="col-xl col-lg-3 col-md-6 col-sm-6 text-start">
                    <div className=" ">
                      <label htmlFor="emp_no" className="small-label">
                        Employee No
                      </label>
                      <input
                        type="text"
                        id="rdId"
                        name="emp_no"
                        className="form-control small-label bg-white"
                        value={formData && formData.emp_no}
                        readOnly
                        min={0}
                      />
                    </div>
                  </div>
                  <div className="col-xl col-lg-3 col-md-6 col-sm-6 text-start">
                    <div className=" ">
                      <label htmlFor="certificate_no" className="small-label">
                        Certificate Number
                      </label>
                      <input
                        type="text"
                        id="certificate_no"
                        name="certificate_no"
                        className="form-control small-label"
                        value={formData && formData.certificate_no}
                        readOnly
                        min={0}
                      />
                    </div>
                  </div>
                  <div className="col-xl col-lg-3 col-md-6 col-sm-6 text-start">
                    <div className="">
                      <label htmlFor="accountNumber" className="small-label">
                        Account No*
                      </label>
                      <input
                        type="number"
                        className="form-control small-label"
                        id="accountNumber"
                        name="accountNumber"
                        value={formData && formData.accountNo} // Binding to formData
                        onChange={(e) => setAccountNo(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-1 col-lg-2 col-md-6 col-sm-6 text-start">
                    <label htmlFor="initial" className="small-label">
                      Initial
                    </label>
                    <input
                      name="initial"
                      className="form-control small-label bg-white"
                      value={formData && formData.initial}
                      readOnly
                      id="floatingInput"
                    ></input>
                  </div>
                  <div className="col-xl col-lg-2 col-md-6 col-sm-6 text-start">
                    <div className="no-outline-login">
                      <label htmlFor="full_name" className="small-label">
                        First Name
                      </label>
                      <input
                        type="text"
                        name="full_name"
                        className="form-control small-label bg-white"
                        value={formData && formData.full_name}
                        id="firstName"
                        readOnly
                      />
                    </div>
                  </div>
                  {/*<div className="col-xl col-lg-2 col-md-6 col-sm-6 text-start">
                    <div className="row">
                      <div className="col-sm-8">
                        <label htmlFor="birth_date" className="small-label">
                          Birth Date
                        </label>
                        <input
                          type="text"
                          className="form-control small-label bg-white"
                          id="birth_date"
                          value={formData && formattedBirthDate}
                          name="birthDate"
                          readOnly
                        />
                      </div>
                      <div className="col-sm-4">
                        <label htmlFor="" className="small-label">
                          Age
                        </label>
                        <input
                          type="text"
                          className="form-control small-label bg-white"
                          id="age"
                          minLength={2}
                          maxLength={2}
                          value={formData && formData.age}
                          name="age"
                          readOnly
                        />
                      </div>
                    </div>
                  </div>*/}
                  <div className="col-xl col-lg-2 col-md-6 col-sm-6 text-start ">
                    <label htmlFor="middleName" className="small-label">
                      Join Date
                    </label>
                    <div>
                      <input
                        type="text"
                        name="middleName"
                        className="form-control small-label bg-white"
                        value={formData && formattedJoinDate}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-xl col-lg-2 col-md-6 col-sm-6 text-start">
                    <div className="">
                      <label htmlFor="retire_on" className="small-label">
                        Retire On
                      </label>
                      <input
                        type="text"
                        name="retire_on"
                        value={formData && formattedRDate}
                        className="form-control small-label bg-white"
                        id="last_name"
                        readOnly
                      />
                    </div>
                  </div>
                </div>

                {/* Renew Section */}
                <div className="container-fluid d-flex">
                  <div className="row w-100 py-2">
                    <div className="col-12 ">
                      <DataTable
                        title="Deposit Entries Details"
                        columns={columns}
                        data={data1}
                        customStyles={customStyles}
                        pagination
                        paginationPerPage={5}
                        striped
                        dense
                      />
                    </div>
                  </div>
                </div>
                <div className="container-fluid d-flex">
                  <div className="row w-100 py-2">
                    <div className="col-12 ">
                      <DataTable
                        title="Interest Entries Details"
                        columns={column_one}
                        data={data2}
                        customStyles={customStyles}
                        pagination
                        paginationPerPage={5}
                        striped
                        dense
                      />
                    </div>
                  </div>
                </div>
                <div className="container-fluid d-flex">
                  <div className="row w-100 py-2">
                    <div className="col-12 ">
                      <DataTable
                        title="Renewal Entries Details"
                        columns={column_two}
                        data={data3}
                        customStyles={customStyles}
                        pagination
                        paginationPerPage={5}
                        striped
                        dense
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
