import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import axios from "axios";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import Header from "../components/Header";
import * as XLSX from "xlsx";
import { SiMicrosoftexcel } from "react-icons/si";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";

export default function RegisterMembers() {
  const [data, setData] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [selectedMemberData, setSelectedMemberData] = useState({});
  const [updatedMemberData, setUpdatedMemberData] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [myFilteredData, setMyFilteredData] = useState([]);

  const handleUpdate = async (member_id) => {
    console.log(data);
    try {
      const response = await axios.get(
        ` http://13.232.218.212:8000/all_memberdata/${member_id}/`
      );
      const jsonData = response.data;
      const resultArray = jsonData.members[0];
      setSelectedMemberData(resultArray);
      setUpdatedMemberData({ ...resultArray });
      console.log(selectedMemberData);
    } catch (error) {
      console.error("Error fetching member data:", error);
    }
    // setSelectedMemberData(member);
    setPopupOpen(true);
  };

  const handleInputChange = (e) => {
    // console.log("Input changed:", e.target.name, e.target.value);
    setUpdatedMemberData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSaveChanges = async () => {
    try {
      await axios.post(
        ` http://13.232.218.212:8000/update_member/`,
        updatedMemberData
      );
      setPopupOpen(false);
      await fetchData();
      Swal.fire({
        title: "Success",
        text: "Changes saved successfully.",
        icon: "success",
        didOpen: () => {
          Swal.getPopup().style.borderRadius = "25px";
          const confirmButton = Swal.getConfirmButton();
          confirmButton.classList.add("custom-swal-button");
        },
      });
    } catch (error) {
      console.error("Error updating member:", error);
      Swal.fire({
        title: "Error",
        text: "An error occurred while saving changes.",
        icon: "error",
        didOpen: () => {
          Swal.getPopup().style.borderRadius = "25px";
          const confirmButton = Swal.getConfirmButton();
          confirmButton.classList.add("custom-swal-button");
        },
      });
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.post(
        "http://13.232.218.212:8000/member_data/"
      );
      const jsonData = await response.data;

      if (Array.isArray(jsonData.members)) {
        const flattenedArray = jsonData.members.flat();
        setData(flattenedArray);
        setMyFilteredData(flattenedArray);
        console.log(flattenedArray);
      } else {
        console.error("Invalid data format: ", jsonData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData()
      .then(() => setDataLoaded(true))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const handleSearch = (searchTerm) => {
    setSearchTerm(searchTerm);
    if (searchTerm && data) {
      const filtered = data.filter((item) => {
        const empNo = String(item.emp_no).toLowerCase(); // Convert emp_no to string
        const fullName = item.full_name ? item.full_name.toLowerCase() : ""; // Check if full_name is not null
        return (
          empNo.includes(searchTerm.toLowerCase()) ||
          fullName.includes(searchTerm.toLowerCase())
        );
      });
      setMyFilteredData(filtered);
    } else {
      setMyFilteredData(data);
    }
  };

  const columns = [
    {
      name: "Sr. No.",
      selector: (row, index) => index + 1,
      sortable: false,
      width: "100px",
    },
    {
      name: "Member ID.",
      selector: (row) => row.member_id,
      sortable: true,
      width: "100px",
    },
    {
      name: "Emp No.",
      selector: (row) => row.emp_no,
      sortable: true,
      width: "100px",
    },
    {
      name: "Name",
      selector: (row) => row.full_name,
      sortable: true,
      width: "250px",
    },
    {
      name: "Email",
      selector: (row) =>
        row.email && row.email.toLowerCase() !== "nan"
          ? row.email
          : "Not Provided",
      sortable: true,
      width: "200px",
    },
    {
      name: "Mobile No",
      selector: (row) => row.mobile_no,
      sortable: true,
      // center: true,
    },
    {
      name: "Actions",
      cell: (row, rowIndex) => (
        <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
          <span
            type="button"
            className="btn"
            style={{ color: "dodgerblue" }}
            data-toggle="modal"
            data-target="#exampleModalCenter"
            onClick={() => handleUpdate(row.member_id)}
          >
            <FiEdit />
          </span>
          {/* <span
            className="btn"
            style={{ color: "red" }}
            onClick={() => handleDelete(row.member_id)}
          >
            <RiDeleteBin6Line />
          </span> */}
        </div>
      ),
      center: true,
    },
  ];

  const exportToExcel = () => {
    if (!Array.isArray(data)) {
      console.error("Invalid data format. Please provide an array.");
      return;
    }

    const columnsForExport = columns.filter((column) => column.selector); // Exclude columns without a selector
    const fileName = "membersheet";

    const exportData = data.map((row, index) => {
      const rowData = {
        "Sr. No.": index + 1,
      };

      columnsForExport.forEach((column) => {
        if (column.name !== "Sr. No.") {
          rowData[column.name] = column.selector(row, index);
        }
      });

      return rowData;
    });

    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "sheet");
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  };

  const customStyles = {
    rows: {
      style: {
        minHeight: "48px",
        textAlign: "center",
      },
    },
    headCells: {
      style: {
        minHeight: "40px",
        backgroundColor: "#4db3c8",
        fontSize: "14px",
        fontWeight: "400",
        color: "white",
        textAlign: "center",
      },
    },
  };

  return (
    <>
      <Sidebar />
      <div className="container-fluid dashboard-area d-flex">
        <div className="main-content p-4">
          {/* Navbar */}
          <Header />
          {/* Heading */}
          <div className="container d-flex text-start w-100 pb-1">
            <div className="row w-100 align-items-center">
              <div className="col-5 text-start">
                <h2 style={{ fontWeight: "bold", color: "dodgerblue" }}>
                  Registered Members List
                </h2>
              </div>
              <div className="col-4">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search Employee No or Name"
                  value={searchTerm}
                  onChange={(e) => handleSearch(e.target.value)}
                  style={{ border: "1px solid grey" }}
                />
              </div>
              <div className="col-3 text-end">
                <SiMicrosoftexcel
                  onClick={exportToExcel}
                  style={{
                    cursor: "pointer",
                    color: "green",
                    fontSize: "1.7em",
                  }}
                />
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12 py-2">
                {dataLoaded ? (
                  <DataTable
                    data={myFilteredData}
                    columns={columns}
                    style={{ textAlign: "center", width: "100%" }}
                    customStyles={customStyles}
                    striped
                    highlightOnHover
                    dense
                    pagination
                  />
                ) : (
                  <>
                    <span className="data-table-loader"></span>
                    <p>Please wait...</p>
                  </>
                )}
              </div>
            </div>
          </div>

          <div
            class="modal fade"
            id="exampleModalCenter"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered"
              role="document"
              style={{ maxWidth: "800px" }}
            >
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLongTitle">
                    <strong>Update Details</strong>
                  </h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div
                    data-spy="scroll"
                    data-target="#list-example"
                    data-offset="0"
                    class="scrollspy-example overflow-auto"
                    style={{ maxHeight: "250px", padding: "13px" }}
                  >
                    <form>
                      {/* 1st Row */}
                      <div className="row pb-2">
                        <div class="col-lg-4 col-md-12 col-sm-12 form-fields">
                          <label for="text">First Name</label>
                          <input
                            type="text"
                            name="first_name"
                            className="form-control no-outline"
                            value={updatedMemberData.first_name}
                            id="first_name"
                            onChange={(e) => {
                              const inputValue = e.target.value;

                              const filteredValue = inputValue
                                .replace(/[^A-Za-z ]/g, "")
                                .slice(0, 20);

                              const capitalizedValue =
                                filteredValue.charAt(0).toUpperCase() +
                                filteredValue.slice(1);

                              handleInputChange({
                                target: {
                                  name: "first_name",
                                  value: capitalizedValue,
                                },
                              });
                            }}
                            style={{
                              backgroundColor: "whitesmoke",
                              borderColor: "none",
                            }}
                          />
                        </div>
                        <div class="col-lg-4 col-md-12 col-sm-12 form-fields">
                          <label for="text">Middle Name</label>
                          <input
                            type="text"
                            name="middle_name"
                            className="form-control no-outline"
                            value={updatedMemberData.middle_name}
                            id="middle_name"
                            onChange={(e) => {
                              const inputValue = e.target.value;

                              const filteredValue = inputValue
                                .replace(/[^A-Za-z ]/g, "")
                                .slice(0, 20);

                              const capitalizedValue =
                                filteredValue.charAt(0).toUpperCase() +
                                filteredValue.slice(1);

                              handleInputChange({
                                target: {
                                  name: "middle_name",
                                  value: capitalizedValue,
                                },
                              });
                            }}
                            style={{
                              backgroundColor: "whitesmoke",
                              borderColor: "none",
                            }}
                          />
                        </div>
                        <div class="col-lg-4 col-md-12 col-sm-12 form-fields">
                          <label for="text">Last Name</label>
                          <input
                            type="text"
                            name="last_name"
                            className="form-control no-outline"
                            value={updatedMemberData.last_name}
                            id="last_name"
                            onChange={(e) => {
                              const inputValue = e.target.value;

                              const filteredValue = inputValue
                                .replace(/[^A-Za-z ]/g, "")
                                .slice(0, 20);

                              const capitalizedValue =
                                filteredValue.charAt(0).toUpperCase() +
                                filteredValue.slice(1);

                              handleInputChange({
                                target: {
                                  name: "last_name",
                                  value: capitalizedValue,
                                },
                              });
                            }}
                            style={{
                              backgroundColor: "whitesmoke",
                              borderColor: "none",
                            }}
                          />
                        </div>
                      </div>

                      {/* 3rd Row */}
                      <div class="row py-3">
                        <div class="col-lg-4 col-md-12 col-sm-12 form-fields">
                          <label for="text">Birth Date</label>
                          <input
                            type="date"
                            name="birth_date"
                            className="form-control no-outline"
                            value={updatedMemberData.birth_date}
                            onChange={handleInputChange}
                            style={{
                              backgroundColor: "whitesmoke",
                              borderColor: "none",
                            }}
                          />
                        </div>
                        <div class="col-lg-4 col-md-12 col-sm-12 form-fields">
                          <label for="text">Email</label>
                          <input
                            type="email"
                            name="email"
                            className="form-control no-outline"
                            value={updatedMemberData.email}
                            onChange={handleInputChange}
                            style={{
                              backgroundColor: "whitesmoke",
                              borderColor: "none",
                            }}
                          />
                        </div>
                        <div class="col-lg-4 col-md-12 col-sm-12 form-fields">
                          <label for="text">Mobile Number</label>
                          <input
                            type="tel"
                            name="mobile_no"
                            className="form-control no-outline "
                            pattern="[0-9]*"
                            value={updatedMemberData.mobile_no}
                            onChange={(e) => {
                              const numericValue = e.target.value.replace(
                                /[^0-9]/g,
                                ""
                              );

                              const trimmedValue = numericValue.slice(0, 10);

                              handleInputChange({
                                target: {
                                  name: "mobile_no",
                                  value: trimmedValue,
                                },
                              });
                            }}
                            style={{
                              backgroundColor: "whitesmoke",
                              borderColor: "none",
                            }}
                          />
                        </div>
                      </div>
                      {/* 5th Row */}
                      <div class="row py-3">
                        <div class="col-lg-4 col-md-12 col-sm-12 form-fields">
                          <label for="text">Marital Status</label>
                          <select
                            className="form-control no-outline"
                            name="marital_status"
                            value={updatedMemberData.marital_status}
                            onChange={handleInputChange}
                            style={{
                              backgroundColor: "whitesmoke",
                              borderColor: "none",
                            }}
                          >
                            <option>Select option</option>
                            <option>Single</option>
                            <option>Married</option>
                            {/* <option>Divorce</option> */}
                          </select>
                        </div>
                        <div class="col-lg-4 col-md-12 col-sm-12 form-fields">
                          <label for="text">Resident Address</label>
                          <input
                            type="text-area"
                            name="resident_address"
                            className="form-control no-outline"
                            value={updatedMemberData.resident_address}
                            onChange={(e) => {
                              const inputValue = e.target.value;

                              const filteredValue = inputValue
                                .replace(/[^A-Za-z ]/g, "")
                                .slice(0, 20);

                              const capitalizedValue =
                                filteredValue.charAt(0).toUpperCase() +
                                filteredValue.slice(1);

                              handleInputChange({
                                target: {
                                  name: "resident_address",
                                  value: capitalizedValue,
                                },
                              });
                            }}
                            style={{
                              backgroundColor: "whitesmoke",
                              borderColor: "none",
                            }}
                          />
                        </div>
                        <div class="col-lg-4 col-md-12 col-sm-12 form-fields">
                          <label for="text">Resident City</label>
                          <input
                            type="text"
                            name="resident_city"
                            className="form-control no-outline"
                            value={updatedMemberData.resident_city}
                            onChange={(e) => {
                              const inputValue = e.target.value;

                              const filteredValue = inputValue
                                .replace(/[^A-Za-z ]/g, "")
                                .slice(0, 20);

                              const capitalizedValue =
                                filteredValue.charAt(0).toUpperCase() +
                                filteredValue.slice(1);

                              handleInputChange({
                                target: {
                                  name: "resident_city",
                                  value: capitalizedValue,
                                },
                              });
                            }}
                            style={{
                              backgroundColor: "whitesmoke",
                              borderColor: "none",
                            }}
                          />
                        </div>
                      </div>

                      {/* 4th Row */}
                      <div class="row py-3">
                        <div class="col-lg-4 col-md-12 col-sm-12 form-fields">
                          <label for="text">Resident Pin Code</label>
                          <input
                            type="text"
                            name="resident_pincode"
                            className="form-control no-outline"
                            value={updatedMemberData.resident_pincode}
                            onChange={(e) => {
                              const numericValue = e.target.value.replace(
                                /\D/g,
                                ""
                              ); // Remove non-numeric characters
                              const truncatedValue = numericValue.substring(
                                0,
                                6
                              ); // Limit to 6 digits

                              handleInputChange({
                                target: {
                                  name: "resident_pincode",
                                  value: truncatedValue,
                                },
                              });
                            }}
                            maxLength={6}
                            style={{
                              backgroundColor: "whitesmoke",
                              borderColor: "none",
                            }}
                          />
                        </div>
                        <div class="col-lg-4 col-md-12 col-sm-12 form-fields">
                          <label for="text">Nominee Name</label>
                          <input
                            type="text"
                            name="nominee_name"
                            className="form-control no-outline"
                            value={updatedMemberData.nominee_name}
                            onChange={handleInputChange}
                            style={{
                              backgroundColor: "whitesmoke",
                              borderColor: "none",
                            }}
                          />
                        </div>
                        <div class="col-lg-4 col-md-12 col-sm-12 form-fields">
                          <label for="text">Phone No.</label>
                          <input
                            type="text"
                            name="phoneno"
                            className="form-control no-outline"
                            value={updatedMemberData.phoneno}
                            onChange={(e) => {
                              const numericValue = e.target.value.replace(
                                /[^0-9]/g,
                                ""
                              );

                              const trimmedValue = numericValue.slice(0, 10);

                              handleInputChange({
                                target: {
                                  name: "phoneno",
                                  value: trimmedValue,
                                },
                              });
                            }}
                            style={{
                              backgroundColor: "whitesmoke",
                              borderColor: "none",
                            }}
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="modal-footer">
                  <span
                    type="button"
                    class="btn btn-primary"
                    data-dismiss="modal"
                    style={{ width: "140px" }}
                  >
                    Close
                  </span>
                  <button
                    type="button"
                    class="btn btn-success"
                    onClick={handleSaveChanges}
                  >
                    Save changes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
