import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import Header from "../components/Header";
import axios from "axios";
import Swal from "sweetalert2";

const RecorveryForm = () => {
  const [empNo, setEmpNo] = useState("");
  const [name, setName] = useState("");
  const [monthlyContribution, setMonthlyContribution] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [saving, setSaving] = useState("");
  const [newContribution, setNewContribution] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (empNo.trim() === "") {
          setName("");
          setSelectedValue("");
          setMonthlyContribution("");
        } else {
          const response = await axios.get(
            `http://13.232.218.212:8000/all_memberdata/${empNo}/`
          );
          const responseData = response.data.members[0];
          const monthlyContribution = responseData.monthly_contribution;
          const FullName = responseData.full_name;

          setName(FullName);
          setMonthlyContribution(monthlyContribution);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [empNo]);

  useEffect(() => {
    if (monthlyContribution) {
      setSelectedValue("Member Contribution");
    }
  }, [monthlyContribution]);

  console.log(
    selectedValue === "Member Contribution"
      ? monthlyContribution
      : selectedValue === "Member Saving Deposit"
      ? saving
      : ""
  );

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(
        "http://13.232.218.212:8000/recovery_form_update/",
        {
          emp_no: empNo,
          value: newContribution,
        }
      );
      if (res.data.success === "monthly_contribution updated successfully") {
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Monthly contribution updated successfully",
          didOpen: () => {
            Swal.getPopup().style.borderRadius = "25px";
            const confirmButton = Swal.getConfirmButton();
            confirmButton.classList.add("custom-swal-button");
          },
        });
        setEmpNo("");
        setNewContribution("");
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: res.data.error_message || "Something went wrong",
          didOpen: () => {
            Swal.getPopup().style.borderRadius = "25px";
            const confirmButton = Swal.getConfirmButton();
            confirmButton.classList.add("custom-swal-button");
          },
        });
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong",
        didOpen: () => {
          Swal.getPopup().style.borderRadius = "25px";
          const confirmButton = Swal.getConfirmButton();
          confirmButton.classList.add("custom-swal-button");
        },
      });
    }
  };

  return (
    <>
      <Sidebar />
      <div className="container-fluid dashboard-area d-flex">
        <div className="main-content p-4">
          <Header />
          <div
            className="container d-flex text-start w-100 pb-1"
            style={{ flexDirection: "column" }}
          >
            <div className="row w-100 align-items-center">
              <div className="col-6 text-start">
                <h2 style={{ fontWeight: "bold", color: "dodgerblue" }}>
                  Recovery Form
                </h2>
              </div>
            </div>

            <div className="row w-100">
              <div className="col-6">
                <label style={{ color: "#004F83", fontWeight: "bold" }}>
                  Enter Member Id{" "}
                </label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setEmpNo(e.target.value)}
                />
              </div>
            </div>
            <div className="row w-100">
              <div className="col-6">
                <label style={{ color: "#004F83", fontWeight: "bold" }}>
                  Name
                </label>
                <input type="text" className="form-control" value={name} />
              </div>
            </div>
            <div className="row w-100">
              <div className="col-6">
                <label style={{ color: "#004F83", fontWeight: "bold" }}>
                  Select Account Head
                </label>
                <select
                  type="text"
                  className="form-select"
                  value={selectedValue}
                  onChange={(e) => setSelectedValue(e.target.value)}
                >
                  <option value="Member Contribution">
                    Member Contribution
                  </option>
                  <option value="Member Saving Deposit">
                    Member Saving Deposit
                  </option>
                </select>
              </div>
            </div>
            <div className="row w-100">
              <div className="col-6">
                <label style={{ color: "#004F83", fontWeight: "bold" }}>
                  Current Contribution
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={
                    selectedValue === "Member Contribution"
                      ? monthlyContribution
                      : selectedValue === "Member Saving Deposit"
                      ? saving
                      : ""
                  }
                  readOnly
                  style={{ backgroundColor: "white" }}
                />
              </div>
            </div>
            <div className="row w-100">
              <div className="col-6">
                <label style={{ color: "#004F83", fontWeight: "bold" }}>
                  Update Contribution
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={newContribution}
                  onChange={(e) => setNewContribution(e.target.value)}
                />
              </div>
            </div>
            <div className="row w-100">
              <div className="col-6 pt-2">
                <button
                  type="text"
                  className="btn btn-success"
                  onClick={handleUpdate}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RecorveryForm;
