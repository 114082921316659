import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

// Function to map numeric months to month names
const getMonthName = (month) => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return monthNames[month - 1];
};

export default function App() {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    axios
      .post("http://13.232.218.212:8000/chart/")
      .then((response) => {
        const depositCounts = response.data.deposit_counts || [];
        const withdrawalCounts = response.data.withdrawal_counts || [];

        // Map deposit counts
        const depositData = depositCounts.map((depositCount) => ({
          month: getMonthName(depositCount.month),
          deposit_amount: depositCount.total_deposit_amount || 0,
        }));

        // Map withdrawal counts
        const withdrawalData = withdrawalCounts.map((withdrawalCount) => ({
          month: getMonthName(withdrawalCount.month),
          withdrawal_amount: withdrawalCount.total_withdrawal_amount || 0,
        }));

        // Generate an array of all months
        const allMonths = Array.from({ length: 12 }, (_, i) =>
          getMonthName(i + 1)
        );

        // Fill in data for all months, even if there are no transactions
        const combinedData = allMonths.map((month) => {
          const correspondingDeposit = depositData.find(
            (item) => item.month === month
          ) || { deposit_amount: 0 };
          const correspondingWithdrawal = withdrawalData.find(
            (item) => item.month === month
          ) || { withdrawal_amount: 0 };

          return {
            month,
            deposit_amount: correspondingDeposit.deposit_amount,
            withdrawal_amount: correspondingWithdrawal.withdrawal_amount,
          };
        });

        console.log("Fetched Data:", combinedData);
        setChartData(combinedData);
      })
      .catch((error) => {
        console.error("Error fetching data from API:", error);
      });
  }, []);

  return (
    <>
      <div className="container d-flex justify-content-center align-items-center w-100 pb-1">
        <div className="row d-flex justify-content-center align-items-center w-100">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center align-items-center">
            <div style={{ width: "100%", height: 300 }}>
              <ResponsiveContainer>
                <LineChart
                  width={500}
                  height={300}
                  data={chartData}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="month"
                    tick={{ fontSize: 12 }}
                    type="category"
                    padding={{ left: 70, right: 70 }}
                  />

                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line
                    type="monotone"
                    dataKey="deposit_amount"
                    stroke="#8884d8"
                    activeDot={{ r: 8 }}
                    name="Deposit Amount"
                  />
                  <Line
                    type="monotone"
                    dataKey="withdrawal_amount"
                    stroke="#82ca9d"
                    name="Withdrawal Amount"
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
