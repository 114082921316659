import React, { useState, useEffect } from "react";
import Sidebar from "./Sidebar";
import Header from "../components/Header";
import axios from "axios";
import Swal from "sweetalert2";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import "../Style/Global_Classes.css";
import MediumTermLoan from "./MediumTermLoan";

export default function NewStaffMediumTermLoan() {
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();

    // Pad month and day with leading zeros if needed
    month = month < 10 ? `0${month}` : month;
    day = day < 10 ? `0${day}` : day;

    return `${year}-${month}-${day}`;
  };
  useEffect(() => {
    // setApplicationDate(getCurrentDate());
    setLoanDate(getCurrentDate());
  }, []);

  const [member_id, setMember_Id] = useState();
  const [loan_date, setLoanDate] = useState();
  const [initial, setinitial] = useState("");
  const [name, setName] = useState();
  const [emp_no, setEmp_No] = useState();
  const [member_class, setMember_class] = useState("");
  const [branchName, setBranchName] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [installment_period, setinstallment_period] = useState("");
  const [loan_amount, setloan_amount] = useState("");
  const [InterestRate, setInterestRate] = useState("");
  const [due_date, setdue_date] = useState("");
  const [installmentAmount, setinstallmentAmount] = useState("");
  const [first_due_date, setfirst_due_date] = useState("");

  const [coMemberNo, setCoMemberNo] = useState("");
  const [coLoaneeTitle, setCoLoaneeTitle] = useState("");
  const [CoName, setCoName] = useState("");
  const [coEmpCode, setCoEmpCode] = useState("");
  const [comember_class, setCoMember_class] = useState("");

  const [meetingNo, setMeetingNo] = useState("");
  const [meetingDate, setMeetingDate] = useState("");
  const [transferDate, setTransferDate] = useState("");
  const [transferFrom, setTransferFrom] = useState("");
  const [oldLoanNo, setOldLoanNo] = useState("");
  const [interestDebited, setInterestDebited] = useState("");
  const [balanceLoan, setBalanceLoan] = useState("");
  const [interest, setInterest] = useState("");
  const [penalty, setPenalty] = useState("");
  const [miscAmount, setMiscAmount] = useState("");
  const [loanNo, setLoanNo] = useState("");
  const [sanctionAmount, setSanctionAmount] = useState("");
  const [balance, setBalance] = useState("");
  const [lastPaidDate, setLastPaidDate] = useState("");
  const [specialRemark, setSpecialRemark] = useState("");
  const [garontor1Id, setGarontor1Id] = useState("");
  const [garontor1EmpCode, setGarontor1EmpCode] = useState("");
  const [garontor1initial, setGarontor1Initial] = useState("");
  const [garontor1FullName, setGarontor1FullName] = useState("");
  const [garontor1Member_C, setGarontor1Member_C] = useState("");

  const [garontor2Id, setGarontor2Id] = useState("");
  const [garontor2EmpCode, setGarontor2EmpCode] = useState("");
  const [garontor2initial, setGarontor2Initial] = useState("");
  const [garontor2FullName, setGarontor2FullName] = useState("");
  const [garontor2Member_C, setGarontor2Member_C] = useState("");

  const [garontor3Id, setGarontor3Id] = useState("");
  const [garontor3EmpCode, setGarontor3EmpCode] = useState("");
  const [garontor3initial, setGarontor3Initial] = useState("");
  const [garontor3FullName, setGarontor3FullName] = useState("");
  const [garontor3Member_C, setGarontor3Member_C] = useState("");

  const [ruser, setRuser] = useState("");
  const [loan_value, setLoan_value] = useState("");
  const [recovery, setRecovery] = useState("");
  const [loan_purpose, setLoan_purpose] = useState("");
  const [sanctionCommittee, setSanctionCommittee] = useState("");
  const [garontor4Id, setGarontor4Id] = useState("");

  const [garontor4EmpCode, setGarontor4EmpCode] = useState("");
  const [garontor4initial, setGarontor4Initial] = useState("");
  const [garontor4FullName, setGarontor4FullName] = useState("");
  const [garontor4Member_C, setGarontor4Member_C] = useState("");

  const [officeaddress, setOfficeaddress] = useState("");
  const [officecity, setOfficecity] = useState("");
  const [officepin, setOfficepin] = useState("");

  const handleGuarantor4IdChange = (garontor4Id) => {
    if (garontor4Id === undefined || garontor4Id === "") {
      console.error("Guarantor 4 ID is undefined or empty");
      setGarontor4EmpCode("");
      setGarontor4FullName("");
      setGarontor4Member_C("");
      setGarontor4Initial("");
      return;
    }
    axios
      .get(`http://13.232.218.212:8000/all_memberdata/${garontor4Id}/`)
      .then((ress) => {
        if (ress.data.members && ress.data.members.length > 0) {
          const guarantorData = ress.data.members[0];

          setGarontor4EmpCode(guarantorData["emp_no"] || "");
          setGarontor4FullName(
            `${guarantorData["first_name"] || ""} ${
              guarantorData["middle_name"] || ""
            } ${guarantorData["last_name"] || ""}`
          );
          setGarontor4Member_C(guarantorData["member_class"] || "");
          setGarontor4Initial(guarantorData["initial"] || "");
        } else {
          console.error("Unexpected response:", ress);
          console.error("Error: Unexpected response format or empty data");
        }
      })
      .catch((error) => {
        console.error("Error fetching guarantor 4 data:", error);
      });

    setGarontor4Id(garontor4Id);
  };

  useEffect(() => {
    if (member_id === "") {
      setGarontor1Id("");
      setGarontor2Id("");
      setGarontor3Id("");
      setGarontor4Id("");
    }
  }, [member_id]);

  const handleGuarantor1IdChange = (guarantor1Id) => {
    if (guarantor1Id === undefined || guarantor1Id === "") {
      console.error("Guarantor ID is undefined or empty");
      setGarontor1EmpCode("");
      setGarontor1FullName("");
      setGarontor1Member_C("");
      setGarontor1Initial("");
      return;
    }

    axios
      .get(`http://13.232.218.212:8000/all_memberdata/${guarantor1Id}/`)
      .then((ress) => {
        if (ress.data.members && ress.data.members.length > 0) {
          const guarantorData = ress.data.members[0];

          setGarontor1EmpCode(guarantorData["emp_no"] || "");
          setGarontor1FullName(
            `${guarantorData["first_name"] || ""} ${
              guarantorData["middle_name"] || ""
            } ${guarantorData["last_name"] || ""}`
          );
          setGarontor1Member_C(guarantorData["member_class"] || "");
          setGarontor1Initial(guarantorData["initial"] || "");

          // ... (set other guarantor fields based on API response)
        } else {
          console.error("Unexpected response:", ress);
          console.error("Error: Unexpected response format or empty data");
        }
      })
      .catch((error) => {
        console.error("Error fetching guarantor data:", error);
      });

    setGarontor1Id(guarantor1Id);
  };
  const handleGuarantor2IdChange = (guarantor2Id) => {
    if (guarantor2Id === undefined || guarantor2Id === "") {
      console.error("Guarantor 2 ID is undefined or empty");
      setGarontor2EmpCode("");
      setGarontor2FullName("");
      setGarontor2Member_C("");
      setGarontor2Initial("");

      return;
    }

    axios
      .get(`http://13.232.218.212:8000/all_memberdata/${guarantor2Id}/`)
      .then((ress) => {
        if (ress.data.members && ress.data.members.length > 0) {
          const guarantorData = ress.data.members[0];

          setGarontor2EmpCode(guarantorData["emp_no"] || "");
          setGarontor2FullName(
            `${guarantorData["first_name"] || ""} ${
              guarantorData["middle_name"] || ""
            } ${guarantorData["last_name"] || ""}`
          );
          setGarontor2Member_C(guarantorData["member_class"] || "");
          setGarontor2Initial(guarantorData["initial"] || "");
        } else {
          console.error("Unexpected response:", ress);
          console.error("Error: Unexpected response format or empty data");
        }
      })
      .catch((error) => {
        console.error("Error fetching guarantor 2 data:", error);
      });

    setGarontor2Id(guarantor2Id);
  };
  const handleGuarantor3IdChange = (garontor3Id) => {
    if (garontor3Id === undefined || garontor3Id === "") {
      console.error("Guarantor 3 ID is undefined or empty");
      setGarontor3EmpCode("");
      setGarontor3FullName("");
      setGarontor3Member_C("");
      setGarontor3Initial("");
      return;
    }
    axios
      .get(`http://13.232.218.212:8000/all_memberdata/${garontor3Id}/`)
      .then((ress) => {
        if (ress.data.members && ress.data.members.length > 0) {
          const guarantorData = ress.data.members[0];

          setGarontor3EmpCode(guarantorData["emp_no"] || "");
          setGarontor3FullName(
            `${guarantorData["first_name"] || ""} ${
              guarantorData["middle_name"] || ""
            } ${guarantorData["last_name"] || ""}`
          );
          setGarontor3Member_C(guarantorData["member_class"] || "");
          setGarontor3Initial(guarantorData["initial"] || "");
        } else {
          console.error("Unexpected response:", ress);
          console.error("Error: Unexpected response format or empty data");
        }
      })
      .catch((error) => {
        console.error("Error fetching guarantor 2 data:", error);
      });

    setGarontor3Id(garontor3Id);
  };

  const handleMemberIdChange = (member_id) => {
    if (member_id === undefined || member_id === "") {
      console.error("Value is undefined or empty");
      setEmp_No("");
      setName("");
      setinitial("");
      setMember_class("");
      setBranchName("");
      setAddress("");
      setCity("");
      setPinCode("");
      setMobileNumber("");
      setBalance("");
      return;
    }

    axios
      .get(`http://13.232.218.212:8000/all_memberdata/${member_id}/`)
      .then((ress) => {
        if (ress.data.members && ress.data.members.length > 0) {
          const memberData = ress.data.members[0];

          setEmp_No(memberData["emp_no"] || "");
          setName(
            `${memberData["first_name"] || ""} ${
              memberData["middle_name"] || ""
            } ${memberData["last_name"] || ""}`
          );
          setinitial(memberData["initial"] || "");
          setMember_class(memberData["member_class"] || "");
          setBranchName(memberData["branch_name"] || "");
          setBranchName(memberData["branch_name"] || "");
          setAddress(memberData["resident_address"] || "");
          setCity(memberData["resident_city"] || "");
          setPinCode(memberData["resident_pincode"] || "");
          setMobileNumber(memberData["mobile_no"] || "");
          setBalance(memberData["salary"] || "");
        } else {
          console.error("Unexpected response:", ress);
          console.error("Error: Unexpected response format or empty data");
        }
      })
      .catch((error) => {
        console.error("Error fetching member data:", error);
      });

    setMember_Id(member_id);
  };
  useEffect(() => {
    if (member_id === "") {
      setCoMemberNo("");
      setCoMemberNo("");
      setCoEmpCode("");
      setCoMember_class("");
      setCoLoaneeTitle("");
    }
  }, [member_id]);

  const handleColoneeMemberId = (coMemberNo) => {
    // No need for const member_id = e.target?.value; since member_id is already a parameter
    if (coMemberNo === undefined || coMemberNo === "") {
      console.error("Value is undefined or empty");
      setCoEmpCode("");
      setCoName("");
      setCoLoaneeTitle("");
      setCoMember_class("");

      return;
    }

    axios
      .get(`http://13.232.218.212:8000/all_memberdata/${coMemberNo}/`)
      .then((ress) => {
        if (ress.data.members && ress.data.members.length > 0) {
          const ColoneeData = ress.data.members[0];

          setCoEmpCode(ColoneeData["emp_no"] || "");
          setCoName(
            `${ColoneeData["first_name"] || ""} ${
              ColoneeData["middle_name"] || ""
            } ${ColoneeData["last_name"] || ""}`
          );
          setCoLoaneeTitle(ColoneeData["initial"] || "");
          setCoMember_class(ColoneeData["member_class"] || "");
        } else {
          console.error("Unexpected response:", ress);
          console.error("Error: Unexpected response format or empty data");
        }
      })
      .catch((error) => {
        console.error("Error fetching member data:", error);
      });

    setCoMemberNo(coMemberNo);
  };
  const eligibility = (loan_amount, installment_period) => {
    const requestData = {
      member_id: member_id,
      loan_amount: loan_amount,
      installment_period: installment_period,
    };

    if (member_id !== "" && loan_amount !== "" && installment_period !== "") {
      fetch("http://13.232.218.212:8000/loan_eligibility/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          console.log(data);
          if (data.status === "Not Eligible") {
            Swal.fire({
              icon: "warning",
              title: "Not Eligible",
              text: "This member is not eligible for a loan.",
              didOpen: () => {
                Swal.getPopup().style.borderRadius = "25px";
                const confirmButton = Swal.getConfirmButton();
                confirmButton.classList.add("custom-swal-button");
              },
            });
          } else {
            setdue_date(data.Due_date);
            setInterestRate(data.InterestRate);
            setinstallmentAmount(data.installmentAmount);
            // setMonths_to_retire(data.months_to_retire);
            setfirst_due_date(data.first_Due_date);
            setloan_amount(loan_amount);
            setinstallment_period(installment_period);

            // (Code for handling response)
          }
        })
        .catch((error) => {
          console.error("Error checking loan eligibility:", error);
        });
    } else {
      console.log(
        "Please provide valid member_id, loan_amount, and installment_period."
      );
    }
  };
  const handleSubmit = async () => {
    try {
      // const userRole = localStorage.getItem("role_name");
      let temp = [];

      if (garontor1Id !== "") {
        temp.push(garontor1Id);
      }
      if (garontor2Id !== "") {
        temp.push(garontor2Id);
      }
      if (garontor3Id !== "") {
        temp.push(garontor3Id);
      }
      const requestData = {
        guarantors: temp,
        member_id,
        initial,
        name,
        emp_no,
        member_class,
        branchName,
        address,
        city,
        pinCode,
        mobileNumber,
        coMemberNo,
        coLoaneeTitle,
        CoName,
        coEmpCode,
        comember_class,
        ac_type: "Staff Medium Term  Loan",
        due_date,
        first_due_date,
        installment_period,
        installmentAmount,
        loan_amount,
        InterestRate,
        loan_date,
        deduction: "0",
        operator: "A1",
        loan_purpose,
      };
      const response = await axios.post(
        "http://13.232.218.212:8000/loan_submit_new/",
        requestData
      );

      let jsonData = response.data;
      if (jsonData.success) {
        Swal.fire({
          icon: "success",
          title: "Thank You! ",
          text: `  Your bond number is ${jsonData.Bonad_number}`,
          didOpen: () => {
            Swal.getPopup().style.borderRadius = "25px";
            const confirmButton = Swal.getConfirmButton();
            confirmButton.classList.add("custom-swal-button");
          },
        });

        setMember_Id("");
        setEmp_No("");
        setName("");
        setBranchName("");
        setBalance("");
        setCoName("");
        setloan_amount("");
        setdue_date("");
        setfirst_due_date("");
        setinstallment_period("");
        setinstallmentAmount("");
        setInterestRate("");
        setGarontor1Id("");
        setMember_class("");
        setGarontor1Member_C("");
        setGarontor2Member_C("");
        setMobileNumber("");
        setPinCode("");
        setAddress("");
        setCity("");
        setinitial("");
      } else if (jsonData.gau_error === "guarantors is retired") {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "guarantors is retired",
          didOpen: () => {
            Swal.getPopup().style.borderRadius = "25px";
            const confirmButton = Swal.getConfirmButton();
            confirmButton.classList.add("custom-swal-button");
          },
        });
      } else if (jsonData.error === "Member is retired") {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Member is retired.",
          didOpen: () => {
            Swal.getPopup().style.borderRadius = "25px";
            const confirmButton = Swal.getConfirmButton();
            confirmButton.classList.add("custom-swal-button");
          },
        });
      } else if (
        jsonData.error === "Loan amount exceeds the eligible limit (0 Rs.)"
      ) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Loan amount exceeds the maximum limit of 2000000 Rs.",
          didOpen: () => {
            Swal.getPopup().style.borderRadius = "25px";
            const confirmButton = Swal.getConfirmButton();
            confirmButton.classList.add("custom-swal-button");
          },
        });
      } else if (
        jsonData.error ===
        "Loan amount exceeds the eligible limit (60000.00 Rs.)"
      ) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Loan amount exceeds the eligible limit (60000.00 Rs.)",
          didOpen: () => {
            Swal.getPopup().style.borderRadius = "25px";
            const confirmButton = Swal.getConfirmButton();
            confirmButton.classList.add("custom-swal-button");
          },
        });
      } else if (
        jsonData.error ===
        "Member has not repaid one-fourth of the previous loan"
      ) {
        console.log(jsonData.error); // You can choose to log the error message if needed
        Swal.fire({
          title: "Error",
          text: "Member has not repaid one-fourth of the previous loan",
          icon: "error",
          didOpen: () => {
            Swal.getPopup().style.borderRadius = "25px";
            const confirmButton = Swal.getConfirmButton();
            confirmButton.classList.add("custom-swal-button");
          },
        });
      } else {
        const errorMessage =
          response.data?.error || "failed to submit data. please try again.";
        console.log(errorMessage);
        Swal.fire({
          title: "Error",
          text: errorMessage,
          icon: "error",
          didOpen: () => {
            Swal.getPopup().style.borderRadius = "25px";
            const confirmButton = Swal.getConfirmButton();
            confirmButton.classList.add("custom-swal-button");
          },
        });
      }
    } catch (error) {
      console.error("Error submitting data:", error.message);
    }
  };
  const FirstDuedate = first_due_date;
  let formattedFirstDueDate = "";
  if (FirstDuedate) {
    const [year, month, day] = FirstDuedate.split("-");
    formattedFirstDueDate = `${day}-${month}-${year}`;
  }
  const Duedate = due_date;
  let formattedDueDate = "";
  if (Duedate) {
    const [year, month, day] = Duedate.split("-");
    formattedDueDate = `${day}-${month}-${year}`;
  }
  const Bdate = loan_date;
  let formattedLoanDate = "";
  if (Bdate) {
    const [year, month, day] = Bdate.split("-");
    formattedLoanDate = `${day}-${month}-${year}`;
  }

  const Tdate = transferDate;
  let formattedTransferDate = "";
  if (Tdate) {
    const [year, month, day] = Tdate.split("-");
    formattedTransferDate = `${day}-${month}-${year}`;
  }
  const Mdate = meetingDate;
  let formattedMeetingDate = "";
  if (Mdate) {
    const [year, month, day] = Bdate.split("-");
    formattedMeetingDate = `${day}-${month}-${year}`;
  }

  const LastPdate = lastPaidDate;
  let formattedLastDate = "";
  if (LastPdate) {
    const [year, month, day] = LastPdate.split("-");
    formattedLastDate = `${day}-${month}-${year}`;
  }
  return (
    <>
      <Sidebar />
      <div className="container-fluid dashboard-area d-flex">
        <div className="main-content p-4">
          {/* Navbar */}
          <Header />
          {/* Heading */}
          <div className="container d-flex text-start w-100 pb-1">
            <div className="H1-Heading-Main">New Staff Medium Term Loan</div>
          </div>
          <div className="container ">
            <div className="row First-Main-Row  pt-2 mb-1">
              <form>
                {/* Loanee  */}
                <div className="row">
                  <div className="col-xl col-lg col-md-2 col-sm-2 text-start">
                    <div class="mt-2 mb-1">
                      <label for="" className="" style={{ color: "red" }}>
                        Loanee
                      </label>
                    </div>
                  </div>
                  <div className="col-xl-1 col-lg-1 col-md-4 col-sm-6 text-start">
                    <div class="mt-2 mb-1">
                      <input
                        type="text"
                        id="member_class"
                        name="member_class"
                        class="form-control small-label bg-white"
                        readOnly
                        value={member_class}
                        onChange={(e) => {
                          setMember_class(e.target.value);
                        }}
                        min={0}
                      />
                    </div>
                  </div>
                  <div className="col-xl col-lg col-md-4 col-sm-6 col-6 text-start">
                    {/* <label htmlFor="memberNo" className="small-label">
                      Member No.
                    </label> */}
                    <div class="mt-2 mb-1">
                      <input
                        type="text"
                        id="member_id"
                        name="member_id"
                        className="form-control small-placeholder"
                        value={member_id}
                        placeholder="Member no"
                        onChange={(e) => {
                          setMember_Id(e.target.value);
                          handleMemberIdChange(e.target.value);
                          // handleLoanEligibility(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-xl-2 col-lg-2 col-md-4 col-sm-6 text-start">
                    {/* <label htmlFor="loaneeTitle" className="small-label">
                      Loanee Title*
                    </label> */}
                    <div class="mt-2 mb-1">
                      <input
                        type="text"
                        className="form-control small-placeholder bg-white"
                        id="initial"
                        name="initial"
                        value={initial}
                        onChange={(e) => {
                          setinitial(e.target.value);
                        }}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 text-start">
                    {/* <label htmlFor="name" className="small-label">
                      Name*
                    </label> */}
                    <div class="mt-2 mb-1">
                      <input
                        type="text"
                        className="form-control small-placeholder bg-white"
                        id="name"
                        name="name"
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-xl-2 col-lg-2 col-md-4 col-sm-6 text-start">
                    <div className="">
                      {/* <label htmlFor="loaneeEmpCode" className="small-label">
                        Emp Code*
                      </label> */}
                      <div class="mt-2 mb-1">
                        <input
                          type="text"
                          id="emp_no"
                          name="emp_no"
                          className="form-control small-placeholder bg-white"
                          value={emp_no}
                          onChange={(e) => {
                            setEmp_No(e.target.value);
                          }}
                          readOnly
                          min={0}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* Co-loanee  */}
                <div className="row mb-2">
                  <div className="col-xl col-lg col-md-2 col-sm-2 text-start">
                    <div class="mt-2">
                      <label for="" className="" style={{ color: "red" }}>
                        Co-Loanee
                      </label>
                    </div>
                  </div>
                  <div className="col-xl-1 col-lg-1 col-md-4 col-sm-6 text-start">
                    <div class="mt-2">
                      <input
                        type="text"
                        id="comember_class"
                        name="comember_class"
                        className="form-control  small-placeholder"
                        value={comember_class}
                        onChange={(e) => {
                          setCoMember_class(e.target.value);
                        }}
                        min={0}
                      />
                    </div>
                  </div>
                  <div className="col-xl col-lg col-md-4 col-sm-6 col-6 text-start">
                    {/* <label htmlFor="coMemberNo" className="small-label">
                      Member No.
                    </label> */}
                    <div className="mt-2">
                      <input
                        type="text"
                        id="coMemberNo"
                        name="coMemberNo"
                        className="form-control small-placeholder"
                        value={coMemberNo}
                        placeholder="Member no"
                        onChange={(e) => {
                          setCoMemberNo(e.target.value);
                          handleColoneeMemberId(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-3 text-start">
                    {/* <label htmlFor="coLoaneeTitle" className="small-label">
                      Title*
                    </label> */}
                    <div className="mt-2">
                      <input
                        type="text"
                        id="coLoaneeTitle"
                        name="coLoaneeTitle"
                        className="form-control small-placeholder"
                        value={coLoaneeTitle}
                      />
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 text-start">
                    {/* <label htmlFor="CoName" className="small-label">
                      Name*
                    </label> */}
                    <div className="mt-2">
                      <input
                        type="text"
                        className="form-control small-placeholder"
                        id="CoName"
                        name="CoName"
                        value={CoName}
                      />
                    </div>
                  </div>
                  <div className="col-xl-2 col-lg-2 col-md-4 col-sm-6 text-start">
                    <div className="mt-2">
                      {/* <label htmlFor="coEmpCode" className="small-label">
                        Emp Code*
                      </label> */}
                      <div className="mt-2">
                        <input
                          type="text"
                          id="coEmpCode"
                          name="coEmpCode"
                          className="form-control small-placeholder"
                          value={coEmpCode}
                          min={0}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* Branch  */}
                <div className="row">
                  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 text-start">
                    <label className="small-label" htmlFor="">
                      Branch Name*
                    </label>
                    <div className="">
                      <input
                        className="form-control"
                        type="text"
                        name="branchName"
                        value={branchName}
                        onChange={(e) => {
                          setBranchName(e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 text-start">
                    <label htmlFor="floatingInput" className="small-label">
                      Open Date*
                    </label>
                    <div>
                      <input
                        type="text"
                        className="form-control bg-white"
                        id="loan_date"
                        name="loan_date"
                        readOnly
                        value={formattedLoanDate}
                        onChange={(e) => {
                          setLoanDate(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 text-start ">
                    <div className="">
                      <label htmlFor="meetingNo" className="small-label">
                        Meeting No*
                      </label>
                      <input
                        type="text"
                        id="meetingNo"
                        name="meetingNo"
                        className="form-control small-placeholder"
                        value={meetingNo}
                        onChange={(e) => {
                          setMeetingNo(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 text-start">
                    <div className="">
                      <label htmlFor="meetingDate" className="small-label">
                        Meeting Date*
                      </label>
                      <input
                        type="text"
                        id="meetingDate"
                        name="meetingDate"
                        className="form-control small-placeholder"
                        value={formattedMeetingDate}
                        onChange={(e) => {
                          setMeetingDate(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>

                {/* Address  */}
                <div>
                  <div className="row mb-2">
                    <div
                      className="text-start"
                      style={{ fontWeight: "bold", color: "dodgerblue" }}
                    >
                      Resident Address
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 text-start">
                      <label htmlFor="address" className="small-label">
                        Address*
                      </label>
                      <div className="mb-1">
                        <textarea
                          type="text-area"
                          className="form-control"
                          id="address"
                          name="address"
                          value={address}
                          onChange={(e) => {
                            setAddress(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 text-start">
                      <label htmlFor="city" className="small-label">
                        City*
                      </label>
                      <div className="mb-1">
                        <input
                          type="text"
                          className="form-control"
                          id="city"
                          name="city"
                          value={city}
                          onChange={(e) => {
                            setCity(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 text-start">
                      <label htmlFor="pinCode" className="small-label">
                        Pin Code*
                      </label>
                      <div className="mb-1">
                        <input
                          type="text"
                          className="form-control"
                          id="pinCode"
                          name="pinCode"
                          value={pinCode}
                          onChange={(e) => {
                            setPinCode(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 text-start">
                      <div class="no-outline-login">
                        <label for="mobileNumber" className="small-label">
                          Mobile Number*
                        </label>
                        <input
                          type="text"
                          class="form-control small-placeholder"
                          id="mobileNumber"
                          name="mobileNumber"
                          value={mobileNumber}
                          onChange={(e) => {
                            setMobileNumber(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div
                      className="text-start"
                      style={{ fontWeight: "bold", color: "dodgerblue" }}
                    >
                      Office Address
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 text-start">
                      <label htmlFor="address" className="small-label">
                        Address*
                      </label>
                      <div className="mb-1">
                        <textarea
                          type="text-area"
                          className="form-control"
                          id="officeaddress"
                          name="officeaddress"
                          value={officeaddress}
                          onChange={(e) => {
                            setOfficeaddress(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 text-start">
                      <label htmlFor="city" className="small-label">
                        City*
                      </label>
                      <div className="mb-1">
                        <input
                          type="text"
                          className="form-control"
                          id="officecity"
                          name="officecity"
                          value={officecity}
                          onChange={(e) => {
                            setOfficecity(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 text-start">
                      <label htmlFor="pinCode" className="small-label">
                        Pin Code*
                      </label>
                      <div className="mb-1">
                        <input
                          type="text"
                          className="form-control"
                          id="officepin"
                          name="officepin"
                          value={officepin}
                          onChange={(e) => {
                            setOfficepin(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* Transfer DAte  */}
                <div className="row">
                  <div
                    className="text-start"
                    style={{ fontWeight: "bold", color: "red" }}
                  >
                    Loan Transfer Details, change it if Loan is transfered from
                    any other Branch
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 text-start ">
                    <div className="">
                      <label htmlFor="transferDate" className="small-label">
                        Transfer Date*
                      </label>
                      <input
                        type="text"
                        id="transferDate"
                        name="transferDate"
                        className="form-control small-placeholder"
                        value={formattedTransferDate}
                      />
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 text-start">
                    <div className="">
                      <label htmlFor="transferFrom" className="small-label">
                        Transfer From*
                      </label>
                      <select
                        id="transferFrom"
                        name="transferFrom"
                        className="form-control small-placeholder form-select"
                        value={transferFrom}
                      >
                        {/* Add options for the select dropdown based on your requirements */}
                        <option value="">Select Transfer From</option>
                        <option value="option1">Option 1</option>
                        <option value="option2">Option 2</option>
                        {/* Add more options as needed */}
                      </select>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 text-start ">
                    <div className="">
                      <label htmlFor="oldLoanNo" className="small-label">
                        Old Loan No.*
                      </label>
                      <input
                        type="text"
                        id="oldLoanNo"
                        name="oldLoanNo"
                        className="form-control small-placeholder"
                        value={oldLoanNo}
                        min={0}
                      />
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 text-start">
                    <div className="">
                      <label
                        htmlFor="interestDebited"
                        className="small-label"
                        style={{ color: "red" }}
                      >
                        Interest Debited*
                      </label>
                      <input
                        type="text"
                        id="interestDebited"
                        name="interestDebited"
                        className="form-control small-placeholder"
                        value={interestDebited}
                      />
                    </div>
                  </div>
                </div>

                {/* Balance Loan  */}
                <div className="row ">
                  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6  text-start">
                    <div className="">
                      <label htmlFor="balanceLoan" className="small-label">
                        Balance Loan*
                      </label>
                      <input
                        type="text"
                        id="balanceLoan"
                        name="balanceLoan"
                        className="form-control small-placeholder"
                        value={balanceLoan}
                      />
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6  text-start">
                    <div className="">
                      <label htmlFor="interest" className="small-label">
                        Interest*
                      </label>
                      <input
                        type="text"
                        id="interest"
                        name="interest"
                        className="form-control small-placeholder"
                        value={interest}
                      />
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6  text-start">
                    <div className="">
                      <label htmlFor="penalty" className="small-label">
                        Penalty*
                      </label>
                      <input
                        type="text"
                        id="penalty"
                        name="penalty"
                        className="form-control small-placeholder"
                        value={penalty}
                      />
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6  text-start ">
                    <div className="">
                      <label htmlFor="miscAmount" className="small-label">
                        Misc Amount(₹)*
                      </label>
                      <input
                        type="text"
                        id="miscAmount"
                        name="miscAmount"
                        className="form-control small-placeholder"
                        placeholder="₹"
                        value={miscAmount}
                      />
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6  text-start ">
                    <div className="">
                      <label htmlFor="miscAmount" className="small-label">
                        Recovery User
                      </label>
                      <input
                        type="text"
                        id="ruser"
                        name="ruser"
                        className="form-control small-placeholder"
                        // placeholder="₹"
                        value={ruser}
                      />
                    </div>
                  </div>
                </div>

                {/* Loan No.*  */}
                <div className="Border-Black px-2 mt-2 pb-2">
                  <div className="row mt-3 mb-3">
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6  text-start ">
                      <div className="">
                        <label
                          htmlFor="loanNo"
                          className="small-label"
                          style={{ color: "blue" }}
                        >
                          Loan No.
                        </label>
                        <input
                          type="text"
                          id="loanNo"
                          name="loanNo"
                          className="form-control small-placeholder bg-white"
                          value={loanNo}
                          onChange={(e) => {
                            setLoanNo(e.target.value);
                          }}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6  text-start ">
                      <div className="">
                        <label
                          htmlFor="sanctionAmount"
                          className="small-label"
                          style={{ color: "blue" }}
                        >
                          Sanction Amount(₹)*
                        </label>
                        <input
                          type="text"
                          id="sanctionAmount"
                          name="sanctionAmount"
                          placeholder="₹"
                          className="form-control small-placeholder"
                          value={sanctionAmount}
                          onChange={(e) => {
                            setSanctionAmount(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6  text-start ">
                      <div className="">
                        <label
                          htmlFor="loanAmount"
                          className="small-label"
                          style={{ color: "blue" }}
                        >
                          Loan Amount(₹)*
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          // className={(invalid[1])?"form-control small-label border-danger":"form-control small-label"}
                          id="loan_amount"
                          name="loan_amount"
                          placeholder="₹"
                          value={loan_amount}
                          onChange={(e) => {
                            setloan_amount(e.target.value);
                            // handleLoanEligibility();
                            eligibility(installment_period, e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6  text-start">
                      <div className="">
                        <label
                          htmlFor="period"
                          className="small-label"
                          style={{ color: "blue" }}
                        >
                          Period*
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="installment_period"
                          name="installment_period"
                          onChange={(e) => {
                            setinstallment_period(e.target.value);
                            // handleLoanEligibility();
                            eligibility(loan_amount, e.target.value);
                          }}
                          value={installment_period}
                        />
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6  text-start">
                      <div className="">
                        <label
                          htmlFor="LoanDate"
                          className="small-label"
                          style={{ color: "blue" }}
                        >
                          Loan Date*
                        </label>
                        <input
                          type="text"
                          className="form-control bg-white"
                          id="loan_date"
                          name="loan_date"
                          readOnly
                          value={formattedLoanDate}
                          onChange={(e) => {
                            setLoanDate(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6  text-start">
                      <div className="">
                        <label
                          htmlFor="interestRate"
                          className="small-label"
                          style={{ color: "blue" }}
                        >
                          Interest Rate
                        </label>
                        <input
                          type="text"
                          className="form-control  bg-white"
                          id="InterestRate"
                          name="InterestRate"
                          placeholder=""
                          value={InterestRate}
                          onChange={(e) => setInterestRate(e.target.value)}
                          step={0.01}
                          disabled
                        />
                      </div>
                    </div>

                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 text-start">
                      {/* Installment */}
                      <div className="">
                        <label
                          htmlFor="loaneeInstallment"
                          className="small-label"
                          style={{ color: "blue" }}
                        >
                          Installment(₹)
                        </label>
                        <input
                          type="text"
                          className="form-control bg-white"
                          id="installmentAmount"
                          name="installmentAmount"
                          placeholder="₹"
                          value={installmentAmount}
                          onChange={(e) => setinstallmentAmount(e.target.value)}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6  text-start">
                      <div className="">
                        <label
                          htmlFor="first_due_date"
                          className="small-label"
                          style={{ color: "blue" }}
                        >
                          First Inst. Due Date
                        </label>
                        <input
                          type="text"
                          id="first_due_date"
                          name="first_due_date"
                          className="form-control small-placeholder"
                          value={formattedFirstDueDate}
                          onChange={(e) => {
                            setfirst_due_date(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6  text-start">
                      <div className="">
                        <label
                          htmlFor="dueDate"
                          className="small-label"
                          style={{ color: "blue" }}
                        >
                          Due Date
                        </label>
                        <input
                          type="text"
                          id="due_date"
                          name="due_date"
                          className="form-control small-placeholder"
                          value={formattedDueDate}
                          onChange={(e) => {
                            setdue_date(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* Loan Security value  */}
                <div className="row mt-1">
                  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6  text-start">
                    <div className="">
                      <label htmlFor="balance" className="small-label">
                        Loan Security value
                      </label>
                      <input
                        type="number"
                        id="loan_value"
                        name="loan_value"
                        placeholder="0"
                        className="form-control small-placeholder"
                        value={loan_value}
                        onChange={(e) => {
                          setLoan_value(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6  text-start">
                    <div className="">
                      <label htmlFor="name" className="small-label"></label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        // placeholder="₹"
                        className="form-control small-placeholder"
                        // value={name}
                        // onChange={(e) => {
                        //   setLone_(e.target.value);
                        // }}
                      />
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6  text-start">
                    <div className="">
                      <label htmlFor="balance" className="small-label">
                        Balance(₹)*
                      </label>
                      <input
                        type="text"
                        id="balance"
                        name="balance"
                        placeholder="₹"
                        className="form-control small-placeholder"
                        value={balance}
                        onChange={(e) => {
                          setBalance(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6  text-start">
                    <div className="">
                      <label htmlFor="balance" className="small-label">
                        Recovery
                      </label>
                      <input
                        type="text"
                        id="recovery"
                        name="recovery"
                        placeholder="₹"
                        className="form-control small-placeholder"
                        value={recovery}
                        onChange={(e) => {
                          setRecovery(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6  text-start">
                    <div className="">
                      <label htmlFor="lastPaidDate" className="small-label">
                        Last Paid Date*
                      </label>
                      <input
                        type="text"
                        id="lastPaidDate"
                        name="lastPaidDate"
                        className="form-control small-placeholder"
                        value={formattedLastDate}
                      />
                    </div>
                  </div>
                </div>

                <div className="row mt-1">
                  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6  text-start">
                    <div className="">
                      <label htmlFor="balance" className="small-label">
                        Loan Purpose
                      </label>
                      <input
                        type="text"
                        id="loan_purpose"
                        name="loan_purpose"
                        // placeholder="0"
                        className="form-control small-placeholder"
                        value={loan_purpose}
                        onChange={(e) => {
                          setLoan_purpose(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6  text-start">
                    <div className="">
                      <label
                        htmlFor="sanctionCommittee"
                        className="small-label"
                      >
                        sanction Committee
                      </label>
                      <input
                        type="text"
                        id="sanctionCommittee"
                        name="sanctionCommittee"
                        // placeholder="₹"
                        className="form-control small-placeholder"
                        value={sanctionCommittee}
                        onChange={(e) => {
                          setSanctionCommittee(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="Border-Black px-2 mt-3 pb-2">
                  <div className="H2-Sub-Heading mt-2 ">SURETY</div>

                  <div className="row">
                    {/* No.1 Field */}
                    <div className="col-xl-1 col-lg-1 col-md-3 col-sm-3 text-start">
                      <div className="">
                        <label htmlFor="number1" className="small-label">
                          No.1
                        </label>
                        <input
                          type="text"
                          id="garontor1Member_C"
                          name="garontor1Member_C"
                          value={garontor1Member_C}
                          onChange={(e) => {
                            setGarontor1Member_C(e.target.value);
                          }}
                          className="form-control small-placeholder bg-white"
                          min={0}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="col-xl-2 col-lg-2 col-md-3 col-sm-3 text-start">
                      <label
                        htmlFor="guarantor1MemberId"
                        className="small-label"
                      >
                        Member No.
                      </label>
                      <input
                        type="text"
                        id="guarantor1MemberId"
                        name="guarantor1MemberId"
                        className="form-control small-placeholder "
                        value={garontor1Id}
                        onChange={(e) => {
                          setGarontor1Id(e.target.value);
                          handleGuarantor1IdChange(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-xl-2 col-lg-2 col-md col-sm text-start">
                      <label htmlFor="garontor1initial" className="small-label">
                        Initial
                      </label>
                      <input
                        type="text"
                        id="garontor1initial"
                        name="garontor1initial"
                        className="form-control small-placeholder"
                        value={garontor1Id === "" ? "" : garontor1initial}
                        onChange={(e) => {
                          setGarontor1Initial(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-xl col-lg col-md col-sm text-start">
                      <label
                        htmlFor="garontor1FullName"
                        className="small-label"
                      >
                        Full Name
                      </label>
                      <input
                        type="text"
                        id="garontor1FullName"
                        name="garontor1FullName"
                        className="form-control small-placeholder bg-white"
                        value={garontor1Id === "" ? "" : garontor1FullName}
                        onChange={(e) => {
                          setGarontor1FullName(e.target.value);
                        }}
                        readOnly
                      />
                    </div>
                    <div className="col-xl-2 col-lg-2 col-md-6 col-sm-6 text-start">
                      <div className="">
                        <label
                          htmlFor="garontor1EmpCode"
                          className="small-label"
                        >
                          Emp Code
                        </label>
                        <input
                          type="text"
                          id="garontor1EmpCode"
                          name="garontor1EmpCode"
                          className="form-control small-placeholder bg-white"
                          value={garontor1Id === "" ? "" : garontor1EmpCode}
                          onChange={(e) => {
                            setGarontor1EmpCode(e.target.value);
                          }}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    {/* No.2 Field */}
                    <div className="col-xl-1 col-lg-1 col-md-3 col-sm-3 text-start">
                      <div className="">
                        <label
                          htmlFor="garontor2Member_C"
                          className="small-label"
                        >
                          No.2
                        </label>
                        <input
                          type="text"
                          id="garontor2Member_C"
                          name="garontor2Member_C"
                          className="form-control small-placeholder"
                          value={garontor2Member_C}
                          onChange={(e) => {
                            setGarontor2Member_C(e.target.value);
                          }}
                          min={0}
                        />
                      </div>
                    </div>
                    <div className="col-xl-2 col-lg-2 col-md-3 col-sm-3 text-start">
                      <label
                        htmlFor="guarantor2MemberId"
                        className="small-label"
                      >
                        Member No.
                      </label>
                      <input
                        type="text"
                        id="guarantor2MemberId"
                        name="guarantor2MemberId"
                        className="form-control small-placeholder"
                        value={garontor2Id}
                        // onChange={handleGuarantor2IdChange}
                        onChange={(e) => {
                          setGarontor2Id(e.target.value);
                          handleGuarantor2IdChange(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-xl-2 col-lg-2 col-md col-sm text-start">
                      <label htmlFor="garontor2initial" className="small-label">
                        Initial
                      </label>
                      <input
                        type="text"
                        id="garontor2initial"
                        name="garontor2initial"
                        className="form-control small-placeholder"
                        value={garontor2Id === "" ? "" : garontor2initial}
                        onChange={(e) => {
                          setGarontor2Initial(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-xl col-lg col-md col-sm text-start">
                      <label
                        htmlFor="garontor2FullName"
                        className="small-label"
                      >
                        Full Name
                      </label>
                      <input
                        type="text"
                        id="garontor2FullName"
                        name="garontor2FullName"
                        className="form-control small-placeholder"
                        value={garontor2Id === "" ? "" : garontor2FullName}
                        onChange={(e) => {
                          setGarontor2FullName(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-xl-2 col-lg-2 col-md-6 col-sm-6 text-start">
                      <div className="">
                        <label
                          htmlFor="garontor2EmpCode"
                          className="small-label"
                        >
                          Emp Code
                        </label>
                        <input
                          type="text"
                          id="garontor2EmpCode"
                          name="garontor2EmpCode"
                          className="form-control small-placeholder"
                          value={garontor2Id === "" ? "" : garontor2EmpCode}
                          onChange={(e) => {
                            setGarontor2EmpCode(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    {/* No.3 Field */}
                    <div className="col-xl-1 col-lg-1 col-md-3 col-sm-3 text-start">
                      <div className="">
                        <label
                          htmlFor="garontor3Member_C"
                          className="small-label"
                        >
                          No.3
                        </label>
                        <input
                          type="text"
                          id="garontor3Member_C"
                          name="garontor3Member_C"
                          className="form-control small-placeholder"
                          value={garontor3Member_C}
                          onChange={(e) => {
                            setGarontor3Member_C(e.target.value);
                          }}
                          min={0}
                        />
                      </div>
                    </div>
                    <div className="col-xl-2 col-lg-2 col-md-3 col-sm-3 text-start">
                      <label
                        htmlFor="guarantor3MemberId"
                        className="small-label"
                      >
                        Member No.
                      </label>
                      <input
                        type="text"
                        id="guarantor3MemberId"
                        name="guarantor3MemberId"
                        className="form-control small-placeholder"
                        value={garontor3Id}
                        onChange={(e) => {
                          setGarontor3Id(e.target.value);
                          handleGuarantor3IdChange(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-xl-2 col-lg-2 col-md col-sm text-start">
                      <label htmlFor="garontor3initial" className="small-label">
                        Initial
                      </label>
                      <input
                        type="text"
                        id="garontor3initial"
                        name="garontor3initial"
                        className="form-control small-placeholder"
                        value={garontor3Id === "" ? "" : garontor3initial}
                        onChange={(e) => {
                          setGarontor3Initial(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-xl col-lg col-md col-sm text-start">
                      <label
                        htmlFor="garontor3FullName"
                        className="small-label"
                      >
                        Name
                      </label>
                      <input
                        type="text"
                        id="garontor3FullName"
                        name="garontor3FullName"
                        className="form-control small-placeholder"
                        value={garontor3Id === "" ? "" : garontor3FullName}
                        onChange={(e) => {
                          setGarontor3FullName(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-xl-2 col-lg-2 col-md-6 col-sm-6 text-start">
                      <div className="">
                        <label
                          htmlFor="garontor2EmpCode"
                          className="small-label"
                        >
                          Emp Code
                        </label>
                        <input
                          type="text"
                          id="garontor3EmpCode"
                          name="garontor3EmpCode"
                          className="form-control small-placeholder"
                          // value={
                          //   garontor3Id === ""
                          //     ?
                          //       ""
                          //     : garontor3EmpCode
                          // }
                          value={garontor3Id === "" ? "" : garontor3EmpCode}
                          onChange={(e) => {
                            setGarontor3EmpCode(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-1 col-lg-1 col-md-3 col-sm-3 text-start">
                      <div className="">
                        <label htmlFor="number4" className="small-label">
                          No.4
                        </label>
                        <input
                          type="text"
                          id="garontor4Member_C"
                          name="garontor4Member_C"
                          value={garontor4Member_C}
                          onChange={(e) => {
                            setGarontor4Member_C(e.target.value);
                          }}
                          className="form-control small-placeholder bg-white"
                          min={0}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="col-xl-2 col-lg-2 col-md-3 col-sm-3 text-start">
                      <label
                        htmlFor="guarantor4MemberId"
                        className="small-label"
                      >
                        Member No.
                      </label>
                      <input
                        type="text"
                        id="guarantor4MemberId"
                        name="guarantor4MemberId"
                        className="form-control small-placeholder "
                        value={garontor4Id}
                        onChange={(e) => {
                          setGarontor4Id(e.target.value);
                          handleGuarantor4IdChange(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-xl-2 col-lg-2 col-md col-sm text-start">
                      <label htmlFor="garontor4initial" className="small-label">
                        Initial
                      </label>
                      <input
                        type="text"
                        id="garontor4initial"
                        name="garontor4initial"
                        className="form-control small-placeholder"
                        value={garontor4Id === "" ? "" : garontor4initial}
                        onChange={(e) => {
                          setGarontor4Initial(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-xl col-lg col-md col-sm text-start">
                      <label
                        htmlFor="garontor4FullName"
                        className="small-label"
                      >
                        Full Name
                      </label>
                      <input
                        type="text"
                        id="garontor4FullName"
                        name="garontor4FullName"
                        className="form-control small-placeholder bg-white"
                        value={garontor4Id === "" ? "" : garontor4FullName}
                        onChange={(e) => {
                          setGarontor4FullName(e.target.value);
                        }}
                        readOnly
                      />
                    </div>
                    <div className="col-xl-2 col-lg-2 col-md-6 col-sm-6 text-start">
                      <div className="">
                        <label
                          htmlFor="garontor4EmpCode"
                          className="small-label"
                        >
                          Emp Code
                        </label>
                        <input
                          type="text"
                          id="garontor4EmpCode"
                          name="garontor4EmpCode"
                          className="form-control small-placeholder bg-white"
                          value={garontor4Id === "" ? "" : garontor4EmpCode}
                          onChange={(e) => {
                            setGarontor4EmpCode(e.target.value);
                          }}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="H2-Sub-Heading mt-2 ">Introduce By :</div>
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-start">
                    <div className="">
                      <label htmlFor="specialRemark" className="small-label">
                        Special Remark
                      </label>
                      <textarea
                        id="specialRemark"
                        name="specialRemark"
                        className="form-control"
                        rows="3"
                        value={specialRemark}
                        onChange={(e) => {
                          setSpecialRemark(e.target.value);
                        }}
                      ></textarea>
                    </div>
                  </div>
                </div>

                <div className="row mt-2 mb-2">
                  <div className="col-sm d-flex justify-content-center">
                    <button
                      type="button"
                      className="mt-2 mx-2"
                      onClick={handleSubmit}
                      style={{
                        padding: "7px 25px 7px 25px",
                        backgroundColor: "green",
                        color: "white",
                        fontWeight: "bold",
                        border: "none",
                        borderRadius: "7px",
                        fontSize: "16px",
                      }}
                    >
                      Submit
                    </button>
                    <button
                      type="button"
                      className="mt-2 mx-2"
                      // onClick={handleCancel}
                      style={{
                        padding: "7px 25px 7px 25px",
                        backgroundColor: "red",
                        color: "white",
                        fontWeight: "bold",
                        border: "none",
                        borderRadius: "7px",
                        fontSize: "16px",
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
