import React, { useState, useEffect } from "react";
import Sidebar from "./Sidebar";
import Header from "../components/Header";
import axios from "axios";
import { FaShoppingCart } from "react-icons/fa";
import Swal from "sweetalert2";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import DataTable from "react-data-table-component";
import "../Style/Global_Classes.css";
import "../Style/DividendInterest.css";

export default function DividentInterest() {
  let [MemberId, setMemberID] = useState("");
  let [Region, setRegion] = useState("BPCL");
  let [posting, setPosting] = useState("");
  let [inputReason, setInputReason] = useState("");
  let [accountType, setAccountType] = useState([]);
  let [selectedAccountType, setSelectedAccountType] = useState([]);

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, "0");
    const day = today.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const [formData, setFormData] = useState({
    employeeNO: "",
    firstName: "",
    lastName: "",
    gender: "",
    birthDate: "",
    age: "",
    joinDate: "",
    confirmDate: "",
    email: "",
    panNo: "",
    mobileNumber: "",
    maritalStatus: "",
    ifscCode: "",
    bankSavingAcNo: "",
    bankName: "",
    branchName: "",
    NumberOfShares: 0,
    SharePrice: 0,
    PurchaseDate: new Date().toISOString().split("T")[0],
  });

  const handleMemberIdChange = async (event) => {
    const memberId = event.target.value;
    setMemberID(memberId);
    try {
      if (!memberId) {
        setFormData({
          employeeNO: "",
          firstName: "",
          lastName: "",
          joinDate: "",
          confirmDate: "",
          email: "",
          panNo: "",
          ifscCode: "",
          bankSavingAcNo: "",
          bankName: "",
          branchName: "",
        });
        return;
      }

      const response = await axios.get(
        `http://13.232.218.212:8000/all_memberdata/${memberId}/`
      );

      const jsondata = response.data;
      const data = jsondata.members[0];
      console.log(data);
      setFormData({
        ...formData,
        employeeNO: data.emp_no || "",
        firstName: data.full_name || "",
        lastName: data.last_name || "",
        age: data.age || "",
        joinDate: data.join_date || "",
        confirmDate: data.confirmed_on || "",
        ifscCode: data.IFSC_code || "",
        bankSavingAcNo: data.bank_ac_no || "",
        bankName: data.bank_name || "",
        branchName: data.branch_name || "",
      });
    } catch (error) {
      console.error("Error fetching member data:", error);
    }
  };

  const [transactions, setTransactions] = useState([]);

  const [memberId, setMemberId] = useState("");
  useEffect(() => {
    let dataArray = [];
    let inputObject = {
      date: <input type="text" className="inputFields" value={formattedDate} />,
      member_id: (
        <input type="number" className="inputFields" style={{ width: "90%" }} />
      ),
      MonthlyDeposit: (
        <input type="text" className="inputFields" style={{ width: "90%" }} />
      ),
      InterestRate: (
        <input type="number" className="inputFields" style={{ width: "90%" }} />
      ),
      deposit_period: (
        <input type="number" className="inputFields" style={{ width: "90%" }} />
      ),
      MonthlyDeposit: (
        <input type="number" className="inputFields" style={{ width: "90%" }} />
      ),
      MonthlyDeposit: (
        <input type="number" className="inputFields" style={{ width: "90%" }} />
      ),
      MonthlyDeposit: (
        <input type="text" className="inputFields" style={{ width: "90%" }} />
      ),
    };

    const fetchData = async () => {
      try {
        const response = await axios.post(
          "http://13.232.218.212:8000/rd_history/",
          {
            Account_type: selectedAccountType,
            member_id: MemberId,
          }
        );
        console.log("API Response:", response.data);
        dataArray = response.data.Output;
        dataArray.push(inputObject);
        console.log("dataArray", dataArray);
        setTransactions(dataArray);
      } catch (error) {
        console.error("Error fetching transaction history:", error);
      }
    };

    fetchData();
  }, [formData.employeeNO]);

  const [totalSavingBalance, setTotalSavingBalance] = useState("");

  const apiUrl = "http://13.232.218.212:8000/saving_history/";

  const Odate = getCurrentDate();
  let formattedDate = "";
  if (Odate) {
    const [year, month, day] = Odate.split("-");
    formattedDate = `${day}-${month}-${year}`;
  }

  const fetchAccountType = async () => {
    try {
      const response = await axios.post(
        "http://13.232.218.212:8000/rd_account_type/"
      );
      setAccountType(response.data.Output);
    } catch (error) {
      console.error("Error fetching teachers:", error);
    }
  };

  useEffect(() => {
    fetchAccountType();
  }, []);

  const handleAccountType = (e) => {
    setSelectedAccountType(e.target.value);
  };
  const columns = [
    {
      name: "Date",
      selector: "date",
      sortable: true,
      center: true,

      style: {
        borderRight: "1px solid #ddd",
      },
    },
    {
      name: "RV No",
      selector: "member_id",
      sortable: true,
      center: true,

      style: {
        borderRight: "1px solid #ddd",
      },
    },
    {
      name: "Particular",
      selector: "MonthlyDeposit",
      sortable: true,
      center: true,

      style: {
        borderRight: "1px solid #ddd",
      },
    },
    {
      name: "Debit",
      selector: "deposit_period",
      sortable: true,
      center: true,
      style: {
        borderRight: "1px solid #ddd",
        textAlign: "center",
      },
    },
    {
      name: "Credit",
      selector: "deposit_period",
      sortable: true,
      center: true,
      style: {
        borderRight: "1px solid #ddd",
        textAlign: "center",
      },
    },
    {
      name: "Balance",
      selector: "deposit_period",
      sortable: true,
      center: true,
      style: {
        borderRight: "1px solid #ddd",
        textAlign: "center",
      },
    },
    {
      name: "Debit",
      selector: "deposit_period",
      sortable: true,
      center: true,

      style: {
        borderRight: "1px solid #ddd",
        textAlign: "center",
      },
    },
    {
      name: "Credit",
      selector: "deposit_period",
      sortable: true,
      center: true,

      style: {
        borderRight: "1px solid #ddd",
        textAlign: "center", // Add a right border to the column
      },
    },
    {
      name: "Balance",
      selector: "deposit_period",
      sortable: true,
      center: true,

      style: {
        borderRight: "1px solid #ddd",
        textAlign: "center", // Add a right border to the column
      },
    },
    {
      name: "OPT",
      selector: "deposit_period",
      sortable: true,
      center: true,

      style: {
        borderRight: "1px solid #ddd",
        textAlign: "center", // Add a right border to the column
      },
    },
  ];
  const customStyles = {
    rows: {
      style: {
        minHeight: "48px",
        textAlign: "center",
      },
    },
    headCells: {
      style: {
        minHeight: "40px",
        backgroundColor: "#4db3c8",
        fontSize: "14px",
        fontWeight: "400",
        color: "white",
        textAlign: "center",
      },
    },
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const requiredFields = [
      "transactionType",
      "transactionAmount",
      "transactionDetails",
      "receiptVoucherNo",
      "selectedBankTransfer",
      "chequeNo",
      "micrCode",
      "ifscCode",
      "selectedBankName",
      "selectedBankBranch",
    ];

    const currentDate = new Date().toISOString().split("T")[0];
  };

  const [currentDate, setCurrentDate] = useState(
    new Date().toISOString().split("T")[0]
  );

  useEffect(() => {
    // Disable other fields based on the updated state
    if (formData.cashAmount !== "") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        bankAmount: "",
        transferAmount: "",
      }));
    } else if (formData.bankAmount !== "") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        cashAmount: "",
        transferAmount: "",
      }));
    } else if (formData.transferAmount !== "") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        cashAmount: "",
        bankAmount: "",
      }));
    }
  }, [formData]);

  const currentYear = new Date().getFullYear();
  const previousYear = currentYear - 1;
  const yearRange = `${previousYear} - ${currentYear}`;

  return (
    <>
      <Sidebar />
      <div className="container-fluid dashboard-area d-flex">
        <div className="main-content p-4">
          {/* Navbar */}
          <Header />
          {/* Heading */}

          <div className="container-fluid First-Main-Row border">
            <div className="row    pt-3 pb-3 ">
              <form
                className="mt-3 py-3"
                style={{ backgroundColor: "whitesmoke", borderRadius: "20px" }}
              >
                <div className="row ">
                  <div className="col-lg-6 col-md-12 col-sm-12 form-fields  d-flex align-items-center mt-lg-0 mt-2">
                    <div className="first-info-div">Account Type :</div>
                    <select
                      className="form-control no-outline   w-25"
                      value={selectedAccountType}
                      onChange={handleAccountType}
                    >
                      <option value="">Select account type</option>
                      {accountType.map((type, index) => (
                        <option key={index} value={type.Account_type}>
                          {type.Account_type}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row mb-2">
                  <div
                    className="col-lg-6 col-md-12 col-sm-12 form-fields d-flex align-items-center"
                    style={{ gap: "1em" }}
                  >
                    <div className="first-info-div">Employee No. :</div>
                    <input
                      type="number"
                      name="TermInMonths"
                      className="form-control no-outline   w-25"
                      value={formData.employeeNO}
                      // onChange={handleMemberIdChange}
                      min={0}
                      disabled
                    />
                  </div>
                  <div
                    className="col-lg-6 col-md-12 col-sm-12 form-fields  d-flex align-items-center mt-lg-0 mt-2"
                    style={{ gap: "1em" }}
                  >
                    {/* <div className="w-md-25 w-0 border"></div> */}
                    <div className="first-info-div first-info-div-member">
                      Member No.* :
                    </div>
                    <input
                      type="number"
                      name="TermInMonths"
                      className="form-control no-outline   w-25"
                      value={MemberId}
                      onChange={handleMemberIdChange}
                      min={0}
                    />
                  </div>
                </div>

                <div className="row mb-2">
                  <div
                    className="col-lg-6 col-md-12 col-sm-12 form-fields d-flex align-items-center"
                    style={{ gap: "1em" }}
                  >
                    <div className="first-info-div">Name :</div>
                    <div>{formData.firstName}</div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 form-fields"></div>
                </div>

                <div className="row pb-2">
                  <div
                    className="col-lg-6 col-md-12 col-sm-12 form-fields d-flex"
                    style={{ gap: "1em" }}
                  >
                    <div className="first-info-div">Region :</div>
                    <div>{Region}</div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 form-fields"></div>
                </div>

                {/* <div className="row pb-2">
                  <div
                    className="col-lg-6 col-md-12 col-sm-12 form-fields d-flex"
                    style={{ gap: "1em" }}
                  >
                    <div className="first-info-div">Posting :</div>
                    <div>{posting}</div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 form-fields"></div>
                </div> */}

                {/* <div className="row pb-2">
                  <div
                    className="col-lg-6 col-md-12 col-sm-12 form-fields d-flex"
                    style={{ gap: "1em" }}
                  >
                    <div className="first-info-div">Reason :</div>
                    <div>{inputReason}</div>
                    
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 form-fields"></div>
                </div> */}
              </form>
            </div>
          </div>

          <div className="container-fluid d-flex">
            <div
              className="row py-2 w-100"
              style={{
                backgroundColor: "",
                borderRadius: "10px",
              }}
            >
              <div className="col-12">
                <div className="H1-Heading-Main">
                  {yearRange} Dividend/Contribution Interest Receipt/Payment
                  Entry
                </div>
                {formData.employeeNO || memberId !== "" ? (
                  <div>
                    <DataTable
                      columns={columns}
                      data={transactions}
                      noHeader
                      pagination={false}
                      striped
                      dense
                      responsive
                      customStyles={customStyles}
                    />
                    <div className="row mt-3 mb-3">
                      <div className="col-sm d-flex justify-content-center">
                        <button
                          type="button"
                          className="mt-2 mx-2"
                          // onClick={handleFormSubmit}
                          style={{
                            padding: "7px 25px 7px 25px",
                            backgroundColor: "green",
                            color: "white",
                            fontWeight: "bold",
                            border: "none",
                            borderRadius: "7px",
                            fontSize: "16px",
                          }}
                        >
                          Save
                        </button>
                        <button
                          type="button"
                          className="mt-2 mx-2"
                          // onClick={handleCancel}
                          style={{
                            padding: "7px 25px 7px 25px",
                            backgroundColor: "red",
                            color: "white",
                            fontWeight: "bold",
                            border: "none",
                            borderRadius: "7px",
                            fontSize: "16px",
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                      {/* <></> */}
                    </div>
                  </div>
                ) : (
                  <p style={{ textAlign: "center" }}>
                    No data available, Please Insert The Member ID!
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
