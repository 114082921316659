import React, { useState, useEffect } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import Sidebar from "./Sidebar";
import Header from "../components/Header";

const ViewAllDeposit = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [deposits, setDeposits] = useState([]);
  const [name, setName] = useState("");
  const [memberId, setMemberId] = useState("");

  useEffect(() => {
    fetchDeposits();
  }, [searchQuery]);

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const fetchDeposits = async () => {
    try {
      const response = await axios.post(
        "http://13.232.218.212:8000/view_all_deposit/",
        {
          emp_no: searchQuery || "",
        }
      );

      if (Array.isArray(response.data.data)) {
        setDeposits(response.data.data);
        const names = response.data.data.map((item) => item.name);
        setName(names.length > 0 ? names[0] : "");
        const memberId = response.data.data.map((item) => item.member_id);
        setMemberId(memberId.length > 0 ? memberId[0] : "");
      } else {
        console.error("Response data is not an array:", response.data);
        setDeposits([]);
        setName("");
      }
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching deposits:", error);
    }
  };

  const columns = [
    // { name: "M ID", selector: "member_id" },

    {
      name: "Account Type",
      selector: (row) => row.Account_type,
      sortable: true,

      width: "160px",
    },
    {
      name: "Ac No",
      selector: (row) => row.RDID,
      sortable: true,
      center: true,
    },
    {
      name: "Start Date",
      selector: (row) => row.startDateDemo,
      sortable: true,
      center: true,
      cell: (row) => {
        const loanDate = new Date(row.startDateDemo);
        if (isNaN(loanDate.getTime())) {
          return "--";
        }
        const formattedDate = `${loanDate
          .getDate()
          .toString()
          .padStart(2, "0")}-${(loanDate.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${loanDate.getFullYear()}`;
        return formattedDate;
      },
    },
    {
      name: "End Date",
      selector: (row) => row.enddateDemo,
      sortable: true,
      center: true,
      cell: (row) => {
        const loanDate = new Date(row.enddateDemo);
        if (isNaN(loanDate.getTime())) {
          return "--";
        }
        const formattedDate = `${loanDate
          .getDate()
          .toString()
          .padStart(2, "0")}-${(loanDate.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${loanDate.getFullYear()}`;
        return formattedDate;
      },
    },
    {
      name: "Int Rate",
      selector: (row) => row.InterestRate,
      sortable: true,
    },
    {
      name: "Period",
      selector: (row) => row.deposit_period,
      sortable: true,
    },
    {
      name: "M Amt",
      selector: (row) => row.MaturityAmt,
      sortable: true,
      center: true,
      width: "100px",
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "48px",
        textAlign: "center",
      },
    },
    headCells: {
      style: {
        minHeight: "40px",
        backgroundColor: "#4db3c8",
        fontSize: "14px",
        fontWeight: "400",
        color: "white",
        textAlign: "center",
      },
    },
  };

  return (
    <>
      <div>
        <Sidebar />
        <div className="container-fluid dashboard-area d-flex">
          <div className="main-content p-4">
            {/* Navbar */}
            <Header />
            {/* Heading */}
            <div className="container w-100 pb-1">
              <div className="row w-100 align-items-center">
                <div className="col-12 text-start">
                  <h4 style={{ fontWeight: "bold", color: "dodgerblue" }}>
                    View All Deposit
                  </h4>
                </div>
                <div className="container d-flex justify-content-center">
                  <div className="row w-100 text-start">
                    <div className="col-4">
                      <label>Employee No.</label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Enter Employee No"
                        value={searchQuery}
                        onChange={handleSearchInputChange}
                      />
                    </div>
                    <div className="col-4">
                      <label htmlFor="">Name</label>
                      <input
                        type="text"
                        value={name}
                        className="form-control"
                      />
                    </div>
                    <div className="col-4">
                      <label htmlFor="">Member ID</label>
                      <input
                        type="text"
                        value={memberId}
                        className="form-control"
                      />
                    </div>

                    {/* Data Table */}
                    <div className="pt-4">
                      <DataTable
                        columns={columns}
                        data={deposits}
                        customStyles={customStyles}
                        pagination
                        highlightOnHover
                        striped
                        dense
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewAllDeposit;
