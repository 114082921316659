import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import Header from "../components/Header";
import DataTable from "react-data-table-component";
import axios from "axios";

const AllEntries = () => {
  const [entriesType, setEntriesType] = useState("Deposits");
  const [accountNo, setAccountNo] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [interest, setInterest] = useState([]);
  const [deposit, setDeposit] = useState([]);
  const [transaction, setTransaction] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          "http://13.232.218.212:8000/view_in_deposit/",
          {
            account_no: accountNo,
            type: selectedType,
          }
        );

        const responseData = response.data || "";
        const DepositData = responseData.data.deposits;
        const IntData = responseData.data.interest;
        const RenewalData = responseData.data.transactions;

        setDeposit(DepositData);
        setInterest(IntData);
        setTransaction(RenewalData);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [entriesType, accountNo, selectedType]);

  const Deposits = [
    // {
    //     name: "Member ",
    //     selector: (row) => row.member_id,
    //     sortable: true,
    //     width: "250px",
    // },
    {
      name: "Account Type",
      selector: (row) => row.Account_type,
      sortable: true,
      width: "200px",
    },
    {
      name: "Interest Rate",
      selector: (row) => row.InterestRate,
      sortable: true,
    },
    {
      name: "Maturity Amount",
      selector: (row) => row.MaturityAmt,
      sortable: true,
    },
    {
      name: "Start Date",
      selector: (row) => row.StartDate,
      sortable: true,
      cell: (row) => {
        const date = new Date(row.StartDate);
        if (isNaN(date.getTime())) {
          return "";
        }
        const formattedDate = `${date.getDate().toString().padStart(2, "0")}-${(
          date.getMonth() + 1
        )
          .toString()
          .padStart(2, "0")}-${date.getFullYear()}`;
        return formattedDate;
      },
    },
    {
      name: "End Date",
      selector: (row) => row.EndDate,
      sortable: true,
      cell: (row) => {
        const endDate = new Date(row.EndDate);
        if (isNaN(endDate.getTime())) {
          return "";
        }
        const formattedDate = `${endDate
          .getDate()
          .toString()
          .padStart(2, "0")}-${(endDate.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${endDate.getFullYear()}`;
        return formattedDate;
      },
    },
    {
      name: "Opening Balance",
      selector: (row) => row.opening_balance,
      sortable: true,
    },
  ];

  const int = [
    // {
    //     name: "Member ",
    //     selector: (row) => row.member_id,
    //     sortable: true,
    //     width: "250px",
    // },
    {
      name: "Date",
      selector: (row) => row.date,
      sortable: true,
      cell: (row) => {
        const date = new Date(row.date);
        if (isNaN(date.getTime())) {
          return "";
        }
        const formattedDate = `${date.getDate().toString().padStart(2, "0")}-${(
          date.getMonth() + 1
        )
          .toString()
          .padStart(2, "0")}-${date.getFullYear()}`;
        return formattedDate;
      },
    },
    {
      name: "Particular",
      selector: (row) => row.DETAIL,
      sortable: true,
      width: "200px",
    },
    {
      name: "RV NO",
      selector: (row) => row.RVNO,
      sortable: true,
    },
    {
      name: "Debit",
      selector: (row) => row.DEBIT,
      sortable: true,
    },
    {
      name: "Credit",
      selector: (row) => row.CREDIT,
      sortable: true,
    },
    {
      name: "Balance",
      selector: (row) => row.BALANCE,
      sortable: true,
    },
  ];
  const transactionColumn = [
    // {
    //     name: "Member ",
    //     selector: (row) => row.member_id,
    //     sortable: true,
    //     width: "250px",
    // },
    {
      name: "Date",
      selector: (row) => row.date,
      sortable: true,
      cell: (row) => {
        const date = new Date(row.date);
        if (isNaN(date.getTime())) {
          return "";
        }
        const formattedDate = `${date.getDate().toString().padStart(2, "0")}-${(
          date.getMonth() + 1
        )
          .toString()
          .padStart(2, "0")}-${date.getFullYear()}`;
        return formattedDate;
      },
    },
    {
      name: "Particular",
      selector: (row) => row.DETAIL,
      sortable: true,
      width: "200px",
    },
    {
      name: "RV NO",
      selector: (row) => row.RVNO,
      sortable: true,
    },

    {
      name: "Debit",
      selector: (row) => row.DEBIT,
      sortable: true,
    },
    {
      name: "Credit",
      selector: (row) => row.CREDIT,
      sortable: true,
    },

    {
      name: "Balance",
      selector: (row) => row.BALANCE,
      sortable: true,
    },
  ];

  const getStyles = (entries) => {
    return {
      color: entriesType === entries ? "white" : "white",
      ...(entriesType === entries
        ? {
            borderBottom: "4px solid orange",
          }
        : {}),
    };
  };

  const customStyles = {
    rows: {
      style: {
        minHeight: "48px",
        textAlign: "center",
      },
    },
    headCells: {
      style: {
        minHeight: "40px",
        backgroundColor: "#4db3c8",
        fontSize: "14px",
        fontWeight: "400",
        color: "white",
        textAlign: "center",
      },
    },
  };
  return (
    <>
      <Sidebar />
      <div className="container-fluid dashboard-area d-flex">
        <div className="main-content p-4">
          <Header />
          <div
            className="container d-flex w-100 pb-1"
            style={{ flexDirection: "column" }}
          >
            <div className="row py-3">
              <div className="col-4 text-start">
                <h3 style={{ color: "dodgerblue" }}>
                  <strong>All Entries</strong>
                </h3>
              </div>
              <div className="col-4">
                <input
                  type="number"
                  placeholder="Account No."
                  className="form-control"
                  value={accountNo}
                  onChange={(e) => setAccountNo(e.target.value)}
                />
              </div>
              <div className="col-4">
                <select
                  className="form-select"
                  value={selectedType}
                  onChange={(e) => setSelectedType(e.target.value)}
                >
                  <option value="">Select Type</option>
                  <option value="CASH CERTIFICATE DEPOSIT">
                    Cash Certificate Deposit
                  </option>
                  <option value="TERM DEPOSIT">Term Deposit</option>
                  <option value="AMRUT DEPOSIT">AMRUT DEPOSIT</option>
                  <option value="CUMULATIVE DEPOSIT">CUMULATIVE DEPOSIT</option>
                  <option value="EK LAKSHA YOJANA DEPOSIT">
                    EK LAKSHA YOJANA DEPOSIT
                  </option>
                  <option value="JOIN SAVING DEPOSIT">
                    JOIN SAVING DEPOSIT
                  </option>
                  <option value="LAKHPATI YOJANA DEPOSIT">
                    LAKHPATI YOJANA DEPOSIT
                  </option>
                  <option value="MEMBER SAVING DEPOSIT">
                    MEMBER SAVING DEPOSIT
                  </option>
                  <option value="MEMBER SETTLEMENT ACCOUNT">
                    MEMBER SETTLEMENT ACCOUNT
                  </option>
                  <option value="MONTHLY INCOME SCHEME">
                    MONTHLY INCOME SCHEME
                  </option>
                  <option value="RECURRING DEPOSIT">RECURRING DEPOSIT</option>
                  <option value="SHARE CAPITAL">SHARE CAPITAL</option>
                  <option value="SHARE APPLICATION">SHARE APPLICATION</option>
                </select>
              </div>
            </div>
            <div className="row">
              <table>
                <tbody>
                  <tr>
                    <td
                      className="column-heading"
                      style={{
                        cursor: "pointer",
                        ...getStyles("Deposits"),
                      }}
                      onClick={() => setEntriesType("Deposits")}
                    >
                      Deposits
                    </td>
                    <td
                      className="column-heading"
                      style={{
                        cursor: "pointer",
                        ...getStyles("interest"),
                      }}
                      onClick={() => setEntriesType("interest")}
                    >
                      Interest
                    </td>
                    <td
                      className="column-heading"
                      style={{
                        cursor: "pointer",
                        ...getStyles("Renewal"),
                      }}
                      onClick={() => setEntriesType("Renewal")}
                    >
                      Renewal
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="row">
              {entriesType === "Deposits" && (
                <div className="py-3">
                  <DataTable
                    title=""
                    columns={Deposits}
                    data={deposit || []}
                    pagination
                    highlightOnHover
                    dense
                    striped
                    responsive
                    customStyles={customStyles}
                  />
                </div>
              )}

              {entriesType === "interest" && (
                <div className="py-3">
                  <DataTable
                    title=""
                    columns={int}
                    data={interest || []}
                    pagination
                    highlightOnHover
                    dense
                    striped
                    responsive
                    customStyles={customStyles}
                  />
                </div>
              )}

              {entriesType === "Renewal" && (
                <div className="py-3">
                  <DataTable
                    title=""
                    columns={transactionColumn}
                    data={transaction || []}
                    pagination
                    highlightOnHover
                    dense
                    striped
                    responsive
                    customStyles={customStyles}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllEntries;
