// import React, { useState, useEffect } from "react";
// import Sidebar from "./Sidebar";
// import Header from "../components/Header";
// import axios from "axios";
// import Swal from "sweetalert2";
// import "react-toastify/dist/ReactToastify.css";
// import { ToastContainer, toast } from "react-toastify";
// import "../Style/Global_Classes.css";
// import { MdEventRepeat } from "react-icons/md";

// export default function TermDepositRenew() {
//   const [accountNo, setAccountNo] = useState("");
//   const [effetDepositPeriod, setEffectDepositPeriod] = useState(null);
//   const [effectMaturity, setEffectMaturity] = useState("");
//   const [effectMaturityDate, setEffectMaturityDate] = useState("");
//   const [effectPaid, setEffectPaid] = useState("");
//   const [amountValue, setAmountValue] = useState("");
//   const [interestR, setInterestR] = useState("");
//   const [depositDate, setDepositDate] = useState("");
//   const [depositPeriod, setDepositPeriod] = useState("");
//   const [maturityDate, setMaturityDate] = useState("");
//   const [maturityAmnt, setMaturityAmnt] = useState("");
//   const [intRate, setIntRate] = useState("");
//   const [effectAmnt, setEffectAmnt] = useState("");
//   const [balance, setBalance] = useState("");
//   const [eNo, setENo] = useState("");
//   const [depDate, setDepDate] = useState("");
//   const [detail, setDetail] = useState("");
//   const [credit, setCredit] = useState("");
//   const [matNo, setMatNo] = useState("");
//   const [eeNo, setEeNo] = useState("");
//   const [rdayMonth, setRdayMonth] = useState("");
//   const [fixed1Data, setFixed1Data] = useState([]);
//   const [fixed3Data, setFixed3Data] = useState([]);

//   const [formData, setFormData] = useState({
//     d_class: "",
//     member_id: "",
//     emp_no: "",
//     certificate_no: "",
//     accountNumber: "",
//     initial: "",
//     full_name: "",
//     join_date: "",
//     retire_on: "",
//     birth_date: "",
//     age: "",
//     startDateDemo: "",
//     opening_balance: "",
//     deposit_period: "",
//     InterestRate: "",
//     enddateDemo: "",
//     MaturityAmt: "",
//     interestAmount: "",
//   });

//   useEffect(() => {
//     setEffectAmnt(maturityAmnt);
//   }, [maturityAmnt]); // This will only update effectAmnt when maturityAmnt changes initially

//   const handleMaturityChange = (e) => {
//     setMaturityAmnt(e.target.value); // This updates maturityAmnt
//   };

//   const handleEffectChange = (e) => {
//     setEffectAmnt(e.target.value); // This updates effectAmnt independently
//   };

//   const intrate = maturityAmnt - amountValue;

//   const efDate = effectMaturityDate;
//   let formattedefDate = "";
//   if (efDate) {
//     const [year, month, day] = efDate.split("-");
//     formattedefDate = `${day}-${month}-${year}`;
//   }

//   const Bdate = formData.birth_date;
//   let formattedBirthDate = "";

//   if (Bdate) {
//     const [year, month, day] = Bdate.split("-");
//     formattedBirthDate = `${day}-${month}-${year}`;
//   }

//   const enddate = maturityDate;
//   let formattedendDate = "";

//   if (enddate) {
//     const datePart = enddate.split("T")[0];
//     const [year, month, day] = datePart.split("-");
//     formattedendDate = `${day}-${month}-${year}`;
//   }

//   const SDate = depositDate;
//   let formattedSDate = "";

//   if (SDate) {
//     const datePart = SDate.split("T")[0];

//     const [year, month, day] = datePart.split("-");

//     formattedSDate = `${day}-${month}-${year}`;
//   }

//   const RDate = formData.retire_on;
//   let formattedRDate = "";

//   if (RDate) {
//     const [year, month, day] = RDate.split("-");
//     formattedRDate = `${day}-${month}-${year}`;
//   }
//   const Jdate = formData.join_date;
//   let formattedJoinDate = "";
//   if (Jdate) {
//     const [year, month, day] = Jdate.split("-");
//     formattedJoinDate = `${day}-${month}-${year}`;
//   }

//   const [todaysDate, setTodaysDate] = useState("");

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         if (accountNo) {
//           const response = await axios.post(
//             `http://13.232.218.212:8000/get_renew_term_deposit/`,
//             {
//               acc_no: accountNo,
//               acc_type: "TERM DEPOSIT",
//             }
//           );
//           const data = response.data.data;
//           console.log("Updated Data :", data);

//           if (
//             Array.isArray(data.all_deposits) &&
//             data.all_deposits.length > 0
//           ) {
//             setFormData(data.all_deposits[0]);
//           } else {
//             console.log("No data available");
//           }

//           if (data.fixed3 && data.fixed3.length > 0) {
//             const depoDate = data.fixed3[0].DATE;
//             const amount = data.fixed3[0].AMOUNT;
//             const depoPeriod = data.fixed3[0].RPERIOD;
//             const intRate = data.fixed3[0].RINT_RATE;
//             const maturityDate = data.fixed3[0].M_DATE;
//             const maturityAmnt = data.fixed3[0].M_AMOUNT;
//             const rdayMonth = data.fixed3[0].RDAYMONTH;
//             setDepositDate(depoDate);
//             setAmountValue(amount);
//             setDepositPeriod(depoPeriod);
//             setInterestR(intRate);
//             setMaturityDate(maturityDate);
//             setMaturityAmnt(maturityAmnt);

//             setFixed3Data(data.fixed3[0]);
//             console.log("Fixed 3 Data:", fixed3Data);
//           }

//           if (data.fixed1 && data.fixed1.length > 0) {
//             const depDetails = data.fixed1[0];
//             setDepDate(depDetails.DATE);
//             setDetail(depDetails.DETAIL);
//             setCredit(depDetails.CREDIT);
//             setBalance(depDetails.BALANCE);
//             setMatNo(depDetails.M_NO);
//             setEeNo(depDetails.E_NO);
//             setFixed1Data(depDetails);
//             console.log("Fixed 1 Data:", fixed1Data);
//           }

//           const currentDate = new Date();
//           const day = currentDate.getDate().toString().padStart(2, "0");
//           const month = (currentDate.getMonth() + 1)
//             .toString()
//             .padStart(2, "0");
//           const year = currentDate.getFullYear();
//           setTodaysDate(`${day}-${month}-${year}`);
//         } else {
//           // Reset formData when accountNo is empty
//           setFormData({
//             d_class: "",
//             member_id: "",
//             emp_no: "",
//             certificate_no: "",
//             accountNumber: "",
//             initial: "",
//             full_name: "",
//             join_date: "",
//             retire_on: "",
//             birth_date: "",
//             age: "",
//             startDateDemo: "",
//             opening_balance: "",
//             deposit_period: "",
//             InterestRate: "",
//             enddateDemo: "",
//             MaturityAmt: "",
//             interestAmount: "",
//           });

//           setTodaysDate("");
//         }
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       }
//     };

//     fetchData();
//   }, [accountNo]);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         if (accountNo) {
//           const response = await axios.post(
//             `http://13.232.218.212:8000/Calculate_amt/`,
//             {
//               RDID: accountNo,
//               accountType: "TERM DEPOSIT",
//               deposit_period: effetDepositPeriod,
//               MonthlyDeposit: formData.MaturityAmt,
//             }
//           );
//           const data = response.data;
//           setEffectMaturity(data.maturity_amt);
//           setEffectMaturityDate(data.maturity_date);
//           setEffectPaid(data.interest_amount);
//         } else {
//           setEffectDepositPeriod("");
//           setEffectMaturityDate("");
//           setEffectMaturity("");
//           setEffectPaid("");
//         }
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       }
//     };

//     fetchData();
//   }, [effetDepositPeriod]);

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (!accountNo) {
//       return;
//     }
//     try {
//       const requestData = {
//         fixed3: fixed3Data,
//         fixed1: fixed1Data,
//       };

//       const response = await axios.post(
//         "http://13.232.218.212:8000/insert_term_deposit/",
//         requestData
//       );

//       const firstDeposit = formData;

//       // Check if 'remark' field exists and has the value 'Renewed'
//       if (firstDeposit.remark === "Renewed") {
//         Swal.fire({
//           icon: "info",
//           title: "Deposit Already Renewed",
//           text: `This ${accountNo} Deposit has already been renewed.`,
//           didOpen: () => {
//             Swal.getPopup().style.borderRadius = "25px";
//             const confirmButton = Swal.getConfirmButton();
//             confirmButton.classList.add("custom-swal-button");
//           },
//         });
//       } else {
//         Swal.fire({
//           icon: "success",
//           title: "Deposit Renewed Successfully!",
//           text: `Your ${accountNo} Deposit has been renewed successfully.`,
//           didOpen: () => {
//             Swal.getPopup().style.borderRadius = "25px";
//             const confirmButton = Swal.getConfirmButton();
//             confirmButton.classList.add("custom-swal-button");
//           },
//         });
//       }

//       // Reset form fields after processing
//       setAccountNo("");
//       setEffectDepositPeriod("");
//       setEffectMaturityDate("");
//       setEffectMaturity("");
//       setEffectPaid("");
//     } catch (err) {
//       console.error("An error occurred:", err);
//       // Swal.fire({
//       //   icon: "error",
//       //   title: "Error",
//       //   text: "An error occurred while submitting the form. Please try again later.",
//       //   didOpen: () => {
//       //     Swal.getPopup().style.borderRadius = "25px";
//       //     const confirmButton = Swal.getConfirmButton();
//       //     confirmButton.classList.add("custom-swal-button");
//       //   },
//       // });
//     }
//   };

//   return (
//     <>
//       <Sidebar />
//       <div className="container-fluid dashboard-area d-flex">
//         <div className="main-content p-4">
//           {/* Navbar */}
//           <Header />
//           {/* Heading1 Main */}
//           <div className="container d-flex text-start w-100 pb-1">
//             <div className="H1-Heading-Main">
//               <MdEventRepeat style={{ fontSize: "32px", color: "orange" }} />
//               &nbsp;Renew Term Deposit
//             </div>
//           </div>

//           <div className="container">
//             {/* Your first form code */}
//             <div className="row First-Main-Row  pt-3 mb-3">
//               {/* Basic Information  */}
//               <form>
//                 <div className="H2-Sub-Heading ">Basic Information</div>
//                 <div className="row">
//                   <div className="col-xl-1 col-lg-3 col-md-6 col-sm-6 text-start">
//                     <div className="">
//                       <label for="memberId" className="small-label">
//                         Class
//                       </label>
//                       <input
//                         type="text"
//                         id="d_class"
//                         name="d_class"
//                         class="form-control small-label bg-white"
//                         value={formData.d_class}
//                         readOnly
//                         min={0}
//                       />
//                     </div>
//                   </div>
//                   <div className="col-xl col-lg-3 col-md-6 col-sm-6 text-start">
//                     <label for="memberId" className="small-label">
//                       Member No
//                     </label>
//                     <div class="">
//                       <input
//                         type="text"
//                         id="memberId"
//                         name="member_id"
//                         class="form-control small-label"
//                         value={formData.member_id}
//                         min={0}
//                         required
//                       />
//                     </div>
//                   </div>
//                   <div className="col-xl col-lg-3 col-md-6 col-sm-6 text-start">
//                     <div class=" ">
//                       <label for="emp_no" className="small-label">
//                         Employee No
//                       </label>
//                       <input
//                         type="text"
//                         id="rdId"
//                         name="emp_no"
//                         class="form-control small-label bg-white"
//                         value={formData.emp_no}
//                         readOnly
//                         min={0}
//                       />
//                     </div>
//                   </div>
//                   <div className="col-xl col-lg-3 col-md-6 col-sm-6 text-start">
//                     <div className="no-outline-login">
//                       <label htmlFor="certificate_no" className="small-label">
//                         Certificate Number
//                       </label>
//                       <input
//                         type="text"
//                         className="form-control small-label"
//                         id="certificate_no"
//                         name="certificate_no"
//                         value={formData.certificate_no}
//                         min={0}
//                       />
//                     </div>
//                   </div>
//                   <div className="col-xl col-lg-3 col-md-6 col-sm-6 text-start">
//                     <div class="">
//                       <label for="accountNumber" className="small-label">
//                         Account No*
//                       </label>
//                       <input
//                         type="text"
//                         class="form-control small-label"
//                         id="accountNumber"
//                         name="accountNumber"
//                         min={0}
//                         value={accountNo}
//                         onChange={(e) => setAccountNo(e.target.value)}
//                         maxLength={20}
//                       />
//                     </div>
//                   </div>
//                 </div>
//                 <div className="row">
//                   <div className="col-xl-1 col-lg-2 col-md-6 col-sm-6 text-start">
//                     <label htmlFor="" className="small-label">
//                       Initial
//                     </label>
//                     <input
//                       name="initial"
//                       className="form-control small-label bg-white"
//                       value={formData.initial}
//                       readOnly
//                       // id="floatingInput"
//                     ></input>
//                   </div>
//                   <div className="col-xl col-lg-2 col-md-6 col-sm-6 text-start">
//                     <div class="no-outline-login">
//                       <label for="full_name" className="small-label">
//                         First Name
//                       </label>
//                       <input
//                         type="text"
//                         name="full_name"
//                         class="form-control small-label bg-white"
//                         value={formData.full_name}
//                         id="firstName"
//                         readOnly
//                       />
//                     </div>
//                   </div>
//                   <div className="col-xl col-lg-2 col-md-6 col-sm-6 text-start">
//                     <div class="row">
//                       <div className="col-sm-8">
//                         <label for="birth_date" className="small-label">
//                           Birth Date
//                         </label>
//                         <input
//                           type="text"
//                           class="form-control small-label bg-white"
//                           id="birth_date"
//                           value={formattedBirthDate}
//                           name="birthDate"
//                           readOnly
//                         />
//                       </div>
//                       <div className="col-sm-4">
//                         <label for="" className="small-label">
//                           Age
//                         </label>
//                         <input
//                           type="text"
//                           class="form-control small-label bg-white"
//                           id="age"
//                           minLength={2}
//                           maxLength={2}
//                           value={formData.age}
//                           name="age"
//                           readOnly
//                         />
//                       </div>
//                     </div>
//                   </div>
//                   <div className="col-xl col-lg-2 col-md-6 col-sm-6 text-start ">
//                     <label htmlFor="middleName" className="small-label">
//                       Join Date
//                     </label>
//                     <div>
//                       <input
//                         type="text"
//                         name="middleName"
//                         className="form-control small-label bg-white"
//                         value={formattedJoinDate}
//                         readOnly
//                       />
//                     </div>
//                   </div>
//                   <div className="col-xl col-lg-2 col-md-6 col-sm-6 text-start">
//                     <div class="">
//                       <label for="retire_on" className="small-label">
//                         Retire On
//                       </label>
//                       <input
//                         type="text"
//                         name="retire_on"
//                         value={formattedRDate}
//                         class="form-control small-label bg-white"
//                         id="last_name"
//                         readOnly
//                       />
//                     </div>
//                   </div>
//                 </div>

//                 {/* Renew Section */}
//                 <div className="Border-Black px-2 py-2 mb-3">
//                   <div className="row">
//                     <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 text-start">
//                       <label htmlFor="floatingInput" className="small-label">
//                         Deposit Date*
//                       </label>
//                       <div>
//                         <input
//                           type="text"
//                           className="form-control small-label"
//                           id="floatingInput"
//                           name="startDateDemo"
//                           value={formattedSDate}
//                           required
//                         />
//                       </div>
//                     </div>
//                     <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 text-start">
//                       <label htmlFor="floatingInput" className="small-label">
//                         Deposit Amount*
//                       </label>
//                       <div>
//                         <input
//                           type="text"
//                           className="form-control small-label"
//                           id="floatingInput"
//                           name="opening_balance"
//                           value={amountValue}
//                           min={0}
//                           required
//                         />
//                       </div>
//                     </div>

//                     <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 text-start">
//                       <label htmlFor="floatingInput" className="small-label">
//                         Deposit Period*
//                       </label>
//                       <div>
//                         <div className="row">
//                           <div className="col-sm-6 mb-2">
//                             <input
//                               className="form-control small-label"
//                               id="floatingInput"
//                               name="deposit_period"
//                               required
//                               value={depositPeriod}
//                               onChange={(e) => setDepositPeriod}
//                             />
//                           </div>
//                           <div className="col-sm-6">
//                             <label
//                               className="form-control small-label"
//                               id="floatingInput"
//                               style={{ width: "100%" }}
//                             >
//                               Days
//                             </label>
//                           </div>
//                         </div>
//                       </div>
//                     </div>

//                     {/* <div className="row"> */}
//                     <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 mb-2 text-start">
//                       <label htmlFor="floatingInput" className="small-label">
//                         Maturity On*
//                       </label>
//                       <div>
//                         <input
//                           type="text"
//                           className="form-control small-label"
//                           id="floatingInput"
//                           name="enddateDemo"
//                           value={formattedendDate}
//                         />
//                       </div>
//                     </div>
//                     <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 text-start">
//                       <label htmlFor="floatingInput1" className="small-label">
//                         Maturity Amount
//                       </label>
//                       <input
//                         type="text"
//                         className="form-control"
//                         id="floatingInput1"
//                         name="maturityAmount"
//                         value={maturityAmnt}
//                         onChange={handleMaturityChange}
//                         min={0}
//                       />
//                     </div>
//                     <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 text-start">
//                       <label htmlFor="floatingInput" className="small-label">
//                         Interest Amount*/Paid Date
//                       </label>
//                       <div>
//                         <input
//                           type="text"
//                           step={0.01}
//                           className="form-control small-label"
//                           id="floatingInput"
//                           name="interestAmount"
//                           value={balance}
//                           min={0}
//                         />
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="Border-Black px-2 py-2 mb-3">
//                   <div className="row">
//                     <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 text-start">
//                       <label htmlFor="floatingInput" className="small-label">
//                         Effect Date
//                       </label>
//                       <input
//                         type="text"
//                         className="form-control"
//                         id="floatingInput"
//                         name="effectDate"
//                         value={formattedendDate}
//                       />
//                     </div>
//                     <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 text-start">
//                       <label htmlFor="floatingInput2" className="small-label">
//                         Effect Amount
//                       </label>
//                       <input
//                         type="text"
//                         className="form-control"
//                         id="floatingInput2"
//                         name="effectAmount"
//                         value={effectAmnt}
//                         onChange={handleEffectChange}
//                         min={0}
//                       />
//                     </div>
//                     <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 text-start">
//                       <label htmlFor="floatingInput" className="small-label">
//                         Deposit Period*
//                       </label>
//                       <div>
//                         <div className="row">
//                           <div className="col-sm-6 mb-2">
//                             <input
//                               type="text"
//                               className="form-control"
//                               id="floatingInput"
//                               name="effectAmount"
//                               value={
//                                 isNaN(effetDepositPeriod)
//                                   ? ""
//                                   : effetDepositPeriod
//                               }
//                               onChange={(e) =>
//                                 setEffectDepositPeriod(parseInt(e.target.value))
//                               }
//                               min={0}
//                             />
//                           </div>
//                           <div className="col-sm-6">
//                             <label
//                               className="form-control small-label"
//                               id="floatingInput"
//                               style={{ width: "100%" }}
//                             >
//                               Days
//                             </label>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                     <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 text-start">
//                       <label htmlFor="floatingInput" className="small-label">
//                         Interest Rate*
//                       </label>
//                       <div>
//                         <input
//                           type="text"
//                           step={0.01}
//                           className="form-control small-label"
//                           id="floatingInput"
//                           name="InterestRate"
//                           value={intRate}
//                           min={0}
//                         />
//                       </div>
//                     </div>
//                     <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 text-start">
//                       <label
//                         htmlFor="floatingInput"
//                         className="small-label"
//                         style={{ color: "#ffc109" }}
//                       >
//                         Interest Pro. Amt
//                       </label>
//                       <input
//                         type="text"
//                         className="form-control"
//                         id="floatingInput"
//                         name="interestProvisionAmount"
//                         value={effectPaid}
//                         min={0}
//                       />
//                     </div>
//                   </div>

//                   <div className="row">
//                     <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 text-start">
//                       <label htmlFor="floatingInput" className="small-label">
//                         Renewed On
//                       </label>
//                       <input
//                         type="text"
//                         className="form-control"
//                         id="floatingInput"
//                         name="effectDate"
//                         value={todaysDate}
//                       />
//                     </div>
//                     <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 text-start">
//                       <label
//                         htmlFor="floatingInput"
//                         className="small-label"
//                         style={{ color: "blue" }}
//                       >
//                         New Maturity Date
//                       </label>
//                       <input
//                         type="text"
//                         className="form-control"
//                         id="floatingInput"
//                         name="closed_on"
//                         value={formattedefDate}
//                         min={0}
//                       />
//                     </div>
//                     <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 text-start">
//                       <label
//                         htmlFor="floatingInput"
//                         className="small-label"
//                         style={{ color: "blue" }}
//                       >
//                         New Maturity Amount
//                       </label>
//                       <input
//                         type="text"
//                         className="form-control"
//                         id="floatingInput"
//                         name="close_amt"
//                         value={effectMaturity}
//                       />
//                     </div>
//                     <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 text-start">
//                       <label
//                         htmlFor="floatingInput"
//                         className="small-label"
//                         style={{ color: "blue" }}
//                       >
//                         Interest Paid
//                       </label>
//                       <input
//                         type="text"
//                         className="form-control"
//                         id="floatingInput"
//                         name="interestpaid"
//                         value={effectPaid}
//                       />
//                     </div>
//                   </div>
//                 </div>
//               </form>
//               <div className="row mt-2 mb-3">
//                 <div className="col-sm d-flex justify-content-center">
//                   <button
//                     type="button"
//                     className="mt-2 mx-2"
//                     onClick={handleSubmit}
//                     style={{
//                       padding: "7px 25px 7px 25px",
//                       backgroundColor: "green",
//                       color: "white",
//                       fontWeight: "bold",
//                       border: "none",
//                       borderRadius: "7px",
//                       fontSize: "16px",
//                     }}
//                   >
//                     Renew
//                   </button>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <ToastContainer
//             position="bottom-center"
//             autoClose={5000}
//             hideProgressBar={false}
//             newestOnTop
//             closeOnClick
//             rtl={false}
//             pauseOnFocusLoss
//             draggable
//             pauseOnHover
//             theme="light"
//           />
//         </div>
//       </div>
//     </>
//   );
// }

import React, { useState, useEffect } from "react";
import Sidebar from "./Sidebar";
import Header from "../components/Header";
import axios from "axios";
import Swal from "sweetalert2";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import "../Style/Global_Classes.css";
import { MdEventRepeat } from "react-icons/md";

export default function TermDepositRenew() {
  const [accountNo, setAccountNo] = useState("");
  const [effetDepositPeriod, setEffectDepositPeriod] = useState(null);
  const [effectMaturity, setEffectMaturity] = useState("");
  const [effectMaturityDate, setEffectMaturityDate] = useState("");
  const [effectPaid, setEffectPaid] = useState("");
  const [amountValue, setAmountValue] = useState("");
  const [interestR, setInterestR] = useState("");
  const [depositDate, setDepositDate] = useState("");
  const [depositPeriod, setDepositPeriod] = useState("");
  const [maturityDate, setMaturityDate] = useState("");
  const [maturityAmnt, setMaturityAmnt] = useState("");
  const [intRate, setIntRate] = useState("");
  const [effectAmnt, setEffectAmnt] = useState("");
  const [balance, setBalance] = useState("");
  const [eNo, setENo] = useState("");
  const [depDate, setDepDate] = useState("");
  const [detail, setDetail] = useState("");
  const [credit, setCredit] = useState("");
  const [matNo, setMatNo] = useState("");
  const [eeNo, setEeNo] = useState("");
  const [fixed3_M_AMOUNT, setfixed3_M_AMOUNT] = useState([]);
  const [fixed3_ACNO, setfixed3_ACNO] = useState([]);
  const [fixed3_AMOUNT, setfixed3_AMOUNT] = useState([]);
  const [cFixed1Data, setFixed1Data] = useState([]);
  const [fixed3Data, setFixed3Data] = useState([]);
  const [firstDepoDate, setFirstDepoDate] = useState([]);
  const [firstAmount, setFirstAmount] = useState([]);

  const [formData, setFormData] = useState({
    d_class: "",
    member_id: "",
    emp_no: "",
    certificate_no: "",
    accountNumber: "",
    initial: "",
    full_name: "",
    join_date: "",
    retire_on: "",
    birth_date: "",
    age: "",
    startDateDemo: "",
    opening_balance: "",
    deposit_period: "",
    InterestRate: "",
    enddateDemo: "",
    MaturityAmt: "",
    interestAmount: "",
  });

  useEffect(() => {
    setEffectAmnt(maturityAmnt);
  }, [maturityAmnt]); // This will only update effectAmnt when maturityAmnt changes initially

  const handleMaturityChange = (e) => {
    setMaturityAmnt(e.target.value); // This updates maturityAmnt
  };

  const handleEffectChange = (e) => {
    setEffectAmnt(e.target.value); // This updates effectAmnt independently
  };

  const intrate = maturityAmnt - amountValue;

  const efDate = effectMaturityDate;
  let formattedefDate = "";
  if (efDate) {
    const [year, month, day] = efDate.split("-");
    formattedefDate = `${day}-${month}-${year}`;
  }

  const Bdate = formData.birth_date;
  let formattedBirthDate = "";

  if (Bdate) {
    const [year, month, day] = Bdate.split("-");
    formattedBirthDate = `${day}-${month}-${year}`;
  }

  const enddate = maturityDate;
  let formattedendDate = "";

  if (enddate) {
    const datePart = enddate.split("T")[0];
    const [year, month, day] = datePart.split("-");
    formattedendDate = `${day}-${month}-${year}`;
  }

  const SDate = firstDepoDate;
  let formattedSDate = "";

  if (SDate && typeof SDate === "string") {
    const datePart = SDate.split("T")[0];
    const [year, month, day] = datePart.split("-");
    formattedSDate = `${day}-${month}-${year}`;
  } else if (SDate instanceof Date) {
    const year = SDate.getFullYear();
    const month = String(SDate.getMonth() + 1).padStart(2, "0");
    const day = String(SDate.getDate()).padStart(2, "0");
    formattedSDate = `${day}-${month}-${year}`;
  }

  const RDate = formData.retire_on;
  let formattedRDate = "";

  if (RDate) {
    const [year, month, day] = RDate.split("-");
    formattedRDate = `${day}-${month}-${year}`;
  }
  const Jdate = formData.join_date;
  let formattedJoinDate = "";
  if (Jdate) {
    const [year, month, day] = Jdate.split("-");
    formattedJoinDate = `${day}-${month}-${year}`;
  }

  const [todaysDate, setTodaysDate] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (accountNo) {
          const response = await axios.post(
            `http://13.232.218.212:8000/get_renew_term_deposit/`,
            {
              acc_no: accountNo,
              acc_type: "TERM DEPOSIT",
            }
          );
          const data = response.data.data;
          //console.log('Updated Data :', data)

          if (data.firstDeposit && data.firstDeposit.length > 0) {
            const firstDepoDate = data.firstDeposit[0].DATE;
            const firstAmount = data.firstDeposit[0].AMOUNT;
            setFirstDepoDate(firstDepoDate);
            setFirstAmount(firstAmount);
            // console.log('First Deposit Info', firstDeposit)
          }

          if (
            Array.isArray(data.all_deposits) &&
            data.all_deposits.length > 0
          ) {
            setFormData(data.all_deposits[0]);
          } else {
            console.log("No data available");
          }

          if (data.fixed3 && data.fixed3.length > 0) {
            const depoDate = data.fixed3[0].DATE;
            const amount = data.fixed3[0].AMOUNT;
            const depoPeriod = data.fixed3[0].RPERIOD;
            const intRate = data.fixed3[0].RINT_RATE;
            const maturityDate = data.fixed3[0].M_DATE;
            const maturityAmnt = data.fixed3[0].M_AMOUNT;
            const rdayMonth = data.fixed3[0].RDAYMONTH;
            setDepositDate(depoDate);
            setAmountValue(amount);
            setDepositPeriod(depoPeriod);
            setInterestR(intRate);
            setMaturityDate(maturityDate);
            setMaturityAmnt(maturityAmnt);

            setFixed3Data(data.fixed3[0]);
            setfixed3_M_AMOUNT(data.cfixed3[0].M_AMOUNT);
            setfixed3_ACNO(data.cfixed3[0].ACNO);
            setfixed3_AMOUNT(data.cfixed3[0].AMOUNT);

            //setFixed3Data(data.fixed3[0])
            console.log("Fixed 3 Data:", fixed3Data);
          }

          if (data.fixed1 && data.fixed1.length > 0) {
            const depDetails = data.fixed1[0];
            setDepDate(depDetails.DATE);
            setDetail(depDetails.DETAIL);
            setCredit(depDetails.CREDIT);
            setBalance(depDetails.BALANCE);
            setMatNo(depDetails.M_NO);
            setEeNo(depDetails.E_NO);
            setFixed1Data(depDetails);
          }

          const currentDate = new Date();
          const day = currentDate.getDate().toString().padStart(2, "0");
          const month = (currentDate.getMonth() + 1)
            .toString()
            .padStart(2, "0");
          const year = currentDate.getFullYear();
          setTodaysDate(`${day}-${month}-${year}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [accountNo]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (accountNo) {
          const response = await axios.post(
            `http://13.232.218.212:8000/Calculate_amt/`,
            {
              RDID: accountNo,
              accountType: "TERM DEPOSIT",
              deposit_period: effetDepositPeriod,
              MonthlyDeposit: formData.MaturityAmt,
              start_date: maturityDate,
            }
          );
          const data = response.data;
          setEffectMaturity(data.maturity_amt);
          setEffectMaturityDate(data.maturity_date);
          setEffectPaid(data.interest_amount);
        } else {
          setEffectDepositPeriod("");
          setEffectMaturityDate("");
          setEffectMaturity("");
          setEffectPaid("");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [effetDepositPeriod]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!accountNo) {
      return;
    }
    try {
      const requestData = {
        ac_no: fixed3_ACNO,
        emp_no: eeNo,
        old_principle: fixed3_AMOUNT,
        start_date: maturityDate,
        mt_date: effectMaturityDate,
        int_rate: intRate,
        principle: fixed3_M_AMOUNT,
        period: depositPeriod,
        interest_amount: effectPaid,
        mt_amount: effectMaturity,

        // fixed3: fixed3Data,
        // fixed1: fixed1Data,
      };

      const response = await axios.post(
        "http://13.232.218.212:8000/manualRenew/",
        requestData
      );

      const firstDeposit = formData;

      // Check if 'remark' field exists and has the value 'Renewed'
      if (firstDeposit.remark === "Renewed") {
        Swal.fire({
          icon: "info",
          title: "Deposit Already Renewed",
          text: `This ${accountNo} Deposit has already been renewed.`,
          didOpen: () => {
            Swal.getPopup().style.borderRadius = "25px";
            const confirmButton = Swal.getConfirmButton();
            confirmButton.classList.add("custom-swal-button");
          },
        });
      } else {
        Swal.fire({
          icon: "success",
          title: "Deposit Renewed Successfully!",
          text: `Your ${accountNo} Deposit has been renewed successfully.`,
          didOpen: () => {
            Swal.getPopup().style.borderRadius = "25px";
            const confirmButton = Swal.getConfirmButton();
            confirmButton.classList.add("custom-swal-button");
          },
        });
      }

      // Reset form fields after processing
      setAccountNo("");
      setEffectDepositPeriod("");
      setEffectMaturityDate("");
      setEffectMaturity("");
      setEffectPaid("");
    } catch (err) {
      console.error("An error occurred:", err);
      // Swal.fire({
      //   icon: "error",
      //   title: "Error",
      //   text: "An error occurred while submitting the form. Please try again later.",
      //   didOpen: () => {
      //     Swal.getPopup().style.borderRadius = "25px";
      //     const confirmButton = Swal.getConfirmButton();
      //     confirmButton.classList.add("custom-swal-button");
      //   },
      // });
    }
  };

  return (
    <>
      <Sidebar />
      <div className="container-fluid dashboard-area d-flex">
        <div className="main-content p-4">
          {/* Navbar */}
          <Header />
          {/* Heading1 Main */}
          <div className="container d-flex text-start w-100 pb-1">
            <div className="H1-Heading-Main">
              <MdEventRepeat style={{ fontSize: "32px", color: "orange" }} />
              &nbsp;Renew Term Deposit
            </div>
          </div>

          <div className="container">
            {/* Your first form code */}
            <div className="row First-Main-Row  pt-3 mb-3">
              {/* Basic Information  */}
              <form>
                <div className="H2-Sub-Heading ">Basic Information</div>
                <div className="row">
                  <div className="col-xl-1 col-lg-3 col-md-6 col-sm-6 text-start">
                    <div className="">
                      <label for="memberId" className="small-label">
                        Class
                      </label>
                      <input
                        type="text"
                        id="d_class"
                        name="d_class"
                        class="form-control small-label bg-white"
                        value={formData.d_class}
                        readOnly
                        min={0}
                      />
                    </div>
                  </div>
                  <div className="col-xl col-lg-3 col-md-6 col-sm-6 text-start">
                    <label for="memberId" className="small-label">
                      Member No
                    </label>
                    <div class="">
                      <input
                        type="text"
                        id="memberId"
                        name="member_id"
                        class="form-control small-label"
                        value={formData.member_id}
                        min={0}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-xl col-lg-3 col-md-6 col-sm-6 text-start">
                    <div class=" ">
                      <label for="emp_no" className="small-label">
                        Employee No
                      </label>
                      <input
                        type="text"
                        id="rdId"
                        name="emp_no"
                        class="form-control small-label bg-white"
                        value={formData.emp_no}
                        readOnly
                        min={0}
                      />
                    </div>
                  </div>
                  <div className="col-xl col-lg-3 col-md-6 col-sm-6 text-start">
                    <div className="no-outline-login">
                      <label htmlFor="certificate_no" className="small-label">
                        Certificate Number
                      </label>
                      <input
                        type="text"
                        className="form-control small-label"
                        id="certificate_no"
                        name="certificate_no"
                        value={formData.certificate_no}
                        min={0}
                      />
                    </div>
                  </div>
                  <div className="col-xl col-lg-3 col-md-6 col-sm-6 text-start">
                    <div class="">
                      <label for="accountNumber" className="small-label">
                        Account No*
                      </label>
                      <input
                        type="text"
                        class="form-control small-label"
                        id="accountNumber"
                        name="accountNumber"
                        min={0}
                        value={accountNo}
                        onChange={(e) => setAccountNo(e.target.value)}
                        maxLength={20}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-1 col-lg-2 col-md-6 col-sm-6 text-start">
                    <label htmlFor="" className="small-label">
                      Initial
                    </label>
                    <input
                      name="initial"
                      className="form-control small-label bg-white"
                      value={formData.initial}
                      readOnly
                      // id="floatingInput"
                    ></input>
                  </div>
                  <div className="col-xl col-lg-2 col-md-6 col-sm-6 text-start">
                    <div class="no-outline-login">
                      <label for="full_name" className="small-label">
                        First Name
                      </label>
                      <input
                        type="text"
                        name="full_name"
                        class="form-control small-label bg-white"
                        value={formData.full_name}
                        id="firstName"
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-xl col-lg-2 col-md-6 col-sm-6 text-start">
                    <div class="row">
                      <div className="col-sm-8">
                        <label for="birth_date" className="small-label">
                          Birth Date
                        </label>
                        <input
                          type="text"
                          class="form-control small-label bg-white"
                          id="birth_date"
                          value={formattedBirthDate}
                          name="birthDate"
                          readOnly
                        />
                      </div>
                      <div className="col-sm-4">
                        <label for="" className="small-label">
                          Age
                        </label>
                        <input
                          type="text"
                          class="form-control small-label bg-white"
                          id="age"
                          minLength={2}
                          maxLength={2}
                          value={formData.age}
                          name="age"
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-xl col-lg-2 col-md-6 col-sm-6 text-start ">
                    <label htmlFor="middleName" className="small-label">
                      Join Date
                    </label>
                    <div>
                      <input
                        type="text"
                        name="middleName"
                        className="form-control small-label bg-white"
                        value={formattedJoinDate}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-xl col-lg-2 col-md-6 col-sm-6 text-start">
                    <div class="">
                      <label for="retire_on" className="small-label">
                        Retire On
                      </label>
                      <input
                        type="text"
                        name="retire_on"
                        value={formattedRDate}
                        class="form-control small-label bg-white"
                        id="last_name"
                        readOnly
                      />
                    </div>
                  </div>
                </div>

                {/* Renew Section */}
                <div className="Border-Black px-2 py-2 mb-3">
                  <div className="row">
                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 text-start">
                      <label htmlFor="floatingInput" className="small-label">
                        Deposit Date*
                      </label>
                      <div>
                        <input
                          type="text"
                          className="form-control small-label"
                          id="floatingInput"
                          name="startDateDemo"
                          value={formattedSDate}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 text-start">
                      <label htmlFor="floatingInput" className="small-label">
                        Deposit Amount*
                      </label>
                      <div>
                        <input
                          type="text"
                          className="form-control small-label"
                          id="floatingInput"
                          name="opening_balance"
                          value={firstAmount}
                          min={0}
                          onChange={(e) => setAmountValue(e.target.value)}
                          required
                        />
                      </div>
                    </div>

                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 text-start">
                      <label htmlFor="floatingInput" className="small-label">
                        Deposit Period*
                      </label>
                      <div>
                        <div className="row">
                          <div className="col-sm-6 mb-2">
                            <input
                              className="form-control small-label"
                              id="floatingInput"
                              name="deposit_period"
                              required
                              value={depositPeriod}
                              onChange={(e) => setDepositPeriod(e.target.value)}
                            />
                          </div>
                          <div className="col-sm-6">
                            <label
                              className="form-control small-label"
                              id="floatingInput"
                              style={{ width: "100%" }}
                            >
                              Days
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className="row"> */}
                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 mb-2 text-start">
                      <label htmlFor="floatingInput" className="small-label">
                        Last Maturity Date*
                      </label>
                      <div>
                        <input
                          type="text"
                          className="form-control small-label"
                          id="floatingInput"
                          name="enddateDemo"
                          value={formattedendDate}
                        />
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 text-start">
                      <label htmlFor="floatingInput1" className="small-label">
                        Last Maturity Amount
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="floatingInput1"
                        name="maturityAmount"
                        value={maturityAmnt}
                        onChange={handleMaturityChange}
                        min={0}
                      />
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 text-start">
                      <label htmlFor="floatingInput" className="small-label">
                        Interest Amount*/Paid Date
                      </label>
                      <div>
                        <input
                          type="text"
                          step={0.01}
                          className="form-control small-label"
                          id="floatingInput"
                          name="interestAmount"
                          // value={balance}
                          min={0}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Border-Black px-2 py-2 mb-3">
                  <div className="row">
                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 text-start">
                      <label htmlFor="floatingInput" className="small-label">
                        Effect Date
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="floatingInput"
                        name="effectDate"
                        value={formattedendDate}
                      />
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 text-start">
                      <label htmlFor="floatingInput2" className="small-label">
                        Effect Amount
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="floatingInput2"
                        name="effectAmount"
                        value={effectAmnt}
                        onChange={handleEffectChange}
                        min={0}
                      />
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 text-start">
                      <label htmlFor="floatingInput" className="small-label">
                        Deposit Period*
                      </label>
                      <div>
                        <div className="row">
                          <div className="col-sm-6 mb-2">
                            <input
                              type="text"
                              className="form-control"
                              id="floatingInput"
                              name="effectAmount"
                              value={
                                isNaN(effetDepositPeriod)
                                  ? ""
                                  : effetDepositPeriod
                              }
                              onChange={(e) =>
                                setEffectDepositPeriod(parseInt(e.target.value))
                              }
                              min={0}
                            />
                          </div>
                          <div className="col-sm-6">
                            <label
                              className="form-control small-label"
                              id="floatingInput"
                              style={{ width: "100%" }}
                            >
                              Days
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 text-start">
                      <label htmlFor="floatingInput" className="small-label">
                        Interest Rate*
                      </label>
                      <div>
                        <input
                          type="text"
                          step={0.01}
                          className="form-control small-label"
                          id="floatingInput"
                          name="InterestRate"
                          value={intRate}
                          min={0}
                        />
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 text-start">
                      <label
                        htmlFor="floatingInput"
                        className="small-label"
                        style={{ color: "#ffc109" }}
                      >
                        Interest Pro. Amt
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="floatingInput"
                        name="interestProvisionAmount"
                        value={effectPaid}
                        min={0}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 text-start">
                      <label htmlFor="floatingInput" className="small-label">
                        Renewed On
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="floatingInput"
                        name="effectDate"
                        value={todaysDate}
                      />
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 text-start">
                      <label
                        htmlFor="floatingInput"
                        className="small-label"
                        style={{ color: "blue" }}
                      >
                        New Maturity Date
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="floatingInput"
                        name="closed_on"
                        value={formattedefDate}
                        min={0}
                      />
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 text-start">
                      <label
                        htmlFor="floatingInput"
                        className="small-label"
                        style={{ color: "blue" }}
                      >
                        New Maturity Amount
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="floatingInput"
                        name="close_amt"
                        value={effectMaturity}
                      />
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 text-start">
                      <label
                        htmlFor="floatingInput"
                        className="small-label"
                        style={{ color: "blue" }}
                      >
                        Interest Paid
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="floatingInput"
                        name="interestpaid"
                        value={effectPaid}
                      />
                    </div>
                  </div>
                </div>
              </form>
              <div className="row mt-2 mb-3">
                <div className="col-sm d-flex justify-content-center">
                  <button
                    type="button"
                    className="mt-2 mx-2"
                    onClick={handleSubmit}
                    style={{
                      padding: "7px 25px 7px 25px",
                      backgroundColor: "green",
                      color: "white",
                      fontWeight: "bold",
                      border: "none",
                      borderRadius: "7px",
                      fontSize: "16px",
                    }}
                  >
                    Renew
                  </button>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </div>
      </div>
    </>
  );
}
