import React, { useState, useEffect } from "react";
import Sidebar from "./Sidebar";
import Header from "../components/Header";
import axios from "axios";
import Swal from "sweetalert2";
import "../Style/Global_Classes.css";
import "../Style/I_Form.css";

export default function ShareHolderColumnI() {
  let [memberId, setMemberId] = useState("");
  let [membershipNo, setmembershipNo] = useState("");
  let [openingDate, setopeningDate] = useState("");
  let [monthlyContribution, setmonthlyContribution] = useState("");
  let [fullName, setfullName] = useState("");
  let [address, setaddress] = useState("");
  let [permanentAddress, setpermanentAddress] = useState("");
  let [occupation, setoccupation] = useState("");
  let [ageAndDateOfAdimission, setageAndDateOfAdimission] = useState("");
  let [nameAndAddOfNominee, setnameAndAddOfNominee] = useState("");
  let [dateOfNomination, setdateOfNomination] = useState("");
  let [reasonOfCessation, setreasonOfCessation] = useState("");
  let [remarks, setremarks] = useState("");
  let [dateOfCessationMembership, setdateOfCessationMembership] = useState("");

  let [TableInfo, setTableInfo] = useState("");
  let [tableInfoArr, setTableInfoArr] = useState([]);

  async function ForMemberDetail() {
    if (memberId === "") {
      return;
    }
    try {
      let response = await axios.get(
        `http://13.232.218.212:8000/all_memberdata/${memberId}/`
      );

      if (response && response.data.members.length > 0) {
        setmembershipNo(response.data.members[0].member_id);
        setopeningDate(response.data.members[0].opening_date_demo);
        setmonthlyContribution(response.data.members[0].monthly_contribution);
        setfullName(response.data.members[0].full_name);
        setaddress(response.data.members[0].resident_address);
        setpermanentAddress(response.data.members[0].resident_address);
        setoccupation("");
        setageAndDateOfAdimission(response.data.members[0].age.toString());
        setnameAndAddOfNominee(response.data.members[0].nominee_name);
        setdateOfNomination("");
        setdateOfCessationMembership("");
        setreasonOfCessation("");
        setremarks("");
      } else {
        setTableInfo("");
        setTableInfoArr([]);
        setmembershipNo("");
        setopeningDate("");
        setmonthlyContribution("");
        setfullName("");
        setaddress("");
        setpermanentAddress("");
        setoccupation("");
        setageAndDateOfAdimission("");
        setnameAndAddOfNominee("");
        setdateOfNomination("");
        setreasonOfCessation("");
        setremarks("");
      }
    } catch (error) {
      setTableInfo("");
      setTableInfoArr([]);
      setmembershipNo("");
      setopeningDate("");
      setmonthlyContribution("");
      setfullName("");
      setaddress("");
      setpermanentAddress("");
      setoccupation("");
      setageAndDateOfAdimission("");
      setnameAndAddOfNominee("");
      setdateOfNomination("");
      setreasonOfCessation("");
      setremarks("");
      console.log(error);
    }
  }

  async function forTable() {
    try {
      let APIResponse = await axios.post("http://13.232.218.212:8000/shares/", {
        member_id: memberId,
      });

      if (
        APIResponse.data &&
        APIResponse.data.data &&
        APIResponse.data.data.length > 0
      ) {
        setTableInfo(APIResponse.data);
        setTableInfoArr(APIResponse.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (memberId != "") {
      ForMemberDetail();
      forTable();
    }
  }, [memberId]);

  const handlePrint = () => {
    let printContents = document.getElementById("printable").innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload();
  };

  const ODate = openingDate;
  let formattedOpeningDate = "";
  if (ODate) {
    const [year, month, day] = ODate.split("-");
    formattedOpeningDate = `${day}-${month}-${year}`;
  }

  const NDate = dateOfNomination;
  let formattedNominationDate = "";
  if (NDate) {
    const [year, month, day] = NDate.split("-");
    formattedNominationDate = `${day}-${month}-${year}`;
  }

  return (
    <>
      <Sidebar />
      <div className="container-fluid dashboard-area d-flex">
        <div className="main-content p-4">
          <Header />
          <div id="printable">
            <div
              className="member-id-input-box text-start mb-5 d-flex align-items-center "
              style={{ gap: "1em" }}
            >
              <span>Member Id : </span>

              <input
                type="text"
                className="member-id-number  form-control  no-outline-login w-25"
                value={memberId}
                onChange={(e) => {
                  const numericValue = e.target.value.replace(/[^0-9.]/g, "");
                  setMemberId(numericValue);
                }}
              />
            </div>
            {memberId !== "" ? (
              <>
                <div className="container d-flex text-start w-100 pb-1 ">
                  <div className=" d-flex flex-column justify-content-center mt-1  w-100 BPC-Employee-heading-box">
                    <h2 className="text-center mb-2">
                      The BPC Employee's Co-op. Credit Society Ltd.
                    </h2>
                    <h3 className="text-center">FORM 'l'</h3>
                    <p className="text-center">[See rule 32 and 65]</p>
                    <h3 className="text-center">Register of Members</h3>
                    <p className="text-center">
                      (Section 38(1) of the Maharashtra Co-operative Societies
                      Act. 1960)
                    </p>
                  </div>
                </div>
                <hr />

                <div className="container">
                  <div className="row  pt-3 mb-1">
                    <div className="row mb-3">
                      <div className="col-6 col-md-6 text-start d-flex justify-content-between mb-md-0 mb-2">
                        <span className="first-column">1. MemberShip No. </span>
                        <span>:</span>
                      </div>
                      <div className="col-6 col-md-6 text-start ">
                        <input
                          type="number"
                          readOnly
                          name="membershipNo"
                          value={membershipNo}
                          readonly
                          className="  share-holder-column-input"
                        />
                      </div>
                    </div>

                    <div className="row  mb-3">
                      <div className="col-6 col-md-6 text-start d-flex justify-content-between mb-md-0 mb-2">
                        <span className="first-column">2. Opening Date </span>
                        <span>:</span>
                      </div>
                      <div className="col-6 col-md-6 text-start">
                        <input
                          type="text"
                          readOnly
                          name="dateOfAdmission"
                          value={formattedOpeningDate}
                          readonly
                          className=" share-holder-column-input"
                        />
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-6 col-md-6 text-start d-flex justify-content-between mb-md-0 mb-2">
                        <span className="first-column">
                          3. Monthly Contribution{" "}
                        </span>
                        <span>:</span>
                      </div>
                      <div className="col-6 col-md-6 text-start">
                        <input
                          type="text"
                          readOnly
                          name="dateOfEnteranceFee"
                          value={monthlyContribution}
                          readonly
                          className="  share-holder-column-input"
                        />
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-6 col-md-6 text-start d-flex justify-content-between mb-md-0 mb-2">
                        <span className="first-column">4. Full Name </span>
                        <span>:</span>
                      </div>
                      <div className="col-6 col-md-6 text-start">
                        <input
                          type="text"
                          readOnly
                          name="fullName"
                          value={fullName}
                          className="  share-holder-column-input"
                        />
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-6 col-md-6 text-start d-flex justify-content-between mb-md-0 mb-2">
                        <span className="first-column">5. Address </span>
                        <span>:</span>
                      </div>
                      <div className="col-6 col-md-6 text-start">
                        <input
                          type="text"
                          readOnly
                          name="address"
                          value={address}
                          readonly
                          className="  share-holder-column-input"
                        />
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-6 col-md-6 text-start d-flex justify-content-between mb-md-0 mb-2">
                        <span className="first-column">Permanent Address </span>
                        <span>:</span>
                      </div>
                      <div className="col-6 col-md-6 text-start">
                        <input
                          type="text"
                          readOnly
                          name="permanentAddress"
                          value={permanentAddress}
                          readonly
                          className="  share-holder-column-input"
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-6 col-md-6 text-start d-flex justify-content-between mb-md-0 mb-2">
                        <span className="first-column">6. Occupation </span>
                        <span>:</span>
                      </div>
                      <div className="col-6 col-md-6 text-start">
                        <input
                          type="text"
                          readOnly
                          name="occupation"
                          value={occupation}
                          readonly
                          className="  share-holder-column-input"
                          style={{ borderBottom: "1px solid grey" }}
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-6 col-md-6 text-start d-flex justify-content-between mb-md-0 mb-2">
                        <span className="first-column">
                          7. Age on the Date of Admission{" "}
                        </span>
                        <span>:</span>
                      </div>
                      <div className="col-6 col-md-6 text-start">
                        <input
                          type="number"
                          readOnly
                          name="ageAndDateOfMission"
                          value={ageAndDateOfAdimission}
                          readonly
                          className="  share-holder-column-input"
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-6 col-md-6 text-start d-flex justify-content-between mb-md-0 mb-2">
                        <span className="first-column">
                          8. Name & add. of Nominee Under Sec. 30(1){" "}
                        </span>
                        <span>:</span>
                      </div>
                      <div className="col-6 col-md-6 text-start">
                        <input
                          type="text"
                          readOnly
                          name="nameAndAddOfNominee"
                          value={nameAndAddOfNominee}
                          readonly
                          className="  share-holder-column-input"
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-6 col-md-6 text-start d-flex justify-content-between mb-md-0 mb-2">
                        <span className="first-column">
                          9. Date of Nomination{" "}
                        </span>
                        <span>:</span>
                      </div>
                      <div className="col-6 col-md-6 text-start ">
                        <input
                          type="text"
                          readOnly
                          name="dateOfNomination"
                          value={formattedNominationDate}
                          readonly
                          className=" share-holder-column-input"
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-6 col-md-6 text-start d-flex justify-content-between mb-md-0 mb-2">
                        <span className="first-column">
                          10. Date of Cessation of Membership{" "}
                        </span>
                        <span>:</span>
                      </div>
                      <div className="col-6 col-md-6 text-start ">
                        <input
                          type="text"
                          readOnly
                          name="dateOfCessationMembership"
                          value={dateOfCessationMembership}
                          readonly
                          className=" share-holder-column-input"
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-6 col-md-6 text-start d-flex justify-content-between mb-md-0 mb-2">
                        <span className="first-column">
                          11. Reason for Censsation{" "}
                        </span>
                        <span>:</span>
                      </div>
                      <div className="col-6 col-md-6 text-start ">
                        <input
                          type="text"
                          readOnly
                          name="reasonOfCessation"
                          value={reasonOfCessation}
                          readonly
                          className=" share-holder-column-input"
                          style={{ borderBottom: "1px solid grey" }}
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-6 col-md-6 text-start d-flex justify-content-between mb-md-0 mb-2">
                        <span className="first-column">12. Remarks </span>
                        <span>:</span>
                      </div>
                      <div className="col-6 col-md-6 text-start ">
                        <input
                          type="text"
                          readOnly
                          name="remarks"
                          value={remarks}
                          readonly
                          className=" share-holder-column-input"
                          style={{ borderBottom: "1px solid grey" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <hr />
                <h3 className="text-center my-2">Particulars of share held</h3>
                <div className="for-table-print">
                  <table class="table" border="1">
                    <thead>
                      <tr className="table-row">
                        <th className="table-heading" rowSpan="2" scope="col">
                          Date
                        </th>
                        <th className="table-heading" rowSpan="2" scope="col">
                          Cash Book Folio
                        </th>
                        <th className="table-heading" colspan="2" scope="col">
                          Application Allotment
                        </th>
                        <th className="table-heading" rowSpan="2" scope="col">
                          Amount received on <br />
                          1st call <br /> 2nd call
                        </th>
                        <th className="table-heading" rowSpan="2" scope="col">
                          Total Amount Received
                        </th>
                        <th className="table-heading" rowSpan="2" scope="col">
                          No. of Shares Held
                        </th>
                        <th className="table-heading" rowSpan="2" scope="col">
                          Serial No. of Shares Certificate No.
                        </th>
                      </tr>
                      <tr>
                        <th className="table-heading">From</th>
                        <th className="table-heading">TO</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableInfoArr.length > 0 &&
                        tableInfoArr.map((element) => {
                          return (
                            <tr className="table-row">
                              <td className="table-data">
                                <span style={{ whiteSpace: "nowrap" }}>
                                  {(() => {
                                    const date = new Date(
                                      element.purchase_date
                                    );
                                    if (isNaN(date.getTime())) {
                                      return "";
                                    }
                                    return date
                                      .toLocaleDateString("en-GB", {
                                        day: "2-digit",
                                        month: "2-digit",
                                        year: "numeric",
                                      })
                                      .replace(/\//g, "-");
                                  })()}
                                </span>
                              </td>
                              <td className="table-data">-</td>
                              <td className="table-data">-</td>
                              <td className="table-data">-</td>
                              <td className="table-data">
                                {element.share_price}
                              </td>
                              <td className="table-data">
                                {element.share_price}
                              </td>
                              <td className="table-data">
                                {element.no_of_share}
                              </td>
                              <td className="table-data">-</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
                <hr />

                <button
                  className="btn btn-primary print-btn"
                  onClick={handlePrint}
                >
                  Print
                </button>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}
