import React, { useState } from "react";
import Sidebar from "./Sidebar";
import Header from "../components/Header";
import axios from "axios";
import DataTable from "react-data-table-component";

const CompareList = () => {
  const [demandedFile, setDemandedFile] = useState(null);
  const [bpclFile, setBpclFile] = useState(null);
  const [data, setData] = useState("");

  const columns = [
    { name: "Member ID", selector: "Member ID", sortable: true },
    { name: "RDID/Loan No", selector: "RDID/Loan No", sortable: true },
    { name: "Full Name", selector: "Full Name", sortable: true },
    { name: "Account Type", selector: "Account Type", sortable: true },
    {
      name: "Amount Subtraction",
      selector: "Amount_Subtraction",
      sortable: true,
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "48px",
        textAlign: "center",
      },
    },
    headCells: {
      style: {
        minHeight: "40px",
        backgroundColor: "#4db3c8",
        fontSize: "14px",
        fontWeight: "400",
        color: "white",
        textAlign: "center",
      },
    },
  };

  const handleDemandedFileChange = (event) => {
    // Logic to handle demanded file selection
    const file = event.target.files[0];
    setDemandedFile(file);
  };

  const handleBpclFileChange = (event) => {
    // Logic to handle BPCL file selection
    const file = event.target.files[0];
    setBpclFile(file);
  };

  const handleFileComparison = async () => {
    // Logic to submit files for comparison using an API
    if (demandedFile && bpclFile) {
      const formData = new FormData();
      formData.append("demandedFile", demandedFile);
      formData.append("bpclFile", bpclFile);

      try {
        const response = await axios.post(
          "http://13.232.218.212:8000/comparelist/",
          formData
        );
        console.log("Demanded List:", demandedFile);
        console.log("BPCL List:", bpclFile);

        const responseData = response.data["result"];
        setData(responseData);

        console.log(responseData);
      } catch (error) {
        console.error("Error during file comparison:", error);
        // Handle the error accordingly
      }
    } else {
      alert("Please select both demanded and BPCL files.");
    }
  };

  return (
    <>
      <Sidebar />
      <div className="container-fluid dashboard-area d-flex">
        <div className="main-content p-4">
          {/* Navbar */}
          <Header />
          <div className="container d-flex text-start w-100 pb-1">
            <div className="row w-100">
              <div className="col-6">
                <h3 style={{ fontWeight: "bold", color: "dodgerblue" }}>
                  Compare Demand List
                </h3>
              </div>
            </div>
          </div>
          <div>
            {/* Input for Demanded List */}
            <div className="d-flex justify-content-center">
              <div className="text-start">
                <span>
                  <label htmlFor="demandedFile">
                    Select Demanded List :&nbsp;
                  </label>
                </span>
                <span>
                  <input
                    type="file"
                    id="demandedFile"
                    accept=".xls, .xlsx, .csv"
                    onChange={handleDemandedFileChange}
                  />
                </span>
              </div>

              {/* Input for BPCL List */}
              <div className="text-start">
                <span>
                  <label htmlFor="bpclFile">Select BPCL List :&nbsp;</label>
                </span>
                <span>
                  <input
                    type="file"
                    id="bpclFile"
                    accept=".xls, .xlsx, .csv"
                    onChange={handleBpclFileChange}
                  />
                </span>
              </div>
              {/* Submit Button */}
              <span
                className="btn btn-success"
                style={{ color: "white" }}
                onClick={handleFileComparison}
              >
                Compare Files
              </span>
            </div>
          </div>

          <div className="py-3">
            <DataTable
              data={data}
              columns={columns}
              customStyles={customStyles}
              striped
              dense
              pagination
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CompareList;
