import axios from "axios";
import React, { useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";

export default function SetNewPassword() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const navigate = useNavigate();
  const location = useLocation();
  const memberId = location.state?.memberId;

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSetPassword = async () => {
    try {
      if (password !== confirmPassword) {
        toast.error("Passwords do not match. Please try again.");
        return;
      }

      const response = await axios.post(
        "http://13.232.218.212:8000/forgot_password_change/",
        {
          member_id: memberId,
          new_password: password,
        }
      );

      if (response.data.success) {
        toast.success("Password changed successfully!");
        setTimeout(() => {
          navigate("/");
        }, 2000);
      } else {
        toast.error("Failed to change password. Please try again.");
      }
    } catch (error) {
      console.error("Error setting new password:", error);
      toast.error("An unexpected error occurred. Please try again later.");
    }
  };

  return (
    <div>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div
        className="container-fluid forgot-password-parent-container"
        style={{ backgroundColor: "white", height: "100vh" }}
      >
        <div className="row">
          <div
            className="col-sm-12 d-flex justify-content-center align-items-center "
            style={{ paddingTop: "10rem" }}
          >
            <div
              className="forgot-password-inner-div"
              style={{
                backgroundColor: "#027CC6",
                padding: "30px 50px 30px 50px",
                borderRadius: "10px",
              }}
            >
              <strong
                className=""
                style={{
                  paddingTop: "20px",
                  fontSize: "1.4rem",
                  color: "white",
                }}
              >
                Change Password
              </strong>
              <form>
                <div className="w-100 mt-3">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label mt-2 px-2 d-flex text-start"
                  >
                    <strong style={{ color: "white", fontSize: "0.9em" }}>
                      Password
                    </strong>
                  </label>
                  <div className="position-relative">
                    <input
                      placeholder="Password"
                      type="password"
                      className="form-control no-outline-login forgot-password-login"
                      value={password}
                      onChange={handlePasswordChange}
                    />
                  </div>
                </div>

                <div className="w-100 mt-3">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label mt-2 px-2 d-flex text-start"
                  >
                    <strong style={{ color: "white", fontSize: "0.9em" }}>
                      Confirm Password
                    </strong>
                  </label>
                  <div className="position-relative">
                    <input
                      placeholder="Confirm Password"
                      type="password"
                      className="form-control no-outline-login forgot-password-login"
                      value={confirmPassword}
                      onChange={handleConfirmPasswordChange}
                    />
                  </div>
                </div>

                <div className="my-4">
                  <button
                    type="button"
                    className="w-100 reset-password-button"
                    onClick={handleSetPassword}
                  >
                    Change Password
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
