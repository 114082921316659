import axios from "axios";
import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import "../Style/ForgotPassword.css";

export default function ForgotPassword(props) {
  const [whatsappNo, setWhatsappNo] = useState("");
  const [storedOtp, setStoredOtp] = useState("");
  const [memberId, setMemberId] = useState("");
  const [otp, setOtp] = useState("");

  let navigate = useNavigate();

  const handleWhatsappNoSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "http://13.232.218.212:8000/forgot_password_send_otp/",
        {
          mobile_no: whatsappNo,
          // member_id: member_id,
        }
      );

      const apiOtp = response.data.otp;
      const member_id = response.data.member_id;
      setStoredOtp(apiOtp);
      setMemberId(member_id);

      props.forSettingForgotPasswordWhatsapp(whatsappNo);

      toast.success("OTP sent successfully to your Whatsapp No!");
      navigate("/otp-verfication", {
        state: { storedOtp: apiOtp, memberId: member_id },
      });
    } catch (error) {
      console.error("Error sending email:", error);
      toast.error(
        "User not found. Please check the Whatsapp No and try again."
      );
    }
  };

  return (
    <div>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div
        className="container-fluid forgot-password-parent-container"
        style={{ backgroundColor: "white", height: "100vh" }}
      >
        <div className="row">
          <div
            className="col-sm-12 d-flex justify-content-center align-items-center "
            style={{ paddingTop: "10rem" }}
          >
            <div
              className="forgot-password-inner-div"
              style={{
                backgroundColor: "#027CC6",
                padding: "30px 50px 30px 50px",
                borderRadius: "10px",
              }}
            >
              <strong
                className=""
                style={{
                  paddingTop: "20px",
                  fontSize: "1.4rem",
                  color: "white",
                }}
              >
                Reset Password
              </strong>
              {!otp && (
                <form onSubmit={handleWhatsappNoSubmit}>
                  <div className=" mt-3">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label mt-2 px-2 d-flex text-start"
                    >
                      <strong style={{ color: "white", fontSize: "0.9em" }}>
                        Whatsapp No.
                      </strong>
                    </label>
                    <input
                      placeholder="Whatsapp No."
                      type="text"
                      className="form-control no-outline-login forgot-password-login"
                      name="whatsappNo"
                      value={whatsappNo}
                      onChange={(e) => setWhatsappNo(e.target.value)}
                    />
                  </div>
                  <div className="forgot-password-msg-div">
                    We’ll send a verification code to this Whatsapp number if it
                    matches an existing account.
                  </div>

                  <div className="my-4">
                    <button
                      type="button"
                      className=" w-100 reset-password-button"
                      onClick={handleWhatsappNoSubmit}
                    >
                      Reset Password
                    </button>
                  </div>
                </form>
              )}
            </div>
          </div>
          <div className="col-12 py-3">
            <Link to="/">
              <button
                type="btn"
                style={{
                  background: "none",
                  textDecoration: "underline",
                  color: "dodgerblue",
                  fontWeight: "700",
                  border: "none",
                  padding: "10px",
                }}
              >
                Remembered Password? Login Now
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
