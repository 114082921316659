import React, { useState, useEffect } from "react";
import Sidebar from "./Sidebar";
import Header from "../components/Header";
import DataTable from "react-data-table-component";
import axios from "axios";
import "../Style/Global_Classes.css";

const MemberStatus = () => {
  const [selectedLoan, setSelectedLoan] = useState("Deposits Detail");
  const [data, setData] = useState("");
  const [depositData, setDepositData] = useState([]);
  const [loanData, setLoanData] = useState([]);
  const [shareData, setShareData] = useState([]);
  const [guarantee, setGuarantee] = useState([]);
  const [guaranteeTo, setGuaranteeTo] = useState([]);
  const [name, setName] = useState("");
  const [joinDate, setJoinDate] = useState("");
  const [retireDate, setRetireDate] = useState("");
  const [IFSCCode, setIFSCCode] = useState("");
  const [bankAccountNo, setBankAccountNo] = useState("");
  const [bankName, setBankName] = useState("");
  const [branchName, setBranchName] = useState("");
  const [mDate, setMDate] = useState("");
  const [mNO, setMNO] = useState("");
  const [birth, setBirth] = useState("");
  const [division, setDivision] = useState("");

  const [memberId, setMemberId] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (memberId) {
          const response = await axios.post(
            "http://13.232.218.212:8000/member_status/",
            {
              id: memberId,
            }
          );
          const responseData = response.data || "";
          const DepositData = responseData.member_data.deposits;
          const LoanData = responseData.member_data.loans;
          const ShareData = responseData.member_data.shares;
          const Guaranters = responseData.member_data.gaurantees;
          const GuarantersTo = responseData.member_data.gaurantees_to;
          const Name = responseData.member_data.full_name;
          const Join = responseData.member_data.join_date;
          const retire = responseData.member_data.retire_on;

          setIFSCCode(responseData.member_data.IFSC_code || "nan");
          setBankAccountNo(responseData.member_data.bank_ac_no || "nan");
          setBankName(responseData.member_data.bank_name || "nan");
          setBranchName(responseData.member_data.branch_name || "nan");
          setMDate(responseData.member_data.opening_date);
          setMNO(responseData.member_data.m_no);
          setBirth(responseData.member_data.birth_date);
          setDivision(responseData.member_data.division);

          setLoanData(LoanData);
          setDepositData(DepositData);
          setData(responseData);
          setShareData(ShareData);
          setGuarantee(Guaranters);
          setGuaranteeTo(GuarantersTo);
          setName(Name);
          setJoinDate(Join);
          setRetireDate(retire);
        } else {
          setLoanData([]);
          setDepositData([]);
          setShareData([]);
          setGuarantee([]);
          setGuaranteeTo([]);
          setName("");
          setJoinDate("");
          setBankAccountNo("");
          setBankName("");
          setBranchName("");
          setIFSCCode("");
          setMDate("");
          setRetireDate("");
          setMNO("");
          setBirth("");
          setDivision("");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [selectedLoan, memberId]);

  const loanColumns = [
    {
      name: "Ac Type",
      selector: (row) => row.ac_type,
      sortable: true,
      // width: "160px",
    },
    {
      name: "Ac No",
      selector: (row) => row.loan_no,
      sortable: true,
      // width: "100px",
    },
    {
      name: "Loan Date",
      selector: (row) => row.loan_date,
      sortable: true,
      cell: (row) => {
        const loanDate = new Date(row.loan_date);
        if (isNaN(loanDate.getTime())) {
          return "--"; // Return empty string if the date is invalid
        }
        const formattedDate = `${loanDate
          .getDate()
          .toString()
          .padStart(2, "0")}-${(loanDate.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${loanDate.getFullYear()}`;
        return formattedDate;
      },
    },
    { name: "Loan Amt", selector: (row) => row.loan_amnt, sortable: true },
    {
      name: "Period",
      selector: (row) => {
        const period = row.period ? row.period : "-";
        const unit = row.unit ? row.unit : "Months";

        return `${period} ${unit}`;
      },
      sortable: true,
    },
    {
      name: "Interest",
      selector: (row) => row.int_rate,
      sortable: true,
      width: "70px",
    },
    {
      name: "Due Date",
      selector: (row) => row.ln_due_dt,
      sortable: true,
      cell: (row) => {
        const loanDate = new Date(row.ln_due_dt);
        if (isNaN(loanDate.getTime())) {
          return "--"; // Return empty string if the date is invalid
        }
        const formattedDate = `${loanDate
          .getDate()
          .toString()
          .padStart(2, "0")}-${(loanDate.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${loanDate.getFullYear()}`;
        return formattedDate;
      },
    },
    {
      name: "Balance",
      selector: (row) => row.balance,
      sortable: true,
      // width: "100px",
    },
    {
      name: "Effect Date",
      selector: (row) => row.effect_dt,
      sortable: true,
      cell: (row) => {
        const loanDate = new Date(row.effect_dt);
        if (isNaN(loanDate.getTime())) {
          return "--"; // Return empty string if the date is invalid
        }
        const formattedDate = `${loanDate
          .getDate()
          .toString()
          .padStart(2, "0")}-${(loanDate.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${loanDate.getFullYear()}`;
        return formattedDate;
      },
    },
    {
      name: "Op",
      selector: (row) => (row.operator ? row.operator : "--"),
      sortable: true,
    },
  ];

  const Deposits = [
    {
      name: "Account Type",
      selector: (row) => row.Account_type,
      sortable: true,
      width: "160px",
    },
    {
      name: "FdrNo",
      selector: (row) => (row.fdr_no ? row.fdr_no : "--"),
      sortable: true,
      width: "150px",
    },
    {
      name: "Ac No",
      selector: (row) => row.acc_no,
      sortable: true,
    },
    {
      name: "Open Date",
      selector: (row) => row.StartDate,
      sortable: true,
      width: "150px",
      cell: (row) => {
        const loanDate = new Date(row.StartDate);
        if (isNaN(loanDate.getTime())) {
          return "--"; // Return empty string if the date is invalid
        }
        const formattedDate = `${loanDate
          .getDate()
          .toString()
          .padStart(2, "0")}-${(loanDate.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${loanDate.getFullYear()}`;
        return formattedDate;
      },
    },
    // {
    //   name: "Op Amt",
    //   selector: (row) => parseFloat(row.opening_balance).toFixed(1),
    //   sortable: true,
    // },
    {
      name: "Op Amt",
      selector: (row) => row.opening_balance,
      sortable: true,
    },

    {
      name: "Period",
      width: "150px",

      selector: (row) => {
        const period = row.deposit_period ? row.deposit_period : "-";
        const unit = row.unit ? row.unit : "";

        if (!unit && [12, 24, 36, 51, 60, 141].includes(period)) {
          return `${period} MONTHS`;
        }

        return `${period} ${unit}`;
      },
      sortable: true,
    },

    {
      name: "Interest",
      selector: (row) => row.InterestRate,
      sortable: true,
    },

    {
      name: "Maturity",
      selector: (row) => row.EndDate,
      sortable: true,
      width: "150px",

      cell: (row) => {
        const loanDate = new Date(row.EndDate);
        if (isNaN(loanDate.getTime())) {
          return "--"; // Return empty string if the date is invalid
        }
        const formattedDate = `${loanDate
          .getDate()
          .toString()
          .padStart(2, "0")}-${(loanDate.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${loanDate.getFullYear()}`;
        return formattedDate;
      },
    },
    // {
    //   name: "M Amt",
    //   selector: (row) => parseFloat(row.MaturityAmt).toFixed(1),
    //   sortable: true,

    // },
    {
      name: "M Amt",
      selector: (row) => row.MaturityAmt,
      sortable: true,
    },

    {
      name: "Balance",
      selector: (row) => row.Balance,
      sortable: true,
    },
  ];

  const Guaranters = [
    { name: "Loan No", selector: "loan_no", sortable: true, width: "130px" },

    {
      name: "Loan Date",
      selector: (row) => row.loan_date,
      sortable: true,
      cell: (row) => {
        const loanDate = new Date(row.loan_date);
        if (isNaN(loanDate.getTime())) {
          return "--"; // Return empty string if the date is invalid
        }
        const formattedDate = `${loanDate
          .getDate()
          .toString()
          .padStart(2, "0")}-${(loanDate.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${loanDate.getFullYear()}`;
        return formattedDate;
      },
    },
    {
      name: "Due Date",
      selector: (row) => row.ln_due_dt,
      sortable: true,
      cell: (row) => {
        const loanDate = new Date(row.ln_due_dt);
        if (isNaN(loanDate.getTime())) {
          return "--"; // Return empty string if the date is invalid
        }
        const formattedDate = `${loanDate
          .getDate()
          .toString()
          .padStart(2, "0")}-${(loanDate.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${loanDate.getFullYear()}`;
        return formattedDate;
      },
    },
    {
      name: "Surity 1",
      selector: "suri1_details",
      sortable: true,
      cell: (row) =>
        row.suri1_name && row.suri1_eno ? (
          <span>
            {row.suri1_name}-<strong>{row.suri1_eno}</strong>
          </span>
        ) : (
          "--"
        ),
    },

    {
      name: "Surity 2",
      selector: "suri2_details",
      sortable: true,
      cell: (row) =>
        row.suri2_name && row.suri2_eno ? (
          <span>
            {row.suri2_name}-<strong>{row.suri2_eno}</strong>
          </span>
        ) : (
          "--"
        ),
    },
    {
      name: "Surity 3",
      selector: "suri3_details",
      sortable: true,
      cell: (row) =>
        row.suri3_name && row.suri3_eno ? (
          <span>
            {row.suri3_name}-<strong>{row.suri3_eno}</strong>
          </span>
        ) : (
          "--"
        ),
    },
  ];

  const GuaranteeToColumns = [
    { name: "Emp No", selector: "e_no", sortable: true },
    { name: "Loan No", selector: "loan_no", sortable: true },

    {
      name: "Loan Date",
      selector: (row) => row.loan_date,
      sortable: true,
      cell: (row) => {
        const loanDate = new Date(row.loan_date);
        if (isNaN(loanDate.getTime())) {
          return "--"; // Return empty string if the date is invalid
        }
        const formattedDate = `${loanDate
          .getDate()
          .toString()
          .padStart(2, "0")}-${(loanDate.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${loanDate.getFullYear()}`;
        return formattedDate;
      },
    },
    { name: "Name", selector: "name", sortable: true, width: "300px" },
    { name: "Member Id", selector: "m_no", sortable: true },

    { name: "Mobile No", selector: "m_no", sortable: true },
  ];

  const DemandedDetailsColumns = [
    // Define columns for "Demanded Details" DataTable
  ];

  const RecoveryDetailsColumns = [
    // Define columns for "Recovery Details" DataTable
  ];

  const ShareCertificateColumns = [
    {
      name: "T Date",
      selector: (row) => row.tdate,
      sortable: true,
      cell: (row) => {
        const loanDate = new Date(row.tdate);
        if (isNaN(loanDate.getTime())) {
          return "--"; // Return empty string if the date is invalid
        }
        const formattedDate = `${loanDate
          .getDate()
          .toString()
          .padStart(2, "0")}-${(loanDate.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${loanDate.getFullYear()}`;
        return formattedDate;
      },
    },
    {
      name: "Credit",
      selector: (row) => row.CREDIT,
      sortable: true,
    },
    {
      name: "Unit",
      selector: (row) => row.UNIT,
      sortable: true,
    },
    {
      name: "No of Shares",
      selector: (row) => row.NOOFSHARES,
      sortable: true,
    },
    {
      name: "Cert No",
      selector: (row) => row.CERT_NO,
      sortable: true,
    },
    {
      name: "Share From",
      selector: (row) => row.SHAREFROM,
      sortable: true,
    },
    {
      name: "Share To",
      selector: (row) => row.SHARETO,
      sortable: true,
    },
    {
      name: "Operator",
      selector: (row) => row.OPERATOR,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => row.DATE,
      sortable: true,
      cell: (row) => {
        const loanDate = new Date(row.DATE);
        if (isNaN(loanDate.getTime())) {
          return "--"; // Return empty string if the date is invalid
        }
        const formattedDate = `${loanDate
          .getDate()
          .toString()
          .padStart(2, "0")}-${(loanDate.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${loanDate.getFullYear()}`;
        return formattedDate;
      },
    },
    {
      name: "Date0",
      selector: (row) => row.DATE0,
      sortable: true,
      cell: (row) => {
        const loanDate = new Date(row.DATE0);
        if (isNaN(loanDate.getTime())) {
          return "--"; // Return empty string if the date is invalid
        }
        const formattedDate = `${loanDate
          .getDate()
          .toString()
          .padStart(2, "0")}-${(loanDate.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${loanDate.getFullYear()}`;
        return formattedDate;
      },
    },
  ];

  const RemarkColumns = [
    // Define columns for "Remark" DataTable
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "48px",
        textAlign: "center",
      },
    },
    headCells: {
      style: {
        minHeight: "40px",
        backgroundColor: "#4db3c8",
        fontSize: "12px",
        fontWeight: "400",
        color: "white",
        textAlign: "center",
      },
    },
  };

  const getStyles = (loanType) => {
    return {
      color: selectedLoan === loanType ? "white" : "white",
      ...(selectedLoan === loanType
        ? {
            borderBottom: "4px solid orange",
          }
        : {}),
    };
  };

  const date = joinDate;
  let formattedDate = "";

  if (date) {
    const [year, month, day] = date.split("-");
    formattedDate = `${day}-${month}-${year}`;
  }
  const redate = retireDate;
  let formattedreDate = "";

  if (redate) {
    const [year, month, day] = redate.split("-");
    formattedreDate = `${day}-${month}-${year}`;
  }

  const memDate = mDate;
  let formattedmemDate = "";

  if (memDate) {
    const [year, month, day] = memDate.split("-");
    formattedmemDate = `${day}-${month}-${year}`;
  }

  const Bdate = birth;
  let formattedBirthDate = "";

  if (Bdate) {
    const [year, month, day] = Bdate.split("-");
    formattedBirthDate = `${day}-${month}-${year}`;
  }

  return (
    <>
      <Sidebar />
      <div className="container-fluid dashboard-area d-flex">
        <div className="main-content p-4">
          {/* Navbar */}
          <Header />
          {/* Heading */}

          <div
            className="container d-flex w-100 pb-1"
            style={{ flexDirection: "column" }}
          >
            <div className="row">
              <h3 style={{ color: "dodgerblue" }}>
                <strong>Member Status</strong>
              </h3>
            </div>
            <div className="row mb-2 w-100 text-start">
              <div className="col-3">
                <label className="form-label">Emp ID</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Enter ID"
                  onChange={(e) => setMemberId(e.target.value)}
                />
              </div>
              <div className="col-3">
                <label htmlFor="">Name</label>
                <input type="text" className="form-control" value={name} />
              </div>
              <div className="col-3">
                <label htmlFor="">Member ID</label>
                <input type="text" className="form-control" value={mNO} />
              </div>
              <div className="col-3">
                <label htmlFor="">Membership Date</label>
                <input
                  type="text"
                  className="form-control"
                  value={formattedmemDate}
                />
              </div>
              <div className="col-3">
                <label htmlFor="">Birth Date</label>
                <input
                  type="text"
                  className="form-control"
                  value={formattedBirthDate}
                />
              </div>
              <div className="col-3">
                <label htmlFor="">Join Date</label>
                <input
                  type="text"
                  className="form-control"
                  value={formattedDate}
                />
              </div>
              <div className="col-3">
                <label htmlFor="">Retire Date</label>
                <input
                  type="text"
                  className="form-control"
                  value={formattedreDate}
                />
              </div>
              <div className="col-3">
                <label htmlFor="">Division</label>
                <input type="text" className="form-control" value={division} />
              </div>
              <div className="col-3">
                <span>IFSC Code</span>
                <input type="text" className="form-control" value={IFSCCode} />
              </div>
              <div className="col-3">
                <span>Bank Account No</span>
                <input
                  type="text"
                  className="form-control"
                  value={parseInt(bankAccountNo)}
                />
              </div>
              <div className="col-3">
                <span>Bank Name</span>
                <input type="text" className="form-control" value={bankName} />
              </div>
              <div className="col-3">
                <span>Branch Name</span>
                <input
                  type="text"
                  className="form-control"
                  value={branchName}
                />
              </div>
            </div>
            <div className="row py-3 w-100 d-flex justify-content-start align-items-center">
              <table>
                <tbody>
                  <tr>
                    <td
                      className="column-heading"
                      style={{
                        cursor: "pointer",
                        ...getStyles("Deposits Detail"),
                      }}
                      onClick={() => setSelectedLoan("Deposits Detail")}
                    >
                      Deposits Detail
                    </td>
                    <td
                      className="column-heading"
                      style={{
                        cursor: "pointer",
                        ...getStyles("Loans Detail"),
                      }}
                      onClick={() => setSelectedLoan("Loans Detail")}
                    >
                      Loans Detail
                    </td>

                    <td
                      className="column-heading"
                      type="btn"
                      style={{
                        cursor: "pointer",
                        ...getStyles("Guaranters Detail"),
                      }}
                      onClick={() => setSelectedLoan("Guaranters Detail")}
                    >
                      Guaranters Detail
                    </td>
                    <td
                      className="column-heading"
                      style={{
                        cursor: "pointer",
                        ...getStyles("Guarantee To"),
                      }}
                      onClick={() => setSelectedLoan("Guarantee To")}
                    >
                      Guarantee To
                    </td>
                    <td
                      className="column-heading"
                      style={{
                        cursor: "pointer",
                        ...getStyles("Demanded Details"),
                      }}
                      onClick={() => setSelectedLoan("Demanded Details")}
                    >
                      Demanded Details
                    </td>
                    <td
                      className="column-heading"
                      style={{
                        cursor: "pointer",
                        ...getStyles("Recovery Details"),
                      }}
                      onClick={() => setSelectedLoan("Recovery Details")}
                    >
                      Recovery Details
                    </td>
                    <td
                      className="column-heading"
                      style={{
                        cursor: "pointer",
                        ...getStyles("Share Certificate"),
                      }}
                      onClick={() => setSelectedLoan("Share Certificate")}
                    >
                      Share Certificate
                    </td>
                    <td
                      className="column-heading"
                      style={{ cursor: "pointer", ...getStyles("Remark") }}
                      onClick={() => setSelectedLoan("Remark")}
                    >
                      Remark
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          {/* Render the DataTable based on the selected loan */}
          {selectedLoan === "Loans Detail" && (
            <div className="py-3">
              {/* <h2>Total No. Of Loan: {data.length}</h2> */}
              <DataTable
                title=""
                columns={loanColumns}
                data={loanData || []}
                pagination
                highlightOnHover
                dense
                striped
                responsive
                customStyles={customStyles}
              />
            </div>
          )}

          {selectedLoan === "Deposits Detail" && (
            <div className="py-3">
              <DataTable
                title=""
                columns={Deposits}
                data={depositData || []}
                pagination
                highlightOnHover
                dense
                striped
                responsive
                customStyles={customStyles}
              />
            </div>
          )}

          {selectedLoan === "Guaranters Detail" && (
            <div>
              <DataTable
                title=""
                columns={Guaranters}
                data={guarantee}
                pagination
                highlightOnHover
                dense
                striped
                responsive
                customStyles={customStyles}
              />
            </div>
          )}

          {selectedLoan === "Guarantee To" && (
            <div className="py-3">
              <DataTable
                title=""
                columns={GuaranteeToColumns}
                data={guaranteeTo || []}
                pagination
                highlightOnHover
                dense
                striped
                responsive
                customStyles={customStyles}
              />
            </div>
          )}

          {selectedLoan === "Demanded Details" && (
            <div className="py-3">
              <DataTable
                title=""
                columns={DemandedDetailsColumns}
                // data={demandedDetailsData || []}
                pagination
                highlightOnHover
                dense
                striped
                responsive
                customStyles={customStyles}
              />
            </div>
          )}

          {selectedLoan === "Recovery Details" && (
            <div className="py-3">
              <DataTable
                title=""
                columns={RecoveryDetailsColumns}
                // data={recoveryDetailsData || []}
                pagination
                highlightOnHover
                dense
                striped
                responsive
                customStyles={customStyles}
              />
            </div>
          )}

          {selectedLoan === "Share Certificate" && (
            <div className="py-3">
              <DataTable
                title=""
                columns={ShareCertificateColumns}
                data={shareData || []}
                pagination
                highlightOnHover
                dense
                striped
                responsive
                customStyles={customStyles}
              />
            </div>
          )}

          {selectedLoan === "Remark" && (
            <div className="py-3">
              <DataTable
                title=""
                columns={RemarkColumns}
                // data={remarkData || []}
                pagination
                highlightOnHover
                dense
                striped
                responsive
                customStyles={customStyles}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default MemberStatus;
