import React, { useState, useEffect } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import Sidebar from "./Sidebar";
import Header from "../components/Header";
import * as XLSX from "xlsx";
import { SiMicrosoftexcel } from "react-icons/si";

export default function NeftTransaction() {
  const [neftTransactions, setNeftTransactions] = useState([]);
  const [startDate, setStartDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [endDate, setEndDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [totalBalance, setTotalBalance] = useState(0);

  useEffect(() => {
    fetchNeftTransactions();
  }, [startDate, endDate]);

  const fetchNeftTransactions = () => {
    const requestData = {
      date_from: startDate,
      date_to: endDate,
    };

    axios
      .post("http://13.232.218.212:8000/neft_transaction/", requestData)
      .then((response) => {
        console.log(response.data);
        const data = response.data.result;
        if (data) {
          setNeftTransactions(data);
          const total = data.reduce((acc, curr) => acc + curr.total, 0);
          setTotalBalance(total);
        } else {
          console.error("Data is undefined:", response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching NEFT transactions:", error);
      });
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const columns = [
    {
      name: "Sr. No.",
      selector: (row, index) => index + 1,
      sortable: false,
      width: "100px",
      center: true,
    },
    { name: "Details", selector: (row) => row.detail, sortable: true },
    { name: "Cheque no", selector: (row) => row.ctno, sortable: true },
    { name: "LTR Date", selector: (row) => row.l_r_t_date, sortable: true },
    { name: "Lup Date", selector: (row) => row.lupdate, sortable: true },
    {
      name: "Lup Time",
      selector: (row) => (row.luptime ? row.luptime : "- -"),
      sortable: true,
    },
    { name: "Balance", selector: (row) => row.total, sortable: true },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "48px",
        textAlign: "center",
      },
    },
    headCells: {
      style: {
        minHeight: "40px",
        backgroundColor: "#4db3c8",
        fontSize: "14px",
        fontWeight: "400",
        color: "white",
        textAlign: "center",
      },
    },
  };

  const exportToExcel = () => {
    const columnsForExport = columns.filter((column) => column.selector);
    const fileName = "NEFT_Transactions";

    const exportData = neftTransactions.map((row, index) => {
      const rowData = {
        "Sr. No.": index + 1,
      };

      columnsForExport.forEach((column) => {
        if (column.name !== "Sr. No.") {
          rowData[column.name] = row[column.selector];
        }
      });

      return rowData;
    });

    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "sheet");
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  };

  return (
    <>
      <Sidebar />
      <div className="container-fluid dashboard-area d-flex">
        <div className="main-content p-4">
          <Header />
          <div className="container d-flex text-start w-100 pb-1 ps-0">
            <h3 style={{ fontWeight: "bold", color: "dodgerblue" }}>
              NEFT Transactions
            </h3>
          </div>
          {/* Date Filters */}
          <div className="row w-100 py-3 d-flex align-items-center">
            <div className="col-4 d-flex">
              <div class="form-group col-6 text-start ps-0">
                <label htmlFor="startDateFilter">Start Date:</label>
                <input
                  type="date"
                  className="form-control"
                  id="startDate"
                  value={startDate}
                  onChange={handleStartDateChange}
                />
              </div>
              <div class="form-group col-6 text-start ps-0">
                <label htmlFor="endDateFilter">End Date:</label>
                <input
                  type="date"
                  className="form-control"
                  id="startDate"
                  value={endDate}
                  onChange={handleEndDateChange}
                />
              </div>
            </div>
            <div
              className="col-5  d-flex justify-content-center "
              style={{ flexDirection: "column" }}
            >
              <h5>
                Transaction Amt: ₹{" "}
                <strong>{totalBalance.toLocaleString("en-IN")}</strong>
              </h5>
              <p style={{ color: "red", fontSize: "10px", fontWeight: "500" }}>
                * The Transaction Amount represents the sum from the Start Date
                to the End Date.
              </p>
            </div>
            <div className="col-3 d-flex justify-content-end pe-0">
              <div className="col-2">
                <span
                  onClick={exportToExcel}
                  style={{
                    cursor: "pointer",
                    color: "green",
                    fontSize: "1.7em",
                  }}
                  title="Export Excel"
                >
                  <SiMicrosoftexcel />
                </span>
              </div>
            </div>
          </div>
          {/* DataTable */}
          {neftTransactions.length > 0 && (
            <>
              <DataTable
                columns={columns}
                data={neftTransactions}
                customStyles={customStyles}
                pagination
                highlightOnHover
                striped
                dense
                responsive
              />
              {/* Export to Excel Button */}
              <div className="row w-100 py-3">
                <div className="col-2"></div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
