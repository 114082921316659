import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Sidebar from "./Sidebar";
import DataTable from "react-data-table-component";
import axios from "axios";
import { format } from "date-fns";

export default function PrintPassbook() {
  const [memberId, setMemberId] = useState("");
  const [passbookType, setPassbookType] = useState("");
  const [memberInfo, setMemberInfo] = useState({});
  const [transactionData, setTransactionData] = useState([]);
  const [forPagination, setForPagination] = useState(true);
  const [rdId, setRdId] = useState("");
  const [printing, setPrinting] = useState(false);
  const [IssuedOn, setIssuedOn] = useState("");
  const entriesPerPage = 15;

  const columns = [
    {
      name: "Sr. No.",
      selector: (row, index) => index + 1,
      sortable: false,
      width: "100px",
      center: true,
    },
    {
      name: "Particular",
      selector: (row) => row.TransactionType,
      sortable: true,
      center: true,
    },

    {
      name: "Date",
      selector: (row) => row.date,
      sortable: true,
      center: true,
      cell: (row) => {
        const date = new Date(row.date);
        // if (isNaN(date.getTime())) {
        //   return "Invalid Date";
        // }
        if (isNaN(date.getTime())) {
          return "";
        }
        return format(date, "dd-MM-yyyy");
      },
    },
    {
      name: "Amount",
      selector: (row) => row.Amount,
      sortable: true,
      center: true,
    },
    {
      name: "Balance",
      selector: (row) => row.total_balance,
      sortable: true,
      center: true,
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "48px",
        textAlign: "center",
      },
    },
    headCells: {
      style: {
        minHeight: "40px",
        backgroundColor: "#4db3c8",
        fontSize: "14px",
        fontWeight: "400",
        color: "white",
        textAlign: "center",
      },
    },
  };

  const fetchMemberInfo = () => {
    const data = {
      member_id: memberId,
    };
    console.log("Sending request with data:", data);

    axios
      .post(`http://13.232.218.212:8000/all_memberdata/${memberId}/`, {
        member_id: memberId,
      })
      .then((response) => {
        const jsondata = response.data;
        const data = jsondata.members[0];
        setMemberInfo(data);

        localStorage.setItem("member_id", memberId);
      })
      .catch((error) => {
        console.error("Member Info Error:", error);
      });
  };

  const fetchRDTransactions = () => {
    const data = {
      member_id: memberId,
      RDID: rdId,
    };

    localStorage.setItem("member_id", memberId);

    axios
      .post("http://13.232.218.212:8000/rd_history_closure/", data)
      .then((response) => {
        const result = response.data["result"];
        setTransactionData(result);
        updatePassbook(result);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fetchSavingsTransactions = () => {
    const data = {
      account_id: memberId,
    };

    localStorage.setItem("member_id", memberId);

    axios
      .post("http://13.232.218.212:8000/saving_history/", data)
      .then((response) => {
        console.log("Saving History Response:", response.data);
        const result = response.data;
        setTransactionData(result);
        updatePassbook(result);
      })
      .catch((error) => {
        console.error("Saving History Error:", error);
      });
  };

  const fetchLakhpatiTransactions = () => {
    axios
      .post("http://13.232.218.212:8000/lakhpati/", {
        account_id: memberId,
      })
      .then((response) => {
        console.log("Lakhpati Passbook History Response:", response.data);
        const result = response.data.result;
        setTransactionData(result);
        updatePassbook(result);
      })
      .catch((error) => {
        console.error("Lakhpati Passbook History Error:", error);
      });
  };

  useEffect(() => {
    if (memberId && passbookType) {
      fetchMemberInfo();
      if (passbookType === "Recurring Deposit Passbook") {
        fetchRDTransactions(rdId);
      } else if (passbookType === "Savings Passbook") {
        fetchSavingsTransactions();
      } else if (passbookType === "Lakhpati Passbook") {
        fetchLakhpatiTransactions();
      }
    }
  }, [memberId, passbookType, rdId]);

  const handlePrint = (elementId) => {
    setPrinting(true);
    setForPagination(!forPagination);
    setTimeout(() => {
      let printContents = document.getElementById(elementId).innerHTML;
      let originalContents = document.body.innerHTML;
      const style = document.createElement("style");
      style.innerHTML = `
      @media print {
        #backPagePrintable {
          width: 14cm;
          height: 9.5cm;
        }
        .Passbookprint {
          display: none;
        }
      }
    `;
      document.head.appendChild(style);
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
      setPrinting(false);
      window.location.reload();
    }, 0);
  };

  const handlePrintPassbook = () => {
    const entriesPerPage = 30; // Adjusted to accommodate 30 entries per page
    const entriesPerBlock = 15; // Number of entries per block

    // Open print dialog
    const printWindow = window.open("", "_blank");
    printWindow.document.write(
      "<html><head><title>Passbook Entries</title></head><body>" // Remove the margin-top style definition
    );

    // Function to format and concatenate entries
    const formatAndConcatEntries = (entries, startIndex) => {
      let formattedEntries = "";
      entries.forEach((entry, index) => {
        // Extract only date without time
        const date = new Date(entry.date);
        const formattedDate = `${("0" + date.getDate()).slice(-2)}-${(
          "0" +
          (date.getMonth() + 1)
        ).slice(-2)}-${date.getFullYear()}`;

        // Pad each value for proper alignment
        const paddedIndex = String(index + 1 + startIndex).padEnd(4);
        const paddedDate = formattedDate.padEnd(12);
        const paddedType = entry.TransactionType
          ? entry.TransactionType.padEnd(20)
          : " ".repeat(20); // Adjust the width as needed
        const paddedAmount = String(entry.Amount).padStart(10); // Adjust the width as needed
        const paddedBalance = String(entry.total_balance).padStart(10); // Adjust the width as needed

        const entryText = `${paddedIndex} ${paddedDate} ${paddedType} ${paddedAmount} ${paddedBalance}\n`;
        formattedEntries += entryText;

        // Add a gap after every 15 entries
        if (
          (index + 1) % entriesPerBlock === 0 &&
          index !== entries.length - 1
        ) {
          formattedEntries += "<br><br><br>";
        }
      });
      return formattedEntries;
    };

    const numTransactions = transactionData.length;

    for (let i = 0; i < numTransactions; i += entriesPerPage) {
      const pageEntries = transactionData.slice(i, i + entriesPerPage);
      let printedEntries = "";

      // Apply margin-top style for each new page
      printedEntries += '<div style="margin-top: 50px;">';

      // Format and concatenate entries for the current page
      printedEntries += formatAndConcatEntries(pageEntries, i);

      // Close the margin-top style div
      printedEntries += "</div>";

      // Print entries on a single page
      printWindow.document.write("<pre>");
      printWindow.document.write(printedEntries);
      printWindow.document.write("</pre>");

      // If there are more transactions and the current page doesn't reach the maximum entriesPerPage, add a page break
      if (
        i + entriesPerPage < numTransactions &&
        pageEntries.length === entriesPerPage
      ) {
        printWindow.document.write(
          '<div style="page-break-before: always;"></div>'
        );
      }
    }

    // Close print dialog
    printWindow.document.write("</body></html>");
    printWindow.document.close();
    printWindow.print();

    // Reload the window after printing
    window.location.reload();
  };

  // Function to update passbook with latest transactionData
  const updatePassbook = (latestTransactions) => {
    // Ensure latestTransactions is an array
    const safeLatestTransactions = Array.isArray(latestTransactions)
      ? latestTransactions
      : [];

    setTransactionData((prevTransactions = []) => [
      ...prevTransactions,
      ...safeLatestTransactions,
    ]);
  };

  const Idate = IssuedOn;
  let formattedIssueDate = "";
  if (Idate) {
    const [year, month, day] = Idate.split("-");
    formattedIssueDate = `${day}-${month}-${year}`;
  }

  return (
    <>
      <Sidebar />
      <div className="container-fluid dashboard-area d-flex">
        <div className="main-content p-4">
          <Header />
          <div className="container d-flex text-start w-100 pb-1 ms-0 ps-0">
            <h3 style={{ fontWeight: "bold", color: "dodgerblue" }}>
              Passbook Printing
            </h3>
          </div>
          <div className="row d-flex justify-content-start align-items-center">
            <div className="col-3 text-start">
              <div className="mb-3">
                <label htmlFor="memberId" className="form-label">
                  Member ID
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="memberId"
                  value={memberId}
                  onChange={(e) => setMemberId(e.target.value)}
                />
              </div>
            </div>
            <div className="col-3 text-start">
              <div className="mb-3">
                <label htmlFor="passbookType" className="form-label">
                  Passbook Type
                </label>
                <select
                  className="form-select"
                  id="passbookType"
                  style={{ fontSize: "12px" }}
                  value={passbookType}
                  onChange={(e) => setPassbookType(e.target.value)}
                >
                  <option value="">Select Passbook</option>
                  <option value="Recurring Deposit Passbook">
                    Recurring Deposit Passbook
                  </option>
                  <option value="Savings Passbook">Savings Passbook</option>
                  <option value="Lakhpati Passbook">Lakhpati Passbook</option>
                  <option value="EK LAKSH YOJANA Passbook">
                    Ek Laksh Yojana Passbook
                  </option>
                </select>
              </div>
            </div>
            {passbookType === "Recurring Deposit Passbook" && (
              <div className="col-3 text-start">
                <div className="mb-3">
                  <label htmlFor="rdId" className="form-label">
                    RDID
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="rdId"
                    value={rdId}
                    onChange={(e) => setRdId(e.target.value)}
                  />
                </div>
              </div>
            )}
          </div>

          <div id="frontPagePrintable">
            {memberId && passbookType && memberInfo && (
              <>
                {/* Front Page - Member Information */}
                <div className="mt-4 text-start">
                  <p>
                    Name:&nbsp;
                    {`${memberInfo.first_name} ${memberInfo.middle_name} ${memberInfo.last_name}`}
                  </p>
                  <p>Reference No : &nbsp;{memberInfo.emp_no}</p>
                  <p>Member No : &nbsp;{memberInfo.member_id}</p>
                  <p>Mobile No : &nbsp;{memberInfo.mobile_no}</p>
                  <p>Issued Date : &nbsp;{formattedIssueDate}</p>
                </div>
                <div className="row justify-content-center align-items-center">
                  <div className="col-3">
                    <button
                      className="btn btn-success Passbookprint"
                      onClick={() => handlePrint("frontPagePrintable")}
                    >
                      PRINT
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>

          {/* Back Page - Transaction History */}
          <div id="backPagePrintable" className="mt-4">
            {memberId &&
              passbookType &&
              transactionData &&
              transactionData.length > 0 && (
                <>
                  <DataTable
                    columns={columns}
                    data={transactionData}
                    pagination={forPagination}
                    striped
                    responsive
                    dense
                    highlightOnHover
                    customStyles={customStyles}
                  />
                  <div className="row justify-content-center align-items-center">
                    <div className="col-3">
                      <button
                        className="btn btn-success Passbookprint"
                        onClick={() => handlePrintPassbook("backPagePrintable")}
                      >
                        PRINT
                      </button>
                    </div>
                  </div>
                </>
              )}
          </div>
        </div>
      </div>
    </>
  );
}
