import React, { useState, useEffect } from "react";
import Sidebar from "./Sidebar";
import Header from "../components/Header";
import DataTable from "react-data-table-component";
import axios from "axios";
import * as XLSX from "xlsx";
import { SiMicrosoftexcel } from "react-icons/si";

const DemandList = () => {
  const [data, setData] = useState("");
  const [amount, setAmount] = useState(0);

  const fetchData = async () => {
    try {
      const response = await axios.post(
        "http://13.232.218.212:8000/demandlist/"
      );

      const depositData = response.data.deposit_data || [];
      const loanData = response.data.loan_data || [];

      const total_amount = response.data.total_deposit_amount;
      setAmount(total_amount);

      // Combine deposit and loan data
      const combinedData = [...depositData, ...loanData];
      setData(combinedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const columns = [
    {
      name: "ID",
      selector: "ID",
      sortable: true,
      cell: (row) => (row.loan_no ? row.loan_no : row.RDID),
    },
    {
      name: "Member ID",
      selector: "member_id",
      sortable: true,
    },
    {
      name: "Account Type",
      selector: "Account_type",
      sortable: true,
      cell: (row) => (row.ac_type ? row.ac_type : row.Account_type),
    },
    {
      name: "Full Name",
      selector: "full_name",
      sortable: true,
    },
    {
      name: "Amount",
      selector: "amount",
      sortable: true,
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "48px",
        textAlign: "center",
      },
    },
    headCells: {
      style: {
        minHeight: "40px",
        backgroundColor: "#4db3c8",
        fontSize: "14px",
        fontWeight: "400",
        color: "white",
        textAlign: "center",
      },
    },
  };

  const exportToExcel = () => {
    if (!Array.isArray(data) || data.length === 0) {
      console.error("No data to export.");
      return;
    }

    const fileName = "Demand List";

    const exportData = data.map((row, index) => {
      const rowData = {
        "Sr. No.": index + 1,
        "RDID/Loan No": row.loan_no ? row.loan_no : row.RDID,
        "Member ID": row.member_id,
        "Full Name": row.full_name,
        "Account Type": row.ac_type ? row.ac_type : row.Account_type,
        Amount: row.amount,
      };

      return rowData;
    });

    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "sheet");
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Sidebar />
      <div className="container-fluid dashboard-area d-flex">
        <div className="main-content p-4">
          {/* Navbar */}
          <Header />
          <div className="container d-flex text-start w-100 pb-1">
            <div className="row w-100">
              <div className="col-6">
                <h3 style={{ fontWeight: "bold", color: "dodgerblue" }}>
                  Generate Demand List
                </h3>
              </div>
              <div className="col-6 text-end">
                <SiMicrosoftexcel
                  onClick={exportToExcel}
                  style={{
                    cursor: "pointer",
                    color: "green",
                    fontSize: "1.8em",
                  }}
                />
              </div>
            </div>
          </div>

          {/* DataTable */}
          <DataTable
            data={data}
            columns={columns}
            customStyles={customStyles}
            pagination
            dense
            striped
          />
          <div className="text-start">
            <span style={{ color: "darkblue", fontWeight: "bold" }}>
              Demanded Amount :&nbsp;
            </span>
            <span style={{ fontSize: "2rem", fontWeight: "bold" }}>
              ₹&nbsp;
              {amount.toLocaleString("en-IN", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default DemandList;
